import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Modal, Alert } from "react-bootstrap";
import { Add } from '@mui/icons-material';
import Avatars from './Avatars';
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/MemberInfo.css";
import useTranslations from '../transalation/TranslationUtils';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setMemberPageFormData, setPageFormToMemberData, updateMember } from "app/redux/slices/rdxMemberSlice";

function MembershipDetails() {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [tags, setTags] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);


    const { memberDetails, addNewRelationship, savechaneg, cancel, showErrMsg } = useTranslations();
    const translations = useTranslations();

    const basicInfoFormMap = useSelector((state) => state.rdxMember?.basicInfoFormMap || {});
    const formMap = useSelector((state) => state.rdxMember?.mbrShipFormMap || {});
    const memberEditData = useSelector((state) => state.rdxMember?.memberEditData || {});


    const handleInputChange = (e, key, item) => {
        const { value } = e.target;

        let data = {
            data: { key: key, val: value },
            projection: "mbrShipFormMap"
        };
        dispatch(setMemberPageFormData(data));
    };

    const handleSave = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        let sendData = {
            "account_id": memberEditData.account_id
        };
        for (const key in formMap) {
            let formItem = formMap[key];
            if (!formItem.ignore) {
                sendData[key] = formMap[key].val;
            }
        }

        for (const key in basicInfoFormMap) {
            let formItem = basicInfoFormMap[key];
            if (!formItem.ignore) {
                sendData[key] = basicInfoFormMap[key].val;
            }
        }

        dispatch(updateMember(sendData, (isSuccess, data) => {
            if (isSuccess) {
                dispatch(setPageFormToMemberData({ formMap: formMap }));
                setSuccessMessage("Basic information saved successfully!");
                setError(null);
            } else {
                setSuccessMessage(null);
                setError(showErrMsg(data));
            }
        }));
    };

    const handleAddTag = () => {
        if (selectedTags.length > 0) {
            const newTags = selectedTags.filter(tag => !tags.includes(tag));
            setTags([...tags, ...newTags]);
            setShowModal(false);
        } else {
            setErrorMessage("Please select at least one tag.");
        }
    };

    const removeTag = (tag) => {
        setTags(tags.filter((t) => t !== tag));
    };

    const handleTagChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedTags([...selectedTags, value]);
        } else {
            setSelectedTags(selectedTags.filter((tag) => tag !== value));
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card className="mt-0 border-0" style={{ position: "relative" }}>
                        <Card.Body>
                            <Avatars initAvatar={memberEditData["account_avatar"]} />
                            <br />
                            <Card className="p-3 m-3">
                                <Form onSubmit={handleSave}>
                                    <Row>
                                        <h5 className="basicinfo">{memberDetails()}</h5>
                                        {Object.keys(formMap).map(key => (
                                            <React.Fragment key={key}>
                                                {"input" == formMap[key].type ? (
                                                    <Col md={6}>
                                                        <Form.Group controlId={key} className="floating-label-group">
                                                            <Form.Control
                                                                disabled={formMap[key].ignore}
                                                                type="text"
                                                                name={key}
                                                                value={formMap[key]["val"]}
                                                                onChange={(event) => handleInputChange(event, key, formMap[key])}
                                                                className="floating-input basicinfoinput"
                                                            />
                                                            <Form.Label className="floating-label basicinfolabel">{translations[formMap[key].label]()}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                ) : "select" == formMap[key].type ? (
                                                    <Col md={6}>
                                                        <Form.Group controlId={key} className="floating-label-group">
                                                            <Form.Select
                                                                disabled={formMap[key].ignore}
                                                                name={key}
                                                                value={formMap[key]["val"]}
                                                                onChange={(event) => handleInputChange(event, key, formMap[key])}
                                                                className="floating-input"
                                                            >
                                                                {formMap[key].options.map(({ label, value }) => (
                                                                    <option key={label} value={value}>{label}</option>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Label className="floating-label basicinfolabel">{translations[formMap[key].label]()}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                ) : "datepicker" == formMap[key].type ? (
                                                    <Col md={6}>
                                                        <Form.Group controlId={key} className="floating-label-group">
                                                            <Form.Control
                                                                disabled={formMap[key].ignore}
                                                                type="date"
                                                                name={key}
                                                                value={formMap[key]["val"]}
                                                                onChange={(event) => handleInputChange(event, key, formMap[key])}
                                                                className="floating-input"
                                                            />
                                                            <Form.Label className="floating-label basicinfolabel">{translations[formMap[key].label]()}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                ) : (<React.Fragment></React.Fragment>)}
                                            </React.Fragment>
                                        ))}
                                    </Row>

                                    <Row className="mt-3">
                                        <Col md={1}>
                                            <Button
                                                style={{
                                                    backgroundColor: '#1976D2',
                                                    border: 'none',
                                                    height: '24px',
                                                    width: '24px',
                                                    padding: '0'
                                                }}
                                                onClick={() => setShowModal(true)}
                                            >
                                                <Add style={{ color: '#fff', fontSize: '16px' }} />
                                            </Button>
                                        </Col>
                                    </Row>

                                    <div className="tags-container d-flex flex-wrap gap-3 mt-3 ms-2">
                                        {tags.map((tag) => (
                                            <span key={tag} className="tag px-3 py-1 rounded-pill border border-primary">
                                                {tag}{' '}
                                                <button
                                                    className="remove-btn ms-2 btn btn-sm btn-link text-primary p-0"
                                                    onClick={() => removeTag(tag)}
                                                >
                                                    x
                                                </button>
                                            </span>
                                        ))}
                                    </div>

                                    <Row className="mt-3">
                                        <Col className="mt-4">
                                            <Button variant="primary" type="submit" className="me-2 instructorphoto">
                                                {savechaneg()}
                                            </Button>
                                            {/* <Button variant="outline-primary" className="canslebtn">{cancel()}</Button> */}
                                        </Col>
                                    </Row>

                                    {error && <p className="text-danger mt-3">{error}</p>}
                                    {successMessage && <p className="text-success mt-3">{successMessage}</p>}
                                </Form>
                            </Card>

                            <Card className="ps-3 m-3" style={{ height: "200px" }}>
                                <Row className="mt-4">
                                    <h5 className="basicinfo ">Member Relationship</h5>
                                </Row>
                                <Row className="h-100">
                                    <Col className="d-flex align-items-center justify-content-center">
                                        <Button variant="primary" type="submit" className="instructorphoto">
                                            {addNewRelationship()}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">Add Tag(s)</h4>
                        {errorMessage && (
                            <Alert variant="danger">
                                {errorMessage}
                            </Alert>
                        )}
                        <Form.Group controlId="formStatus" className="mt-3 ms-3">
                            {["Instructor", "Caregiver", "Volunteer"].map((tag, index) => (
                                <Form.Check
                                    key={index}
                                    type="checkbox"
                                    label={tag}
                                    value={tag}
                                    onChange={handleTagChange}
                                    className="assigntocheck mb-3"
                                />
                            ))}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="" onClick={() => setShowModal(false)} className="me-2 groupclear">Cancel</Button>
                    <Button variant="" className="groupsearch" onClick={handleAddTag}>Save</Button>
                </Modal.Footer>
            </Modal>

            {/* <style jsx>{`
                .tag {
                    background-color: #e0f7ff;
                    color: #007bff;
                    display: inline-flex;
                    align-items: center;
                }
                .remove-btn {
                    background: none;
                    border: none;
                    color: #007bff;
                    cursor: pointer;
                    font-size: 14px;
                }
                .remove-btn:hover {
                    color: #0056b3;
                }
                `}</style> */}
        </Container>
    );
}

export default MembershipDetails;
