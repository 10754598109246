import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    hasToken: false,
    tokenFailed: false,
    adminInited: false,
    permissions: [],
    adminName: "User"
};

export const login = (sendData, callback) => async (dispatch) => {
    //"fulfilled" == thunkResult.meta?.requestStatus
    let token;
    let error;

    try {
        const thunkResult = await dispatch(loginReq(sendData));
        let data = thunkResult.payload;
        let msg = data?.message;
        token = data?.data?.token;
        // console.log(msg, token);

        if (msg) {
            error = data;
        } else {
            error = {
                message: "An unexpected error occurred. Please try again.",
                message_en: "An unexpected error occurred. Please try again."
            }
        }
    } catch (err) {
        error = {
            message: err.message,
            message_en: err.message
        }
    }

    callback(token, error);
};

export const mLogout = (callback) => async (dispatch) => {
    await dispatch(logoutReq());
    callback();
};

export const getadmininfo = (callback) => async (dispatch) => {
    const thunkResult = await dispatch(adminInfoReq());
    let data = thunkResult.payload;
    if (1 == data?.code && callback) {
        callback();
    }
};


const loginReq = createAsyncThunk("mAuthReq/login", async (data) => {
    // try{
    //     const res = await axios.post("http://localhost:3007/login");
    // } catch(err){
    //     //can catch 500 response from server
    //     console.log(err);
    // }

    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/login", data);
    // let res = await axios.post("http://localhost:3007/login", data);
    return res.data;
});

const adminInfoReq = createAsyncThunk("mAuthReq/noLoading/tknAware/admininfo", async () => {
    let res = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getadmininfo");
    return res.data;
});

const logoutReq = createAsyncThunk("mAuthReq/noLoading/logout", async () => {
    axios.post("https://membershipuat.maxisense.io/webapp/memportal/logout", {});
    return {};
});


const rdxAuthSlice = createSlice({
    initialState,
    name: "rdxAuth",
    extraReducers: (builder) => {
        builder
            .addCase(loginReq.rejected, (state, actions) => {
                // can handle 404, 500 here
            })
            .addCase(loginReq.fulfilled, (state, actions) => {
                // can handle 200 here
                // let data = action.payload;
                // if (data.code === 1) {
                //    ... 
                // }
            })
            .addCase(adminInfoReq.fulfilled, (state, actions) => {
                let data = actions.payload;
                if (data.code === 1) {
                    state.adminInited = true;
                    state.permissions = data.data.permission_list || [];
                    state.adminName = data.data.admin_name || "User";
                }
            })
            .addMatcher(
                (action) => action.type.includes('/tknAware') && action.type.endsWith('/fulfilled'),
                (state, actions) => {
                    let data = actions.payload;
                    if (data.code === -5) {
                        //使用者被登出
                        state.tokenFailed = true;
                    }
                }
            )
    },
    reducers: {
        initToken(state, actions) {
            console.log("initToken");
            // Store token in localStorage
            let token = actions.payload;
            axios.defaults.headers.common.token = `${token}`

            state.hasToken = true;
            state.tokenFailed = false;
        },
        clearToken(state) {
            console.log("clearToken");
            localStorage.removeItem('authToken');
            delete axios.defaults.headers.common.token;

            state.hasToken = false;
            state.tokenFailed = false;
            state.adminInited = false;
        },
        revokeToken(state) {
            console.log("revokeToken");
            state.tokenFailed = true;
        }
    }
});

export const { initToken, clearToken, revokeToken } = rdxAuthSlice.actions;

export const rdxAuthReducer = rdxAuthSlice.reducer;