import React, { useState } from "react";
import { Container, Row, Col, Form, Card, Button, CardBody, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Icon } from '@mui/material';
import './campaigncss/Consent.css'
import { IoNotificationsOutline } from 'react-icons/io5';
import useTranslations from '../transalation/TranslationUtils';
function Consent() {
    const [inputValues, setInputValues] = useState({
        campaignName: "", // For the Campaign Name dropdowns
        subsidiaryNameChinese: "一起來吧！",  // Provided value for Subsidiary Name (Chinese)
        subsidiaryNameEnglish: "Join Now!",   // Provided value for Subsidiary Name (English)
        introductionChinese: "學員會在導師指導下讓學員練唱，以提升學習水平。", // Provided value for Introduction (Chinese)
        introductionEnglish: "Members will be asked to practice singing under the guidance of instructors to improve their learning level." // Provided value for Introduction (English)
    });

    const { cancel, save, pushstatus, assignTo, instructor, consentForm,
        titleChinese,
        contentChinese,
        rule,
        setConsentFormAsMandatory,
        consentFormMandatory, } = useTranslations();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    const [showBasicModal, setShowBasicModal] = useState(false);
    const handleshowBasicModal = () => setShowBasicModal(true);
    const handlecloseBasicModal = () => setShowBasicModal(false);


    return (
        <Container fluid>
            <Row>

                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Card.Body>
                        <Form>


                            <Row className="align-items-center mb-3">
                                <Col md={6} className="d-flex align-items-center">
                                    <h5 className="basicheading">{consentForm()}</h5>
                                </Col>
                                <Col md={6} className="text-end">
                                    <Button variant="secondary" className="basiccancelbtnn me-2">{cancel()}</Button>
                                    <Button variant="primary" className="basicsavebtn me-3">{save()}</Button>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col md={12}>
                                    <Card className="basicinfocard">
                                        <CardBody className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
                                                <span className="basicstatus">{pushstatus()}: Draft</span>
                                            </div>
                                            <Button className="basicsavebtn " style={{ width: '110px' }} onClick={handleshowBasicModal}>{assignTo()}</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row> */}

                            <Row className="mt-3">
                                <Col md={5}>
                                    {/* Campaign Name (Chinese) */}
                                    <Form.Group controlId="subsidiaryNameChinese" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="campaignName"
                                            value={inputValues.campaignName}
                                            onChange={handleChange}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">{titleChinese()}</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    {/* Campaign Name (English) */}
                                    <Form.Group controlId="subsidiaryNameEnglish" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="campaignName"
                                            value={inputValues.campaignName}
                                            onChange={handleChange}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">Title (English)</Form.Label>
                                    </Form.Group>
                                </Col>

                                <Col md={2} className="d-flex justify-content-center align-items-center">
                                    <Button
                                        style={{
                                            backgroundColor: '#1976D2',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            padding: '0'
                                        }}

                                    >
                                        <Icon>edit</Icon>
                                    </Button>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md={10}>
                                    {/* Introduction (Chinese) */}
                                    <Form.Group controlId="introductionChinese" className="floating-label-group">
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="introductionChinese"
                                            value={inputValues.introductionChinese}
                                            onChange={handleChange}
                                            className="floating-input"
                                            style={{ height: '200px' }}
                                        />
                                        <Form.Label className="floating-label">{contentChinese()}</Form.Label>
                                    </Form.Group>
                                </Col>

                                <Col md={2} className="d-flex justify-content-center align-items-center">
                                    <Button
                                        style={{
                                            backgroundColor: '#1976D2',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            padding: '0'
                                        }}

                                    >
                                        <Icon>edit</Icon>
                                    </Button>
                                </Col>

                            </Row>

                            <Row className="mt-3">
                                <Col md={10}>
                                    {/* Introduction (English) */}
                                    <Form.Group controlId="introductionEnglish" className="floating-label-group">
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="introductionEnglish"
                                            value={inputValues.introductionEnglish}
                                            onChange={handleChange}
                                            className="floating-input"
                                            style={{ height: '200px' }}

                                        />
                                        <Form.Label className="floating-label">Content (English)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={2} className="d-flex justify-content-center align-items-center">
                                    <Button
                                        style={{
                                            backgroundColor: '#1976D2',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            padding: '0'
                                        }}

                                    >
                                        <Icon>edit</Icon>
                                    </Button>
                                </Col>
                            </Row>

                            <Row className="mt-3">

                                <Col md={5}>
                                    <Form.Group controlId="campaignNameEnglish" className="floating-label-group">
                                        <Form.Select
                                            name="campaignName"
                                            value={inputValues.campaignName}
                                            onChange={handleChange}
                                            className="floating-input"
                                        >
                                            <option value=""></option>
                                            <option value="Age Range">Age Range</option>

                                        </Form.Select>
                                        <Form.Label className="floating-label">Criteria For Trigger</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    {/* Campaign Name (Chinese) */}
                                    <Form.Group controlId="subsidiaryNameChinese" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="rule"

                                            onChange={handleChange}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">{rule()}</Form.Label>
                                    </Form.Group>
                                </Col>

                                <Col md={2} className="d-flex justify-content-center align-items-center">
                                    <Button
                                        style={{
                                            backgroundColor: '#1976D2',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            padding: '0'
                                        }}

                                    >
                                        <Icon>edit</Icon>
                                    </Button>
                                </Col>

                            </Row>






                            <Row className="mt-5">
                                <h5 className="targetheading">{setConsentFormAsMandatory()} <FormControlLabel control={<Switch value="true" />} label="" /></h5>
                                <p className="publictext">{consentFormMandatory()}</p>
                            </Row>


                        </Form>
                    </Card.Body>
                </Card>
            </Row>


            <Modal
                show={showBasicModal}
                onHide={handlecloseBasicModal}
                centered
                className="custom-modal-width"
            >
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">
                            {assignTo}
                        </h4>

                        <Form.Group controlId="formStatus" className="mt-3 ms-3">

                            <Form.Check
                                type="checkbox"
                                label="Ling (Manager)"
                                name="status"
                                value="pre-approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Rainbow Lee (Campaign Organizer)"
                                name="status"
                                value="approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Alex Law (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Chan Siu Ming (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant=""
                        onClick={handlecloseBasicModal}
                        className="me-2 groupclear"
                    >
                        {cancel()}
                    </Button>
                    <Button
                        variant=""
                        className="groupsearch"
                        onClick={handlecloseBasicModal}
                    >
                        {save()}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Consent;
