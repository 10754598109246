


// export const navigations = [
//   {
//     name: "Home",
//     icon: "home",
//     path: "/dashboard/default",
//     permission: "home"  // A default permission key
//   },
//   {
//     name: "Role Management",
//     icon: "group_add",
//     path: "/role/rolemanagement",
//     permission: "role"  // Permission key for Role Management
//   },
//   {
//     name: "User Management",
//     icon: "person_outline",
//     path: "/user/usermanagement",
//     permission: "admin"  // Permission key for User Management
//   },
//   {
//     name: "Member Management",
//     icon: "mood",
//     path: "/member/membermanagement",
//     permission: "account"  // Permission key for Member Management
//   },
//   {
//     name: "User Defined Categories",
//     icon: "location_on",
//     path: "/user/usercategories",
//     permission: "sef_category"  // Permission key for Categories
//   },
//   {
//     name: "Push Notification",
//     icon: "open_in_browser",
//     path: "/pushnotification",
//     permission: "push_r"  // Permission key for Push Notifications
//   },
//   {
//     name: "Campaign Management",
//     icon: "content_paste",
//     path: "/campaign/campaignmanagement",
//     permission: "campaign_r"  // Permission key for Campaign Management
//   },
//   {
//     name: "Reports",
//     icon: "local_printshop",
//     path: "/report/reports",
//     permission: "report_r"  // Permission key for Reports
//   }
// ];




export const navigations = [
  {
    name: { zh: "首頁 - 儀表板", en: "Home" },
    icon: "home",
    path: "/dashboard/default",
    permission: "home"
  },
  {
    name: { zh: "角色管理", en: "Role Management" },
    icon: "group_add",
    path: "/role/rolemanagement",
    permission: "role"},
  {
    name: { zh: "使用者管理", en: "User Management" },
    icon: "person_outline",
    path: "/user/usermanagement",
    permission: "admin"
  },
  {
    name: { zh: "會員管理", en: "Member Management" },
    icon: "mood",
    path: "/member/membermanagement",
    permission: "account"
  },
  {
    name: { zh: "常用資源管理", en: "User Defined Categories" },
    icon: "location_on",
    path: "/user/usercategories",
    permission: "sef_category"
  },
  {
    name: { zh: "推播通知管理", en: "Push Notification" },
    icon: "open_in_browser",
    path: "/pushnotification",
    permission: "push"
  },
  {
    name: { zh: "活動管理", en: "Campaign Management" },
    icon: "content_paste",
    path: "/campaign/campaignmanagement",
    permission: "campaign"
  },
  {
    name: { zh: "報表", en: "Reports" },
    icon: "local_printshop",
    path: "/report/reports",
    permission: "report"
  }
];




