import { useLanguage } from 'app/contexts/LanguageContext';
import { join } from 'lodash';
import { Pass } from 'react-bootstrap-icons';

const useTranslationshome = () => {
    const { selectedLanguage } = useLanguage();

    const translate = (zh, en) => (selectedLanguage === 'zh' ? zh : en);

    return {
        listOfCompletedCampaigns: () => translate('已完成的活動', 'List of Completed Campaigns'),
        campaignName: () => translate('活動名稱', 'Campaign Name'),
        enrolmentMethod: () => translate('報名方式', 'Enrolment method'),
        totalEnrolledMembers: () => translate('成功報名人數', 'Total Enrolled members'),
        waitlisted: () => translate('候補人數', 'Waitlisted'),
        totalClasses: () => translate('總課堂數', 'Total classes'),
        totalClassAttendance: () => translate('總出席率', 'Total class Attendance'),
        totalClassAbsence: () => translate('總缺席率', 'Total class absence'),
        totalRating: () => translate('總評分人數', 'Total rating'),
        rating: () => translate('得分', 'Rating'),
        quota: () => translate('人數容量', 'Quota'),

        listOfOpenForRegistration: () => translate('報名中的活動', 'List of Open for Registration'),
        totalApplicants: () => translate('總報名人數', 'Total Applicants'),
        enrolmentType: () => translate('報名方式', 'Enrolment type'),
        enrolmentPeriod: () => translate('報名日期段', 'Enrolment period'),
        startDateAndEndDate: () => translate('開始日期/結束日期', 'Start Date and End Date'),
        spaceAvailable: () => translate('空額/剩餘', 'Space Available'),
        spaceTaken: () => translate('已登記', 'Space taken'),
        luckyDrawDate: () => translate('抽籤日期', 'Lucky Draw Date'),


        listOfCampaignInProgress: () => translate('進行中的活動', 'List of campaign in Progress'),
  totalClassesCompleted: () => translate('課程完成數', 'Total Classes Completed'),
  percentOfClassesCompleted: () => translate('完成百分比', '% of classes Completed'),
    };


};

export default useTranslationshome;
