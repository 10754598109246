import React, { useState, useEffect } from 'react';
import { Container, Row, Button, Card, Modal, Form, Col, CardBody } from 'react-bootstrap';
import { XCircle } from 'react-bootstrap-icons';
import pdfimg from './pdfimg.png'
import './campaigncss/FileUpload.css'
import { IoNotificationsOutline } from 'react-icons/io5';
import axios from 'axios';
import useTranslations from '../transalation/TranslationUtils';
import { add } from 'lodash';
function FileUpload({ campaignDetails }) {
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null); // To preview the selected image or PDF
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);

    const [formData, setFormData] = useState({
        setfileimg: [],
        setfilevideo: [],
    });

    useEffect(() => {
        if (campaignDetails && campaignDetails.campaign_medias) {
            const images = campaignDetails.campaign_medias.filter(media => media.type === "image");
            const videos = campaignDetails.campaign_medias.filter(media => media.type === "video");

            setFormData({
                setfileimg: images,
                setfilevideo: videos,
            });
        }
    }, [campaignDetails]);


    // Handle opening and closing modals
    const handleOpenPhotoModal = () => {
        setSelectedFile(null); // Reset the file when opening modal
        setPreviewUrl(null);
        setShowPhotoModal(true);
    };
    // const handleClosePhotoModal = () => setShowPhotoModal(false);
    const handleClosePhotoModal = () => {
        setShowPhotoModal(false);
        setSelectedFiles([]);
        setPreviewUrls([]);
    };

    const handleOpenDocumentModal = () => {
        setSelectedFile(null);
        setPreviewUrl(null);
        setShowDocumentModal(true);
    };
    const handleCloseDocumentModal = () => setShowDocumentModal(false);



    // Handle saving
    const handleSave = async () => {
        if (selectedFiles.length === 0) {
            alert("No files selected.");
            return;
        }

        try {
            const token = localStorage.getItem("authToken");

            // Step 1: Get File Upload URL
            const getFileUploadUrl = async (file) => {
                const response = await axios.post(
                    "https://membershipuat.maxisense.io/webapp/memportal/getfileuploadurl",
                    {
                        file_name: file.name,
                        file_type: file.type,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Token: token,
                        },
                    }
                );
                return response.data;
            };

            // Step 2: Return presigned URL to Upload File
            const uploadFileToS3 = async (file, presignedUrl) => {
                await axios.put(presignedUrl, file, {
                    headers: {
                        "Content-Type": file.type,
                    },
                });
            };

            // Step 3: Use presigned URL to Upload File to S3
            const uploadFiles = async () => {
                const uploadedFiles = [];
                for (const file of selectedFiles) {
                    const { presigned_url, file_url } = await getFileUploadUrl(file);
                    await uploadFileToS3(file, presigned_url);
                    uploadedFiles.push({
                        type: file.type.startsWith("image/") ? "image" : "video",
                        url: file_url,
                    });
                }
                return uploadedFiles;
            };

            const uploadedFiles = await uploadFiles();

            // Step 4: Check if upload is OK or Not
            if (uploadedFiles.length === 0) {
                alert("Failed to upload files.");
                return;
            }
            // Step 5: Set image_url Get from “Get File Upload URL” in Campaign Medias or Campaign Icon
            const payload = {
                campaign_id: campaignDetails.campaign_id,
                campaign_medias: uploadedFiles,
            };

            // Step 6: Return Success, then Campaign image will be updated
            const response = await axios.post(
                "https://membershipuat.maxisense.io/webapp/memportal/updatefileupload",
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Token: token,
                    },
                }
            );

            if (response.data?.code === 1) {
                alert("Files uploaded successfully!");
                handleClosePhotoModal();
                handleCloseDocumentModal();
            } else {
                alert(`Error: ${response.data?.message_en || "An error occurred."}`);
            }
        } catch (error) {
            console.error("Error uploading files:", error);
            alert("Unable to upload files. Please try again later.");
        }
    };


    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);

        // Check if the number of selected files exceeds the limit of 3
        if (selectedFiles.length + files.length > 3) {
            alert("You can only select up to 3 images/videos.");
            return;
        }

        // Add new files to the list
        const updatedFiles = [...selectedFiles, ...files];
        setSelectedFiles(updatedFiles);

        // Preview the images/videos
        const newPreviewUrls = files.map((file) => URL.createObjectURL(file));
        setPreviewUrls((prev) => [...prev, ...newPreviewUrls]);
    };

    // Remove a selected file
    const handleRemoveFile = (index) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(updatedFiles);

        const updatedPreviewUrls = previewUrls.filter((_, i) => i !== index);
        setPreviewUrls(updatedPreviewUrls);
    };




    const [showBasicModal, setShowBasicModal] = useState(false);
    const handleshowBasicModal = () => setShowBasicModal(true);
    const handlecloseBasicModal = () => setShowBasicModal(false);


    const { cancel, save, pushstatus, assignTo, date,
        promotionStartDate,
        promotionEndDate,
        registrationStartDate,
        registrationEndDate,
        campaignStartDate,
        campaignEndDate,
        recurrenceChinese, uploadCampaignPhotoVideo, addActivityPhotoVideo, addActivityIcon } = useTranslations();




    return (
        <Container >
            <Row>
                <Card className="mt-0 border-0" style={{ position: 'relative' }}>
                    <Card.Body>

                        <Row className="align-items-center mb-3">
                            <Col md={6} className="d-flex align-items-center">
                                <h5 className="basicheading">{uploadCampaignPhotoVideo()}</h5>
                            </Col>
                            <Col md={6} className="text-end">
                                {/* <Button variant="secondary" className="basiccancelbtnn me-2">{cancel()}</Button> */}
                                <Button variant="primary" className="basicsavebtn me-3">{save()}</Button>
                            </Col>
                        </Row>

                        {/* <Row>
                            <Col md={12}>
                                <Card className="basicinfocard">
                                    <CardBody className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
                                            <span className="basicstatus">{pushstatus()}: Draft</span>
                                        </div>
                                        <Button className="basicsavebtn " style={{ width: '110px' }} onClick={handleshowBasicModal}>{assignTo()}</Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> */}
                        <br />
                        <Row>
                            <Button style={{ width: '200px', fontSize: '13px' }} onClick={handleOpenPhotoModal} className='filebtn ms-2'>
                                {addActivityPhotoVideo()}
                            </Button>
                        </Row>
                        <br />
                        <Row>
                            <Button style={{ width: '200px', fontSize: '13px' }} onClick={handleOpenDocumentModal} className='filebtn ms-2'>
                                {addActivityIcon()}
                            </Button>
                        </Row>


                    </Card.Body>
                </Card>
            </Row>





            <Modal show={showPhotoModal} onHide={handleClosePhotoModal} centered size="lg" className="custom-modal">
                <Modal.Body style={{ height: '560px' }}>
                    <h4 className="maintitle">{addActivityPhotoVideo()}</h4>

                    {/* Display Campaign Details Media */}
                    {formData.setfileimg.length > 0 && (
                        <div style={{ marginTop: '20px' }}>
                            <h5>Images</h5>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                {formData.setfileimg.map((media, index) => (
                                    <img
                                        key={index}
                                        src={media.url}
                                        alt={`Activity Image ${index}`}
                                        style={{ width: '175px', height: '121px', borderRadius: '5px' }}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {formData.setfilevideo.length > 0 && (
                        <div style={{ marginTop: '20px' }}>
                            <h5>Videos</h5>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                {formData.setfilevideo.map((media, index) => (
                                    <video
                                        key={index}
                                        width="375"
                                        height="221"
                                        controls
                                        style={{ borderRadius: '5px' }}
                                    >
                                        <source src={media.url} type="video/mp4" />
                                    </video>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Fallback to Upload Functionality */}
                    {(formData.setfileimg.length === 0 && formData.setfilevideo.length === 0) && (
                        <>
                            <div style={{ textAlign: 'center', height: '156px', padding: '40px', margin: '20px', border: '1px solid #D9D9D9' }}>
                                <p>Ratio limit 4:3, e.g. 323(W) * 180(H). File size: within 500MB</p>
                                <Form.Group controlId="formFile">
                                    <input
                                        type="file"
                                        id="file-input"
                                        accept="image/*,video/*"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        multiple // Allow multiple files
                                    />
                                    <Button
                                        onClick={() => document.getElementById('file-input').click()}
                                        disabled={selectedFiles.length >= 3} // Disable if 3 files are selected
                                    >
                                        Select
                                    </Button>
                                </Form.Group>
                            </div>

                            {/* Preview Uploaded Files */}
                            {previewUrls.length > 0 && (
                                <div style={{ textAlign: 'left', marginTop: '20px' }}>
                                    {previewUrls.map((url, index) => (
                                        <div key={index} style={{ marginBottom: '10px' }}>
                                            {selectedFiles[index].type.startsWith('image/') ? (
                                                <img src={url} alt={`Preview ${index}`} style={{ width: '175px', height: '121px' }} />
                                            ) : (
                                                <video width="375" height="221" controls>
                                                    <source src={url} />
                                                </video>
                                            )}
                                            <XCircle
                                                style={{ cursor: 'pointer', color: 'red', marginTop: '10px' }}
                                                size={24}
                                                onClick={() => handleRemoveFile(index)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePhotoModal}>
                        {cancel()}
                    </Button>
                    <Button variant="primary" onClick={handleClosePhotoModal} disabled={selectedFiles.length === 0}>
                        {save()}
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Document Upload Modal */}
            <Modal show={showDocumentModal} onHide={handleCloseDocumentModal} centered size="lg" className="custom-modal">
                <Modal.Body style={{ height: '560px' }}>
                    <h4 className="maintitle">{addActivityIcon()}</h4>
                    <div style={{ textAlign: 'center', height: '156px', padding: '40px', margin: '20px', border: '1px solid #D9D9D9' }}>
                        <p>Ratio limit 4:3, e.g. 79(W) * 92(H). File size: within 10MB</p>
                        <Form.Group controlId="formFileDocument">
                            <input
                                type="file"
                                id="file-input-doc"
                                accept=".pdf,.doc,.docx"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <Button onClick={() => document.getElementById('file-input-doc').click()}>Select</Button>
                        </Form.Group>
                    </div>

                    {/* Preview PDF or Document */}
                    {previewUrl && (
                        <div style={{ textAlign: 'left', marginTop: '20px' }}>
                            {selectedFile.type === 'application/pdf' ? (
                                <img src={pdfimg} alt="PDF Preview" style={{ width: '66px', height: '73px' }} />

                            ) : (
                                <img src="pdfimg.png" alt="PDF Preview" style={{ width: '66px', height: '73px' }} />
                            )}
                            <XCircle
                                style={{ cursor: 'pointer', color: 'red', marginTop: '10px' }}
                                size={24}
                                onClick={handleRemoveFile}
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDocumentModal}>
                        {cancel()}
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={!selectedFile}>
                        {save()}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showBasicModal}
                onHide={handlecloseBasicModal}
                centered
                className="custom-modal-width"
            >
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">
                            {assignTo()}
                        </h4>

                        <Form.Group controlId="formStatus" className="mt-3 ms-3">

                            <Form.Check
                                type="checkbox"
                                label="Ling (Manager)"
                                name="status"
                                value="pre-approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Rainbow Lee (Campaign Organizer)"
                                name="status"
                                value="approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Alex Law (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Chan Siu Ming (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant=""
                        onClick={handlecloseBasicModal}
                        className="me-2 groupclear"
                    >
                        {cancel()}
                    </Button>
                    <Button
                        variant=""
                        className="groupsearch"
                        onClick={handlecloseBasicModal}
                    >
                        {save()}
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
}

export default FileUpload;

