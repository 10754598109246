import React, { useState, Fragment, useEffect } from "react";
import {
    Box,
    Card,
    Grid,
    Drawer,
    Button,
    useTheme,
    IconButton,
    useMediaQuery
} from "@mui/material";
import { H5 } from "app/components/Typography";
import { FlexBox } from "app/components/FlexBox";
import Apps from "app/components/icons/Apps";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslations from "../transalation/TranslationUtils";

// Styled button component
import { styled } from "@mui/system";
import BasicInfo from "./BasicInfo";
import Datepage from "./Datepage";
import Instructor from "./Instructor";
import Lesson from "./Lesson";
import FileUpload from "./FileUpload";
import Survy from "./Survy";
import Enrollnment from "./Enrollnment";
import Attendencedetail from "./Attendencedetail";
import Consent from "./Consent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { MODE_ADD, MODE_EDIT, MODE_UNKNOWN } from "app/utils/constant";
import { clearEditCampaign, getCampaignDetail, setEditCampaignData } from "app/redux/slices/rdxCampaignSlice";
import Breadcrumb from "app/components/Breadcrumb";

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 0,
    overflow: "hidden",
    position: "relative",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    padding: "0.6rem 1.5rem",
    justifyContent: "flex-start",
    color: theme.palette.text.primary
}));

const Mainpage = ({ selectedCampaign }) => {
    const theme = useTheme();
    const [campaignDetails, setCampaignDetails] = useState({});

    const {
        date,
        basicInformation,
        instructor,
        lessonDetails,
        uploadCampaignPhotoVideo,
        survey,
        consentForm,
        enrolmentStatus,
        attendanceDetails,
        campaignManagement,
        mAdd,
        mEdit,
        showErrMsg
    } = useTranslations();

    const tabList = [
        { id: 1, name: basicInformation(), component: <BasicInfo /> },
        { id: 2, name: date(), component: <Datepage /> },
        { id: 3, name: instructor(), component: <Instructor campaignDetails={campaignDetails} /> },
        { id: 4, name: lessonDetails(), component: <Lesson campaignDetails={campaignDetails} /> },
        { id: 5, name: uploadCampaignPhotoVideo(), component: <FileUpload campaignDetails={campaignDetails} /> },
        { id: 6, name: survey(), component: <Survy /> },
        // { id: 7, name: consentForm(), component: <Consent /> },
        { id: 8, name: enrolmentStatus(), component: <Enrollnment campaignDetails={campaignDetails} /> },
        { id: 9, name: attendanceDetails(), component: <Attendencedetail campaignDetails={campaignDetails} /> }
    ];

    const style = {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.grey[100],
        "&::before": {
            left: 0,
            width: 4,
            content: '""',
            height: "100%",
            position: "absolute",
            transition: "all 0.3s",
            backgroundColor: theme.palette.primary.main
        }
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState(false);
    const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const location = useLocation();


    const [active, setActive] = useState(1); // Default tab
    const [breadCrumbInit, setBreadCrumbInit] = useState(false);
    const campaignEditMode = useSelector((state) => state.rdxCampaign?.campaignEditMode || MODE_UNKNOWN);
    const campaignEditData = useSelector((state) => state.rdxCampaign?.campaignEditData || {});


    useEffect(() => {
        // Component mount logic
        const searchParams = new URLSearchParams(location.search);
        const campaignId = searchParams.get("campaign_id");

        dispatch(getCampaignDetail(campaignId, (isSuccess, data) => {
            if (!isSuccess) {
                alert(showErrMsg(data));
                navigate("/campaign/campaignmanagement");
            } else {
                //TODO peteryeh 過度寫法, 等 child component 不再依賴 campaignDetails 時去除
                setCampaignDetails(data.data);
            }
        }));

        // dispatch(otherCommonData());

        return () => {
            // Component unmount logic
            dispatch(clearEditCampaign())
        };
    }, []);

    useEffect(() => {
        // Component mount logic
        if (MODE_EDIT == campaignEditMode || MODE_ADD == campaignEditMode) {
            dispatch(setEditCampaignData(campaignEditData));
            setActive(1);
            setBreadCrumbInit(true);
        }
    }, [campaignEditMode]);

    const checkTabDisabled = (id) => {
        if (id == 1) {
            return false;
        } else {
            return !campaignEditData.campaign_id;
        }
    }

    function TabListContent() {
        return (
            <FlexBox flexDirection="column">
                {tabList.map(({ id, name }) => (
                    <StyledButton
                        key={id}
                        sx={active === id ? style : {}}
                        onClick={() => {
                            if (!checkTabDisabled(id)) {
                                setActive(id);
                                setOpenDrawer(false);
                            } else {
                                alert("Please save the Basic Information & Fee data first!");
                            }
                        }}
                        disabled={checkTabDisabled(id)} // Disable other tabs
                    >
                        {name}
                    </StyledButton>
                ))}
            </FlexBox>
        );
    }

    return (
        <Box p={4}>
            {(!breadCrumbInit) ? (
                <React.Fragment></React.Fragment>
            ) : (
                <div className="breadcrumb">
                    {(MODE_EDIT == campaignEditMode) ? (
                        <Breadcrumb routeSegments={[{ name: campaignManagement(), path: "/campaign/campaignmanagement" }, { name: mEdit() }]} />
                    ) : (
                        <Breadcrumb routeSegments={[{ name: campaignManagement(), path: "/campaign/campaignmanagement" }, { name: mAdd() }]} />
                    )}
                </div>
            )}
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    {downMd ? (
                        <Fragment>
                            <FlexBox alignItems="center" gap={1}>
                                <IconButton sx={{ padding: 0 }} onClick={() => setOpenDrawer(true)}>
                                    <Apps sx={{ color: "primary.main" }} />
                                </IconButton>
                                <H5>Show More</H5>
                            </FlexBox>

                            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                                <Box padding={1}>
                                    <TabListContent />
                                </Box>
                            </Drawer>
                        </Fragment>
                    ) : (
                        <Card sx={{ padding: "1rem 0", marginTop: "25px" }}>
                            <TabListContent />
                        </Card>
                    )}
                </Grid>

                <Grid item md={9} xs={12}>
                    {/* Render the active tab's component */}
                    {tabList.find((tab) => tab.id === active)?.component || (
                        <BasicInfo />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Mainpage;

