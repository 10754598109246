import React from 'react'
import Breadcrumb from "app/components/Breadcrumb";
import useTranslationshome from './Hometransalte'
import { Form, Button, Table, Container, Row, Col, Card } from "react-bootstrap";
const OpenRegistration = () => {

    const { listOfCompletedCampaigns,
        campaignName,
        enrolmentMethod,
        totalEnrolledMembers,
        waitlisted,
        totalClasses,
        totalClassAttendance,
        totalClassAbsence,
        totalRating, quota,
        rating, listOfOpenForRegistration,
        totalApplicants,
        enrolmentType,
        enrolmentPeriod,
        startDateAndEndDate,
        spaceAvailable,
        spaceTaken,
        luckyDrawDate, } = useTranslationshome();

    return (
        <div>
            <Row className="ps-4 pt-4 align-items-center">
                <Col>
                    <Breadcrumb routeSegments={[{ name: listOfOpenForRegistration() }]} />
                </Col>

            </Row>
            <table className="table table-striped mt-4">
                <thead>
                    <tr>
                        <th className="custom-th">{campaignName()}</th>
                        <th className="custom-th">{totalApplicants()}</th>
                        <th className="custom-th">{enrolmentType()}</th>
                        <th className="custom-th">{enrolmentPeriod()}</th>
                        <th className="custom-th">{startDateAndEndDate()}</th>
                        <th className="custom-th">{totalClasses()}</th>
                        <th className="custom-th">{spaceAvailable()}</th>
                        <th className="custom-th">{spaceTaken()}</th>
                        <th className="custom-th">{luckyDrawDate()}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="custom-td">
                        <td>Cooking Class</td>
                        <td>21</td>
                        <td>Lottery Draw</td>
                        <td>05/10/2024-15/10/2024</td>
                        <td>10/28/2024-11/4/2024</td>
                        <td>2</td>
                        <td>15</td>
                        <td>0</td>
                        <td>10/16/2024</td>



                    </tr>
                    <tr className="custom-td">
                        <td>3D Gaming Class</td>
                        <td>21</td>
                        <td>Lottery Draw</td>
                        <td>10/5/2024-10/15/2024</td>
                        <td>10/28/2024-11/4/2024</td>
                        <td>2</td>
                        <td>15</td>
                        <td>0</td>
                        <td>10/16/2024</td>

                    </tr>
                </tbody>
            </table></div>
    )
}

export default OpenRegistration