import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    navItems: [],
    pagePermissions: {}
};

const rdxMainSlice = createSlice({
    initialState,
    name: "rdxMain",
    extraReducers: (builder) => { },
    reducers: {
        updateNavViewData(state, actions) {
            state.navItems = actions.payload;
        },
        setPagePermissions(state, actions) {
            let permissions = actions.payload;

            let result = {};
            permissions.forEach((item) => {
                let arr = item.split("_");
                let cat = arr[0];
                let func = arr[1];

                if (!result[cat]) {
                    result[cat] = {};
                }

                if ("campaign_review" == item) {
                    result["campaign"]["campaign_review"] = true;
                } else if (func) {
                    switch (func) {
                        case "u":
                            result[cat]["add"] = true;
                            result[cat]["edit"] = true;
                            break;
                        case "d":
                            result[cat]["delete"] = true;
                            break;
                    }
                }
            });

            state.pagePermissions = result;
        },
    }
});

export const { updateNavViewData, setPagePermissions } = rdxMainSlice.actions;

export const rdxMainReducer = rdxMainSlice.reducer;