export const authRoles = {
  sa: ["SA"], // Only Super Admin has access
  admin: ["SA", "ADMIN"], // Only SA & Admin has access
  editor: ["SA", "ADMIN", "EDITOR"], // Only SA & Admin & Editor has access
  guest: ["SA", "ADMIN", "EDITOR", "GUEST"] // Everyone has access
};

export const defaultRoutes = {
  "SA": "/session/signin",
  "ADMIN": "/dashboard/analytics",
  "EDITOR": "/dashboard/alternative",
  "GUEST": "/dashboard/inventory-management",
}