import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import "./membercss/MemberInfo.css";
import './membercss/BasicInformation.css';
import Avatars from './Avatars';
import useTranslations from '../transalation/TranslationUtils';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createMember, getMemberDetail, setMemberPageFormData, setPageFormToMemberData, updateMember } from "app/redux/slices/rdxMemberSlice";
import { MODE_EDIT, MODE_UNKNOWN } from "app/utils/constant";
import { useNavigate } from "react-router-dom";
function BasicInformation() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { basicinfo, savechaneg, showErrMsg } = useTranslations();
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const translations = useTranslations();
    const formMap = useSelector((state) => state.rdxMember?.basicInfoFormMap || {});
    const memberEditMode = useSelector((state) => state.rdxMember?.memberEditMode || MODE_UNKNOWN);
    const memberEditData = useSelector((state) => state.rdxMember?.memberEditData || {});

    const handleInputChange = (e, key, item) => {
        const { value } = e.target;

        let canUpdateFormVal = false;
        if ("account_birth" == key) {
            canUpdateFormVal = true;

            // Calculate age if date of birth is changed
            let age = calculateAge(value);
            let data = {
                data: { key: "f_age", val: age },
                projection: "basicInfoFormMap"
            };
            dispatch(setMemberPageFormData(data));
        } else if ("account_phone" == key) {
            // Ensure mobile are numeric and max 10 digits
            if (/^\d{0,10}$/.test(value)) {
                canUpdateFormVal = true;
            }
        } else if ("member_id" == key) {
            // Ensure member ID are numeric and max 8 digits
            if (/^\d{0,8}$/.test(value)) {
                canUpdateFormVal = true;
            }
        } else if ("card_ref" == key) {
            // Ensure card ref is alphanumeric and max 5 characters
            if (/^[a-zA-Z0-9]{0,5}$/.test(value)) {
                canUpdateFormVal = true;
            }
        } else {
            canUpdateFormVal = true;
        }

        if (canUpdateFormVal) {
            let data = {
                data: { key: key, val: value },
                projection: "basicInfoFormMap"
            };
            dispatch(setMemberPageFormData(data));
        }
    };

    const onBasicInfoPicChanged = (newPic) => {
        let picUrl = "https://membershipuat.maxisense.io" + newPic.replace("/assets", "").replace("https://membershipuat.maxisense.io", "");
        // console.log("on avatar changed:", picUrl);

        let data = {
            data: { key: "account_avatar", val: picUrl },
            projection: "basicInfoFormMap"
        };
        dispatch(setMemberPageFormData(data));
    }

    const calculateAge = (dob) => {
        if (!dob) return "";

        const birthDate = new Date(dob);
        const today = new Date();

        let years = today.getFullYear() - birthDate.getFullYear();

        // Adjust if the current date is before the birth date in the current year
        const isBeforeBirthdayThisYear =
            today.getMonth() < birthDate.getMonth() ||
            (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate());

        if (isBeforeBirthdayThisYear) {
            years -= 1;
        }

        return (`${years} ${years !== 1 ? '' : ''}`);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formMap["account_phone"].val.length > 10) {
            setError("Phone number should not exceed 10 digits.");
            return;
        }

        if (formMap["member_id"].val.length > 8) {
            setError("Member ID should not exceed 8 digits.");
            return;
        }

        // Email validation: simple regex for basic validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formMap["account_email"].val)) {
            setError("Please enter a valid email address.");
            return;
        }

        // Joined Date validation: must not be a future date
        // const today = new Date().toISOString().split("T")[0];
        // if (inputValues.input9 > today) {
        //     setError("Joined Date cannot be a future date.");
        //     return;
        // }

        if (MODE_EDIT == memberEditMode) {
            let sendData = {
                "account_id": memberEditData.account_id
            };
            for (const key in formMap) {
                let formItem = formMap[key];
                if (!formItem.ignore) {
                    sendData[key] = formMap[key].val;
                }
            }
            dispatch(updateMember(sendData, (isSuccess, data) => {
                if (isSuccess) {
                    dispatch(setPageFormToMemberData({ formMap: formMap }));
                    setSuccessMessage("Basic information saved successfully!");
                    setError(null);
                } else {
                    setSuccessMessage(null);
                    setError(showErrMsg(data));
                }
            }));
        } else {
            let sendData = {};
            for (const key in formMap) {
                let formItem = formMap[key];
                if (!formItem.ignore) {
                    sendData[key] = formMap[key].val;
                }
            }
            dispatch(createMember(sendData, (isSuccess, data) => {
                if (isSuccess) {
                    setSuccessMessage("Basic information create successfully!");
                    setError(null);

                    navigate("/member/memberinfo?account_id=" + data.data.account_id);
                    dispatch(getMemberDetail(data.data.account_id, (isSuccess, data) => {
                        if (!isSuccess) {
                            alert(showErrMsg(data));
                            navigate("/member/membermanagement");
                        }
                    }));
                } else {
                    setSuccessMessage(null);
                    setError(showErrMsg(data));
                }
            }));
        }
    };


    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card className="mt-0 border-0">
                        <Card.Body>
                            <Avatars editable={true} onAvatarChanged={onBasicInfoPicChanged} initAvatar={formMap["account_avatar"].val} />
                            <br />
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <h5 className="basicinfo">{basicinfo()}</h5>
                                    {Object.keys(formMap).map(key => (
                                        <React.Fragment key={key}>
                                            {"input" == formMap[key].type ? (
                                                <Col md={6}>
                                                    <Form.Group controlId={key} className="floating-label-group">
                                                        <Form.Control
                                                            disabled={formMap[key].ignore}
                                                            type="text"
                                                            name={key}
                                                            value={formMap[key]["val"]}
                                                            onChange={(event) => handleInputChange(event, key, formMap[key])}
                                                            className="floating-input basicinfoinput"
                                                        />
                                                        <Form.Label className="floating-label basicinfolabel">{translations[formMap[key].label]()}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            ) : "select" == formMap[key].type ? (
                                                <Col md={6}>
                                                    <Form.Group controlId={key} className="floating-label-group">
                                                        <Form.Select
                                                            disabled={formMap[key].ignore}
                                                            name={key}
                                                            value={formMap[key]["val"]}
                                                            onChange={(event) => handleInputChange(event, key, formMap[key])}
                                                            className="floating-input"
                                                        >
                                                            {formMap[key].options.map(({ label, value }) => (
                                                                <option key={label} value={value}>{translations[label]()}</option>
                                                            ))}
                                                        </Form.Select>
                                                        <Form.Label className="floating-label basicinfolabel">{translations[formMap[key].label]()}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            ) : "datepicker" == formMap[key].type ? (
                                                <Col md={6}>
                                                    <Form.Group controlId={key} className="floating-label-group">
                                                        <Form.Control
                                                            disabled={formMap[key].ignore}
                                                            type="date"
                                                            name={key}
                                                            value={formMap[key]["val"]}
                                                            onChange={(event) => handleInputChange(event, key, formMap[key])}
                                                            className="floating-input"
                                                        />
                                                        <Form.Label className="floating-label basicinfolabel">{translations[formMap[key].label]()}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            ) : (<React.Fragment></React.Fragment>)}
                                        </React.Fragment>
                                    ))}
                                </Row>

                                <Row className="mt-5">
                                    <Col>
                                        <Button variant="primary" type="submit" className="me-2 instructorphoto">
                                            {savechaneg()}
                                        </Button>
                                        {/* <Button variant="outline-primary" onClick={() => setInputValues({})} className="canslebtn">
                                            {cancel()}
                                        </Button> */}
                                    </Col>
                                </Row>

                                {error && <p className="text-danger mt-3">{error}</p>}
                                {successMessage && <p className="text-success mt-3">{successMessage}</p>}
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}

export default BasicInformation;



