import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, Collapse, CardBody, Modal } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa'; // Import Plus and Minus icons
import './campaigncss/Lesson.css';
import { IoNotificationsOutline } from 'react-icons/io5';
import axios from 'axios';
import { Add } from '@mui/icons-material';
import { IoIosArrowDown } from "react-icons/io";
import useTranslations from '../transalation/TranslationUtils';
import { Icon } from '@mui/material';
function Lesson({ campaignDetails }) {
    const [showBasicModal, setShowBasicModal] = useState(false);
    const handleshowBasicModal = () => setShowBasicModal(true);
    const handlecloseBasicModal = () => setShowBasicModal(false);
    const [venuedata, setvenuedataData] = useState([]);
    const [lessoninputValues, setLessonInputValues] = useState([]);
    const [collapsed, setCollapsed] = useState({}); // State to manage collapse for each card

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);


    const { cancel, save, pushstatus, assignTo, date, venueChinese, lessonDetails,
        lessonNameChinese,
        startTime,
        endTime, role_delete, normal, delayed, cancelled } = useTranslations();




    const handleShowDeleteModal = (index) => {
        setDeleteIndex(index);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = () => {
        if (deleteIndex !== null) {
            handleRemoveLesson(deleteIndex);
            handleCloseDeleteModal();
        }
    };

    // Fetch venues data
    const fetchCampaignVenues = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getvenuelist", {
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
            });

            if (response.data.code === 1) {
                setvenuedataData(response.data.data || []);
            } else {
                console.error("API responded with an error:", response.data.message_en);
            }
        } catch (error) {
            console.error("Error fetching campaign venues:", error);
        }
    };

    // Fetch campaign venues on mount and every 3 seconds
    useEffect(() => {
        fetchCampaignVenues();
        // const intervalId = setInterval(fetchCampaignVenues, 3000);
        // return () => {
        //     clearInterval(intervalId);
        // }
    }, []);

    // Handle lesson form updates when campaignDetails changes
    useEffect(() => {
        if (campaignDetails && campaignDetails.listCousre && campaignDetails.listCousre.length > 0) {
            const courses = campaignDetails.listCousre.map((course) => ({
                course_id: course.course_id || "",
                lessonzh: course.course_name?.zh || "",
                lessonen: course.course_name?.en || "",
                venueeng: course.venue_id || "",
                venuezh: course.venue_id || "",
                lessondate: formatDatelesson(course.course_start_time),
                lessonstarttime: formatDate(course.course_start_time, "time"),
                lessonendtime: formatDate(course.course_end_time, "time"),
                lessonstatus: course.course_status || "",
                isEditable: false,
            }));
            console.log(campaignDetails)
            setLessonInputValues(courses);
        }
    }, [campaignDetails]);

    const handleEditLesson = (index) => {
        setLessonInputValues((prevLessons) => {
            const updatedLessons = [...prevLessons];
            updatedLessons[index].isEditable = !updatedLessons[index].isEditable;
            return updatedLessons;
        });
    };


    // Format date function for mm-dd-yyyy
    const formatDatelessonhome = (timestamp) => {
        if (!timestamp) return "";
        const date = new Date(timestamp);
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const year = date.getFullYear();
        return `${month}-${day}-${year}`; // Format as mm-dd-yyyy
    };

    const formatDatelesson = (timestamp) => {
        if (!timestamp) return "";
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`; // Format: yyyy-MM-dd
    };

    // Format time function
    const formatDate = (timestamp, type) => {
        if (!timestamp) return "";
        const date = new Date(timestamp);
        if (isNaN(date.getTime())) return "";

        if (type === "time") {
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
        }
        return "";
    };

    const handleToggleCollapse = (index) => {
        setCollapsed((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // Toggle collapse state for the clicked card
        }));
    };





    const handleAddNewLesson = () => {
        const newIndex = lessoninputValues.length;
        setLessonInputValues((prevLessons) => [
            ...prevLessons,
            {
                lessonzh: "",
                lessonen: "",
                venueeng: "",
                venuezh: "",
                lessondate: "",
                lessonstarttime: "",
                lessonendtime: "",
                lessonstatus: "",
            },
        ]);
        // Automatically open the form for the new lesson
        setCollapsed((prevState) => ({
            ...prevState,
            [newIndex]: true,
        }));
    };




    const handleRemoveLesson = (index) => {
        setLessonInputValues((prevLessons) => prevLessons.filter((_, i) => i !== index));
    };

    const [status, setStatus] = useState("Normal");
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };



    // const handleSaveLesson = async () => {
    //     const token = localStorage.getItem("authToken");
    //     if (!token) {
    //         alert("Authentication token not found. Please log in again.");
    //         return;
    //     }

    //     // Ensure there is at least one lesson
    //     if (lessoninputValues.length === 0) {
    //         alert("No lesson data to save. Please add a lesson first.");
    //         return;
    //     }

    //     // Use the first lesson in the array to create the payload
    //     const lesson = lessoninputValues[lessoninputValues.length - 1]; // Only save the last added lesson

    //     // Rest of your validation logic
    //     const courseStartTime = lesson.lessondate && lesson.lessonstarttime
    //         ? new Date(`${lesson.lessondate}T${lesson.lessonstarttime}:00Z`).getTime()
    //         : null;
    //     const courseEndTime = lesson.lessondate && lesson.lessonendtime
    //         ? new Date(`${lesson.lessondate}T${lesson.lessonendtime}:00Z`).getTime()
    //         : null;

    //     if (courseStartTime === null || courseEndTime === null) {
    //         alert("Start time and end time are required.");
    //         return;
    //     }

    //     if (courseEndTime <= courseStartTime) {
    //         alert("End time must be after the start time.");
    //         return;
    //     }

    //     const payload = {
    //         campaign_id: campaignDetails.campaign_id || "",
    //         course_name: {
    //             zh: lesson.lessonzh || "",
    //             en: lesson.lessonen || "",
    //         },
    //         course_status: lesson.lessonstatus ? parseInt(lesson.lessonstatus, 10) : 1,
    //         venue_id: lesson.venueeng || "",
    //         course_start_time: courseStartTime,
    //         course_end_time: courseEndTime,
    //     };


    //     console.log("Payload  sending:", JSON.stringify(payload));
    //     try {
    //         const response = await axios.post(
    //             "https://membershipuat.maxisense.io/webapp/memportal/addcourse",
    //             payload,
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Token: token,
    //                 },
    //             }
    //         );

    //         if (response.data?.code === 1) {
    //             alert("Course lesson created successfully!");
    //         } else {
    //             alert(`Error: ${response.data?.message_en || "An error occurred."}`);
    //         }
    //     } catch (error) {
    //         console.error("Error creating course lesson:", error);
    //         alert("Unable to create course lesson. Please try again later.");
    //     }
    // };


    const handleSaveLesson = async () => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            alert("Authentication token not found. Please log in again.");
            return;
        }

        // Iterate through all lessons and process them
        for (const lesson of lessoninputValues) {
            // Calculate start and end times
            const courseStartTime = lesson.lessondate && lesson.lessonstarttime
                ? new Date(`${lesson.lessondate}T${lesson.lessonstarttime}:00Z`).getTime()
                : null;
            const courseEndTime = lesson.lessondate && lesson.lessonendtime
                ? new Date(`${lesson.lessondate}T${lesson.lessonendtime}:00Z`).getTime()
                : null;

            if (!courseStartTime || !courseEndTime || courseEndTime <= courseStartTime) {
                alert("Invalid start or end time for one or more lessons.");
                return;
            }

            // Prepare the payload
            const payload = {
                campaign_id: campaignDetails.campaign_id,
                course_name: {
                    zh: lesson.lessonzh || "",
                    en: lesson.lessonen || "",
                },
                course_status: lesson.lessonstatus ? parseInt(lesson.lessonstatus, 10) : 1,
                venue_id: lesson.venueeng || "",
                course_start_time: courseStartTime,
                course_end_time: courseEndTime,
            };

            if (lesson.course_id) {
                // Update existing course
                payload.course_id = lesson.course_id; // Include course_id for update
                try {
                    const response = await axios.post(
                        "https://membershipuat.maxisense.io/webapp/memportal/updatecourse",
                        payload,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Token: token,
                            },
                        }
                    );

                    if (response.data?.code === 1) {
                        console.log(`Course updated successfully: ${lesson.course_id}`);

                    } else {
                        alert(`Error updating course: ${response.data?.message_en || "Unknown error"}`);
                    }
                } catch (error) {
                    console.error(`Error updating course ${lesson.course_id}:`, error);
                }
            } else {
                // Create a new course
                try {
                    const response = await axios.post(
                        "https://membershipuat.maxisense.io/webapp/memportal/addcourse",
                        payload,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Token: token,
                            },
                        }
                    );

                    if (response.data?.code === 1) {
                        console.log("New course created successfully.");

                    } else {
                        alert(`Error creating course: ${response.data?.message_en || "Unknown error"}`);
                    }
                } catch (error) {
                    console.error("Error creating new course:", error);
                }
            }
        }

        alert("All changes saved successfully!");
    };





    const handleDeleteLesson = async () => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            alert("Authentication token not found. Please log in again.");
            return;
        }

        // Get the lesson to delete based on the deleteIndex
        const lessonToDelete = lessoninputValues[deleteIndex];

        // Find the corresponding course from listCousre using course_id
        const courseToDelete = campaignDetails.listCousre.find(course =>
            course.course_name.zh === lessonToDelete.lessonzh &&
            course.course_name.en === lessonToDelete.lessonen
        );

        if (!courseToDelete) {
            alert("Course not found for the lesson. Please try again.");
            return;
        }

        const payload = {
            campaign_id: campaignDetails.campaign_id,  // Use the campaign_id from the found course
            course_id: courseToDelete.course_id,     // Use the course_id from the found course
        };

        console.log("Payload before delete:", JSON.stringify(payload, null, 2));

        try {
            const response = await axios.post(
                "https://membershipuat.maxisense.io/webapp/memportal/deletecourse",
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Token: token,
                    },
                }
            );

            if (response.data?.code === 1) {
                alert("Lesson deleted successfully!");
                handleRemoveLesson(deleteIndex);
                handleCloseDeleteModal();
            } else {
                alert(`Error: ${response.data?.message_en || "An error occurred."}`);
                console.log(response.data);
            }
        } catch (error) {
            console.error("Error deleting lesson:", error);
            alert("Unable to delete lesson. Please try again later.");
        }
    };



    const handleChangeLesson = (e, index) => {
        const { name, value } = e.target;
        setLessonInputValues(prevValues => {
            const updatedLessons = [...prevValues];
            updatedLessons[index][name] = value;

            // Sync venue selection between Chinese and English
            if (name === "venuezh" || name === "venueeng") {
                const syncedField = name === "venuezh" ? "venueeng" : "venuezh";
                updatedLessons[index][syncedField] = value;
            }

            return updatedLessons;
        });
    };

    // const handleChangeLesson = (e, index) => {
    //     const { name, value } = e.target;
    //     setLessonInputValues((prevLessons) => {
    //         const updatedLessons = [...prevLessons];
    //         updatedLessons[index] = {
    //             ...updatedLessons[index],
    //             [name]: value,

    //         };
    //         return updatedLessons;
    //     });
    // };


    return (
        <Container fluid>
            <Row>
                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Row className="align-items-center mb-3">
                        <Col md={6} className="d-flex align-items-center">
                            <h5 className="basicheading">{lessonDetails()}</h5>
                        </Col>
                        <Col md={6} className="text-end">
                            {/* <Button variant="secondary" className="basiccancelbtnn me-2">{cancel()}</Button> */}
                            <Button variant="primary" className="basicsavebtn me-3" onClick={handleSaveLesson}>{save()}</Button> {/* Add onClick handler */}
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col md={12}>
                            <Card className="basicinfocard">
                                <CardBody className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
                                        <span className="basicstatus">{pushstatus()}: Draft</span>
                                    </div>
                                    <Button className="basicsavebtn " style={{ width: '110px' }} onClick={handleshowBasicModal}>{assignTo()}</Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}

                    <Row className="mt-3">
                        {/* Always render the Add button */}
                        <Col md={1}>
                            <Button
                                style={{
                                    backgroundColor: '#1976D2',
                                    border: 'none',
                                    height: '24px',
                                    width: '24px',
                                    padding: '0'
                                }}
                                onClick={handleAddNewLesson} // Add a new lesson when clicked 

                            >
                                <Add style={{ color: '#fff', fontSize: '16px' }} />
                            </Button>
                        </Col>

                        {/* Render lesson cards if there are any */}
                        {lessoninputValues.map((lesson, index) => (
                            <div key={index}>
                                <Card.Body>
                                    <div
                                        className="p-3 border border-success rounded d-flex justify-content-between align-items-center mb-3 lessoncard"
                                    // Toggle collapse on click
                                    >
                                        <h5 className="mb-0 cardtext"> <IoIosArrowDown style={{ color: 'white' }} onClick={() => handleToggleCollapse(index)} />  {date()}: {formatDatelessonhome(lesson.lessondate)}</h5>




                                        <FaMinus style={{ color: 'white' }} onClick={() => handleShowDeleteModal(index)} />
                                    </div>

                                    <Collapse in={collapsed[index]}>
                                        <div className="p-3">
                                            <Form>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group controlId="introductionChinese" className="floating-label-group">
                                                            <Form.Control
                                                                type="text"
                                                                name="lessonzh"
                                                                className="floating-input"
                                                                value={lesson.lessonzh}
                                                                onChange={(e) => handleChangeLesson(e, index)} // Add onChange handler
                                                                readOnly={!lesson.isEditable}
                                                            />
                                                            <Form.Label className="floating-label">{lessonNameChinese()}</Form.Label>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Form.Group controlId="introductionChinese" className="floating-label-group">
                                                            <Form.Control
                                                                type="text"
                                                                name="lessonen"
                                                                className="floating-input"
                                                                value={lesson.lessonen}
                                                                onChange={(e) => handleChangeLesson(e, index)} // Add onChange handler
                                                                readOnly={!lesson.isEditable}
                                                            />
                                                            <Form.Label className="floating-label">Lesson Name (English)</Form.Label>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Form.Group controlId="campaignNameChinese" className="floating-label-group">
                                                            <Form.Select
                                                                name="venuezh"
                                                                className="floating-input"
                                                                value={lesson.venuezh}
                                                                onChange={(e) => handleChangeLesson(e, index)} // Add onChange handler
                                                                disabled={!lesson.isEditable}
                                                            >
                                                                <option value="">Select Venue (Chinese)</option>
                                                                {venuedata.map((item) => (
                                                                    <option key={item.venue_id} value={item.venue_id}>
                                                                        {item.venue_name.zh}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Label className="floating-label">{venueChinese()}</Form.Label>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Form.Group controlId="campaignNameEnglish" className="floating-label-group">
                                                            <Form.Select
                                                                name="venueeng"
                                                                className="floating-input"
                                                                value={lesson.venueeng}
                                                                onChange={(e) => handleChangeLesson(e, index)} // Add onChange handler
                                                                disabled={!lesson.isEditable}
                                                            >
                                                                <option value="">Select Venue (English)</option>
                                                                {venuedata.map((item) => (
                                                                    <option key={item.venue_id} value={item.venue_id}>
                                                                        {item.venue_name.en}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Label className="floating-label">Venue (English)</Form.Label>
                                                        </Form.Group>
                                                    </Col>





                                                    <Col md={3}>
                                                        <Form.Group controlId="code" className="floating-label-group">
                                                            <Form.Control
                                                                type="date"
                                                                name="lessondate"
                                                                className="floating-input"
                                                                value={lesson.lessondate}
                                                                onChange={(e) => handleChangeLesson(e, index)} // Add onChange handler
                                                                readOnly={!lesson.isEditable}
                                                            />
                                                            <Form.Label className="floating-label">{date()}</Form.Label>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={3}>
                                                        <Form.Group controlId="code" className="floating-label-group">
                                                            <Form.Control
                                                                type="time"
                                                                name="lessonstarttime"
                                                                className="floating-input"
                                                                value={lesson.lessonstarttime}
                                                                onChange={(e) => handleChangeLesson(e, index)} // Add onChange handler
                                                                readOnly={!lesson.isEditable}
                                                            />
                                                            <Form.Label className="floating-label">{startTime()}</Form.Label>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={3}>
                                                        <Form.Group controlId="code" className="floating-label-group">
                                                            <Form.Control
                                                                type="time"
                                                                name="lessonendtime"
                                                                className="floating-input"
                                                                value={lesson.lessonendtime}
                                                                onChange={(e) => handleChangeLesson(e, index)} // Add onChange handler
                                                                readOnly={!lesson.isEditable}
                                                            />
                                                            <Form.Label className="floating-label">{endTime()}</Form.Label>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={2}>
                                                        <Form.Group controlId="status" className="floating-label-group">
                                                            <Form.Select
                                                                name="lessonstatus"
                                                                className="floating-input"
                                                                value={lesson.lessonstatus}
                                                                onChange={(e) => handleChangeLesson(e, index)} // Add onChange handler
                                                                disabled={!lesson.isEditable}
                                                            >


                                                                <option value="1">{normal()}</option>
                                                                <option value="2">{delayed()}</option>
                                                                <option value="3">{cancelled()}</option>
                                                            </Form.Select>
                                                            <Form.Label className="floating-label">{pushstatus()}</Form.Label>
                                                        </Form.Group>
                                                    </Col>


                                                    <Col md={1} className="d-flex justify-content-center align-items-center mt-2">
                                                        <Button
                                                            style={{
                                                                backgroundColor: '#1976D2',
                                                                border: 'none',
                                                                height: '24px',
                                                                width: '24px',
                                                                padding: '0'
                                                            }}
                                                            onClick={() => handleEditLesson(index)}
                                                        >
                                                            <Icon>edit</Icon>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </Collapse>
                                </Card.Body>
                            </div>
                        ))}
                    </Row>
                </Card>
            </Row>
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Body>
                    <p>Do you want to delete this lesson?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="canslebtndialog" onClick={handleCloseDeleteModal}>
                        {cancel()}
                    </Button>
                    <Button className="logoutdialogbtn" onClick={handleDeleteLesson}>
                        {role_delete()}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Lesson;
