

import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Image } from "react-bootstrap";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/Volunteer.css";
import axios from 'axios';
import Avatars from './Avatars'
import useTranslations from "../transalation/TranslationUtils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { initSimpleData, setSimpleData, setSimpleDataToMemberData, updateMember, updateVolumnteer } from "app/redux/slices/rdxMemberSlice";
import { FormControlLabel, Switch } from "@mui/material";
function Volunteer() {
    const { volunteerSetting,
        joinVoluntary,
        willingToJoinVoluntary,
        interestAreaInVoluntary,
        education,
        community,
        environment,
        health,
        animal,
        periodMostLikelyToJoinVoluntaryActivityTimeOfDay,
        morning,
        afternoon,
        evening,
        periodMostLikelyToJoinVoluntaryActivityTimeOfWeek,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        publicHoliday,
        receivePromotion,
        post,
        email,
        mobileApp,
        no, savechaneg, cancel,
        showErrMsg, showSuccessMsg } = useTranslations()

    const dispatch = useDispatch();

    const memberEditData = useSelector((state) => state.rdxMember?.memberEditData || {});
    const editVolumnteer = useSelector((state) => state.rdxMember?.editVolumnteer || {});

    const [isSuccess, setIsSuccess] = useState(false);
    const [resultMsg, setResultMsg] = useState("");

    useEffect(() => {
        let data = {
            data: memberEditData,
            projection: "editVolumnteer",
            type: "obj"
        };
        dispatch(initSimpleData(data));
    }, []);

    const containsKey = (lyr1, lyr2) => {
        return editVolumnteer["volunteer_setting"][lyr1] && editVolumnteer["volunteer_setting"][lyr1][lyr2];
    }

    const handleSwitchChange = (e) => {
        let data = {
            data: !editVolumnteer.is_volunteer,
            projection: "editVolumnteer",
            path: "is_volunteer"
        }
        dispatch(setSimpleData(data));
    }

    const handleChange = (event, lyr1, lyr2) => {
        let origVal = (editVolumnteer["volunteer_setting"][lyr1][lyr2]) ? (true) : (false);
        let data = {
            data: !origVal,
            projection: "editVolumnteer",
            path: "volunteer_setting" + "|" + lyr1 + "|" + lyr2
        }
        dispatch(setSimpleData(data));
    }

    const onUpdateVolumnteerClick = () => {
        dispatch(updateVolumnteer(editVolumnteer, (isSuccess, data) => {
            setIsSuccess(isSuccess);
            if (isSuccess) {
                let rData = {
                    data: editVolumnteer,
                    projection: "self",
                    base: memberEditData
                };
                dispatch(setSimpleDataToMemberData(rData));
                setResultMsg("Volumnteer Setting update successful!");
            } else {
                setResultMsg(showErrMsg(data));
            }
        }));
    }


    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card className="mt-0 border-0" style={{ position: "relative" }}>
                        <Card.Body>
                            <Avatars initAvatar={memberEditData["account_avatar"]} />
                            <br />
                            <Card className="m-3 p-3">
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <h5 className="basicinfo">
                                                {volunteerSetting()}
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={editVolumnteer.is_volunteer}
                                                            onChange={handleSwitchChange}
                                                            color="primary"
                                                            className="ms-3"
                                                        />
                                                    }
                                                    label=""
                                                />
                                            </h5>
                                        </Col>
                                    </Row>
                                    {editVolumnteer.is_volunteer &&
                                        <React.Fragment>
                                            {/* Second Row */}
                                            <Row className="mt-4 mb-4 volunteertext">

                                                <p className="mb-2 volunteertext">{interestAreaInVoluntary()}</p>
                                                <Col xs="auto">
                                                    <Form.Check inline type="checkbox" label={education()}
                                                        checked={containsKey("area", "education")}
                                                        onChange={(event) => handleChange(event, "area", "education")} />
                                                    <Form.Check inline type="checkbox" className="ms-4" label={community()}
                                                        checked={containsKey("area", "community")}
                                                        onChange={(event) => handleChange(event, "area", "community")} />
                                                    <Form.Check inline type="checkbox" className="ms-4" label={environment()}
                                                        checked={containsKey("area", "environmental")}
                                                        onChange={(event) => handleChange(event, "area", "environmental")} />
                                                    <Form.Check inline type="checkbox" className="ms-4" label={health()}
                                                        checked={containsKey("area", "health")}
                                                        onChange={(event) => handleChange(event, "area", "health")} />
                                                    <Form.Check inline type="checkbox" className="ms-4" label={animal()}
                                                        checked={containsKey("area", "Animal")}
                                                        onChange={(event) => handleChange(event, "area", "Animal")} />
                                                </Col>
                                            </Row>

                                            {/* Third Row */}
                                            <Row className="mb-4 volunteertext">
                                                <p className="mb-2">{periodMostLikelyToJoinVoluntaryActivityTimeOfDay()}</p>
                                                <Col xs="auto">
                                                    <Form.Check inline type="checkbox" label={morning()}
                                                        checked={containsKey("time_of_date", "morning")}
                                                        onChange={(event) => handleChange(event, "time_of_date", "morning")} />
                                                    <Form.Check inline type="checkbox" className="ms-4" label={afternoon()}
                                                        checked={containsKey("time_of_date", "afternoon")}
                                                        onChange={(event) => handleChange(event, "time_of_date", "afternoon")} />
                                                    <Form.Check inline type="checkbox" className="ms-4" label={evening()}
                                                        checked={containsKey("time_of_date", "evening")}
                                                        onChange={(event) => handleChange(event, "time_of_date", "evening")} />
                                                </Col>
                                            </Row>

                                            {/* Fourth Row */}
                                            <Row className="mb-4 volunteertext">
                                                <p className="mb-2">{periodMostLikelyToJoinVoluntaryActivityTimeOfWeek
                                                    ()}</p>
                                                <Col xs="auto">
                                                    <Form.Check inline type="checkbox" label={monday()}
                                                        checked={containsKey("days_of_the_week", "monday")}
                                                        onChange={(event) => handleChange(event, "days_of_the_week", "monday")} />
                                                    <Form.Check inline type="checkbox" className="ms-4" label={tuesday()}
                                                        checked={containsKey("days_of_the_week", "tuesday")}
                                                        onChange={(event) => handleChange(event, "days_of_the_week", "tuesday")} />
                                                    <Form.Check inline type="checkbox" className="ms-4" label={wednesday()}
                                                        checked={containsKey("days_of_the_week", "wednesday")}
                                                        onChange={(event) => handleChange(event, "days_of_the_week", "wednesday")} />
                                                    <Form.Check inline type="checkbox" className="ms-4" label={thursday()}
                                                        checked={containsKey("days_of_the_week", "thursday")}
                                                        onChange={(event) => handleChange(event, "days_of_the_week", "thursday")} />
                                                    <Form.Check inline type="checkbox" className="ms-4" label={friday()}
                                                        checked={containsKey("days_of_the_week", "friday")}
                                                        onChange={(event) => handleChange(event, "days_of_the_week", "friday")} />
                                                    <Form.Check inline type="checkbox" className="ms-4" label={saturday()}
                                                        checked={containsKey("days_of_the_week", "saturday")}
                                                        onChange={(event) => handleChange(event, "days_of_the_week", "saturday")} />
                                                    <Form.Check inline type="checkbox" label={sunday()}
                                                        checked={containsKey("days_of_the_week", "sunday")}
                                                        onChange={(event) => handleChange(event, "days_of_the_week", "sunday")} />
                                                </Col>
                                            </Row>

                                            {/* Fifth Row */}
                                            <Row className="mb-4 volunteertext">
                                                <p className="mb-2">{receivePromotion()}</p>
                                                <Col xs="auto">
                                                    <Form.Check inline type="checkbox" label={post()}
                                                        checked={containsKey("promotion", "post")}
                                                        onChange={(event) => handleChange(event, "promotion", "post")} />
                                                    <Form.Check inline type="checkbox" className="ms-4" label={email()}
                                                        checked={containsKey("promotion", "email")}
                                                        onChange={(event) => handleChange(event, "promotion", "email")} />
                                                    <Form.Check inline type="checkbox" className="ms-4" label={mobileApp()}
                                                        checked={containsKey("promotion", "sms")}
                                                        onChange={(event) => handleChange(event, "promotion", "sms")} />

                                                </Col>
                                            </Row>
                                        </React.Fragment>}

                                    {/* Last Row: Buttons */}
                                    <Row className="mt-4">
                                        <Col className="d-flex justify-content-start">
                                            <Button variant="primary" className="me-2 volunteersave" onClick={onUpdateVolumnteerClick}>
                                                {savechaneg()}
                                            </Button>
                                            {/* <Button variant="secondary" className="volunteercansle">{cancel()}</Button> */}
                                        </Col>
                                        <Col sm={12} className="mt-2">
                                            {resultMsg && <p className={isSuccess ? "text-success" : "text-danger"}>{resultMsg}</p>}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Volunteer;
