

import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "./rolemanagemntcss/RoleManagementNew.css";
import { useLanguage } from 'app/contexts/LanguageContext';
import useTranslations from '../transalation/TranslationUtils';
const RoleManagementNew = ({ show, handleClose, modalTitle, roleData, fetchRoles }) => {
  const [roleName, setRoleName] = useState("");
  const [roleNameeng, setRoleNameeng] = useState("");
  const [permissions, setPermissions] = useState({});
  const [availablePermissions, setAvailablePermissions] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Fetch all permissions from API
  const fetchPermissions = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    try {
      const response = await axios.get(
        "https://membershipuat.maxisense.io/webapp/memportal/getallpermission",
        {
          headers: {
            Token: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.code === 1 && Array.isArray(response.data.data)) {
        console.log("API Response:", response.data);

        // Organize permissions into a hierarchy
        const organizedPermissions = {};
        response.data.data.forEach((permission) => {
          const { permission_id, permission_name_en, permission_child_order, permission_global_order } = permission;

          if (permission_child_order === 0) {
            // This is a parent permission (module)
            if (!organizedPermissions[permission_id]) {
              organizedPermissions[permission_id] = {
                name: permission_name_en,
                children: [],
              };
            }
          } else {
            // This is a child permission
            const parentPermission = Object.keys(organizedPermissions).find(
              (key) => key.split("_")[0] === permission_id.split("_")[0]
            );

            if (parentPermission) {
              organizedPermissions[parentPermission].children.push({
                id: permission_id,
                name: permission_name_en,
                order: permission_child_order,
              });
            }
          }
        });

        // Sort child permissions by their `permission_child_order`
        Object.keys(organizedPermissions).forEach((module) => {
          organizedPermissions[module].children.sort((a, b) => a.order - b.order);
        });

        console.log("Organized Permissions:", organizedPermissions);

        setAvailablePermissions(organizedPermissions);
        initializePermissionsState(organizedPermissions);
      } else {
        console.warn("Unexpected API response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };



  // Initialize permissions state
  const initializePermissionsState = (organizedPermissions) => {
    const initialState = {};

    Object.keys(organizedPermissions).forEach((module) => {
      initialState[module] = {};
      organizedPermissions[module].forEach((action) => {
        initialState[module][action.id] = false;
      });
    });

    setPermissions(initialState);
  };


  const convertPermissionsToState = (permissionList) => {
    // Reset permissions state before assigning new values
    const updatedPermissions = {};

    permissionList.forEach((perm) => {
      // Iterate over available permissions and set the permission state to 'true' if it exists in the permission list
      Object.keys(availablePermissions).forEach((module) => {
        if (availablePermissions[module].children) {
          availablePermissions[module].children.forEach((child) => {
            if (child.id === perm) {
              updatedPermissions[module] = {
                ...updatedPermissions[module],
                [child.id]: true,
              };
            }
          });
        }
      });
    });

    setPermissions(updatedPermissions);
  };


  // Fetch permissions on component mount
  useEffect(() => {
    fetchPermissions();
  }, []);



  useEffect(() => {
    if (roleData) {
      // Reset permissions state on role change
      setPermissions({});
      setRoleName(roleData.role_name || "");
      setRoleNameeng(roleData.role_name_en || "");
      convertPermissionsToState(roleData.permission_list || []);
    } else {
      setRoleName("");
      setRoleNameeng("");
      setPermissions({}); // Ensure reset when roleData is null or empty
    }
  }, [roleData, availablePermissions]);





  // Handle checkbox change
  // const handleChange = (module, permissionId, checked) => {
  //   setPermissions((prevPermissions) => {
  //     return {
  //       ...prevPermissions,
  //       [module]: {
  //         ...prevPermissions[module],
  //         [permissionId]: checked,
  //       },
  //     };
  //   });
  // };


  useEffect(() => {
    setPermissions({});
  }, []);


  const handleChange = (moduleId, permissionId, checked) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions };

      // Ensure the module exists in the state
      if (!updatedPermissions[moduleId]) {
        updatedPermissions[moduleId] = {};
      }

      // Update the clicked permission
      updatedPermissions[moduleId][permissionId] = checked;

      const isEdit = permissionId.endsWith("_u");
      const isDelete = permissionId.endsWith("_d");

      if (isDelete && checked) {
        // When "Delete" is clicked and checked, select all permissions in the module
        Object.keys(updatedPermissions[moduleId]).forEach((perm) => {
          updatedPermissions[moduleId][perm] = true; // Select all permissions
        });

        // Explicitly ensure that common permissions like "Read", "Edit", and "Review" are selected
        updatedPermissions[moduleId][permissionId.replace("_d", "_r")] = true; // Read
        updatedPermissions[moduleId][permissionId.replace("_d", "_u")] = true; // Edit
        updatedPermissions[moduleId][permissionId.replace("_d", "_review")] = true; // Review
      } else if (isEdit && checked) {
        // When "Edit" is clicked, only ensure "Read" is selected
        const readPermissionId = permissionId.replace("_u", "_r");
        updatedPermissions[moduleId][readPermissionId] = true; // Select "Read"
      }

      return updatedPermissions;
    });
  };






  // Build permission list for saving
  const buildPermissionList = () => {
    const permissionList = [];

    Object.entries(permissions).forEach(([moduleId, actions]) => {
      // Add the module's permission_id (parent permission)
      permissionList.push(moduleId);

      // Add child permissions that are enabled
      Object.entries(actions).forEach(([action, isEnabled]) => {
        if (isEnabled) {
          permissionList.push(action);
        }
      });
    });

    return permissionList;
  };


  // Save role
  const handleSave = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    const permissionList = buildPermissionList();

    const payload = {
      role_name: roleName,
      role_name_en: roleNameeng,
      available: true,
      permission_list: permissionList,
    };

    console.log("Payload being sent to API:", payload);

    try {
      if (roleData) {
        const updatePayload = { ...payload, role_id: roleData.role_id };

        await axios.post(
          "https://membershipuat.maxisense.io/webapp/memportal/updaterole",
          updatePayload,
          {
            headers: {
              Token: localStorage.getItem("authToken"),
              "Content-Type": "application/json",
            },
          }
        );
        setSuccessMessage("Role updated successfully!");
      } else {
        await axios.post(
          "https://membershipuat.maxisense.io/webapp/memportal/addrole",
          payload,
          {
            headers: {
              Token: localStorage.getItem("authToken"),
              "Content-Type": "application/json",
            },
          }
        );
        setSuccessMessage("Role created successfully!");
      }

      setRoleName("");          // Reset the Role Name (Chinese)
      setRoleNameeng("");       // Reset the Role Name (English)
      setPermissions({});      // Reset the permissions state

      fetchRoles && fetchRoles();
      setTimeout(() => setSuccessMessage(""), 2000);
      setTimeout(() => handleClose(), 2000);
    } catch (error) {
      console.error("Error saving role:", error);
      setErrorMessage("There was an error saving the role.");
    }
  };


  const { selectedLanguage } = useLanguage();


  const getRoleNamepermission = (item) => {
    return selectedLanguage === 'zh'
      ? item.permission_name
      : item.permission_name_en;
  };


  const { cancel, save } = useTranslations();


  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="maintitle">{modalTitle}</Modal.Title>
      </Modal.Header>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      <Modal.Body>
        <Form>
          <Form.Group controlId="formRoleName" className="floating-label-group">
            <Form.Control
              type="text"
              value={roleNameeng}
              onChange={(e) => setRoleNameeng(e.target.value)}
              className="floating-input"
              required
            />
            <Form.Label className="floating-label">Role Name (English)</Form.Label>
          </Form.Group>

          <Form.Group controlId="formRoleNameChinese" className="floating-label-group">
            <Form.Control
              type="text"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              className="floating-input"
              required
            />
            <Form.Label className="floating-label">Role Name (Chinese)</Form.Label>
          </Form.Group>

          {Object.entries(availablePermissions).map(([moduleId, moduleData]) => (
            <div key={moduleId} className="mt-4">
              <Form.Label className="lablecheck">{moduleData.name}</Form.Label>
              {/* <Form.Label className="lablecheck">{getRoleNamepermission(moduleData)}</Form.Label> */}
              <div className="checkbox-group">
                {moduleData.children.map((action) => (
                  <Form.Check
                    key={action.id}
                    type="checkbox"
                    label={action.name}
                    // label={getRoleNamepermission(action)}
                    checked={permissions[moduleId]?.[action.id] || false}
                    onChange={(e) =>
                      handleChange(moduleId, action.id, e.target.checked)
                    }
                  />
                ))}
              </div>
            </div>
          ))}


          <div className="modal-footer">
            <Button variant="secondary" onClick={handleClose} className="rolecancelbtn">
              {cancel()}
            </Button>
            <Button variant="primary" onClick={handleSave} className="rolesavebtn">
              {save()}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RoleManagementNew;








