import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Table, Modal } from 'react-bootstrap';
import './campaigncss/Attendance.css';
import useTranslations from '../transalation/TranslationUtils';
function Attendencedetail({ campaignDetails }) {
    // State for modal visibility and edit mode
    const [showMemberModal, setShowMemberModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [newMember, setNewMember] = useState({
        memberId: '',
        memberName: '',
        mobile: '',
        status: '',
        leaveReason: ''
    });

    const { cancel, save, pushstatus, assignTo, func_t, memberid, membername, mobile, approver, role_edit,
        attendanceDetails,
        title,
        dateStart,
        noOfAttendance,
        noOfAbsentee,
        absenceReason,
        close,
        details, attendance
    } = useTranslations();


    // State to track visibility of the second table modal
    const [showSecondTableModal, setShowSecondTableModal] = useState(false);
    const [detailedAttendanceData, setDetailedAttendanceData] = useState([]);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [selectedAccountId, setSelectedAccountId] = useState(null);

    // Toggle modal visibility
    const handleShowMemberModal = (edit = false, member = {}) => {
        setIsEditing(edit);
        setNewMember({
            memberId: member.member_id || '',
            memberName: member.account_name || '',
            mobile: member.account_phone || '',
            status: member.attendance_status ? 'approve' : 'reject',
            leaveReason: member.leave_reason || ''
        });
        setSelectedAccountId(member.account_id || '');
        setShowMemberModal(true);
    };
    const handleCloseMemberModal = () => setShowMemberModal(false);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewMember(prevState => ({ ...prevState, [name]: value }));
    };

    // Save or update member function
    const handleSaveMember = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch('https://membershipuat.maxisense.io/webapp/memportal/updatecourseattendstatus', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Token: token,
                },
                body: JSON.stringify({
                    campaign_id: campaignDetails.campaign_id,
                    course_id: selectedCourseId,
                    account_id: selectedAccountId,
                    attendance_status: newMember.status === 'approve',
                    leave_reason: newMember.leaveReason
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update attendance status');
            }

            const result = await response.json();
            if (result.code === 1) {
                alert('Attendance status updated successfully!');
                setShowMemberModal(false);
                // Refresh the detailed attendance data
                handleShowSecondTableModal(selectedCourseId);
            } else {
                throw new Error('Invalid response code');
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    // Function to handle Detail button click
    const handleShowSecondTableModal = async (courseId) => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch('https://membershipuat.maxisense.io/webapp/memportal/getcourseattendeelist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Token: token,
                },
                body: JSON.stringify({ campaign_id: campaignDetails.campaign_id, course_id: courseId }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch detailed attendance data');
            }

            const result = await response.json();
            if (result.code === 1) {
                setDetailedAttendanceData(result.data);
                setSelectedCourseId(courseId);
                setShowSecondTableModal(true); // Show the second table modal
            } else {
                throw new Error('Invalid response code');
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const handleCloseSecondTableModal = () => setShowSecondTableModal(false);

    const [attendanceData, setAttendanceData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const campaignId = campaignDetails?.campaign_id;

    useEffect(() => {
        const fetchAttendanceData = async () => {
            try {
                const token = localStorage.getItem("authToken");
                setLoading(true);
                const response = await fetch('https://membershipuat.maxisense.io/webapp/memportal/getcampapignattendancelist', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Token: token,
                    },
                    body: JSON.stringify({ campaign_id: campaignId }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch attendance data');
                }

                const result = await response.json();
                if (result.code === 1) {
                    setAttendanceData(result.data);
                    console.log(result.data);
                } else {
                    throw new Error('Invalid response code');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (campaignId) {
            fetchAttendanceData();
        }
    }, [campaignId]);
    return (
        <Container fluid>
            <Row>
                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <h5 className='lessonheading p-2'>{attendanceDetails()}</h5>
                    <Card.Body>
                        <Table striped hover responsive className='mt-3'>
                            <thead className="text-center">
                                <tr>
                                    <th className='tableheadgrp'>Lesson Name</th>
                                    <th className='tableheadgrp'>{dateStart()}</th>
                                    <th className='tableheadgrp'>{pushstatus()}</th>
                                    <th className='tableheadgrp'>{noOfAttendance()}</th>
                                    <th className='tableheadgrp'>{noOfAbsentee()}</th>
                                    <th className='tableheadgrp'>{func_t()}</th>
                                </tr>
                            </thead>
                            <tbody className="tableheadgrp ">
                                {attendanceData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.course_name?.en || 'N/A'}</td>
                                        <td>
                                            {new Date(item.course_start_time).toLocaleString()}
                                        </td>
                                        <td>{item.course_status === '1' ? 'Normal' : 'Pending'}</td>
                                        <td><div className='d-flex justify-content-center align-items-center'>{item.no_attendee}</div></td>
                                        <td><div className='d-flex justify-content-center align-items-center'>{item.no_absentee}</div></td>
                                        <td>
                                            <Button
                                                variant=""
                                                size="sm"
                                                className="attendancebtn"
                                                onClick={() => handleShowSecondTableModal(item.course_id)}
                                            >
                                                {details()}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Row>

            {/* Modal for the second table */}
            <Modal show={showSecondTableModal} onHide={handleCloseSecondTableModal} centered className="custom-modal-attendance" size="lg">
                <Modal.Body>
                    <Row>
                        <Col md={12} className="d-flex justify-content-between align-items-center mt-5">
                            <div className="d-flex">
                                <p className="grouplabel me-3 mb-0">{noOfAttendance()}: {detailedAttendanceData.filter(item => item.attendance_status).length}</p>
                                <p className="grouplabel mb-0 ms-5">{noOfAbsentee()}: {detailedAttendanceData.filter(item => !item.attendance_status).length}</p>
                            </div>
                        </Col>
                    </Row>

                    <Table striped hover responsive className='mt-3'>
                        <thead className="text-center">
                            <tr>
                                <th className='tableheadgrp'>
                                    <div className="button-group d-flex ">
                                        {memberid()}
                                    </div>
                                </th>
                                <th className='tableheadgrp'>
                                    <div className="button-group d-flex "> {membername()}</div>
                                </th>
                                <th className='tableheadgrp'>
                                    <div className="button-group d-flex ">{mobile()}</div>
                                </th>
                                <th className='tableheadgrp'>
                                    <div className="button-group d-flex justify-content-center align-items-center">{attendance()}</div>
                                </th>
                                <th className='tableheadgrp'>
                                    <div className="button-group d-flex justify-content-center align-items-center">{absenceReason()}</div>
                                </th>
                                <th className='tableheadgrp'>
                                    <div className="button-group d-flex justify-content-center align-items-center">{approver()}</div>
                                </th>
                                <th className='tableheadgrp'>
                                    <div className="button-group d-flex justify-content-center align-items-center">
                                        {func_t()}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='tableheadgrp text-center'>
                            {detailedAttendanceData.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="button-group d-flex ">{item.member_id}</div>
                                    </td>
                                    <td>
                                        <div className="button-group d-flex "> {item.account_name}</div>
                                    </td>
                                    <td>
                                        <div className="button-group d-flex "> {item.account_phone}</div>
                                    </td>
                                    <td >
                                        <div className="button-group d-flex justify-content-center align-items-center">{item.attendance_status ? 'Y' : 'N'}</div>
                                    </td>
                                    <td >
                                        <div className="button-group d-flex justify-content-center align-items-center"> {item.leave_reason}</div>
                                    </td>
                                    <td>
                                        <div className="button-group d-flex justify-content-center align-items-center">{item.approver}</div>
                                    </td>
                                    <td>
                                        <div className="button-group d-flex justify-content-center align-items-center">
                                            <Button variant="" size="sm" onClick={() => handleShowMemberModal(true, item)} className='attendancebtn'>{role_edit()}</Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="" onClick={handleCloseSecondTableModal} className="me-5 attendancebtn">
                        {close()}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for Add/Edit Member */}
            <Modal show={showMemberModal} onHide={handleCloseMemberModal} centered className="custom-modal-width">
                <Modal.Body>
                    <Form>
                        <h4 className='memberheads'>{isEditing ? 'Attendance Details - Edit' : 'Attendance Details - Save'}</h4>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="memberId">
                                    <Form.Label className='grouplabel'>Member ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="memberId"
                                        className="floating-input memberslabels"
                                        value={newMember.memberId}
                                        onChange={handleInputChange}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={9}>
                                <Form.Group controlId="memberName">
                                    <Form.Label className='grouplabel'>Member Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="memberName"
                                        className="floating-input memberslabels"
                                        value={newMember.memberName}
                                        onChange={handleInputChange}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="mobile">
                                    <Form.Label className='grouplabel'>Mobile</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="mobile"
                                        className="floating-input memberslabels"
                                        value={newMember.mobile}
                                        onChange={handleInputChange}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group controlId="formStatus" className="mt-3 mb-3">
                            <Form.Label className='grouplabel'>Attendance</Form.Label>
                            <div className="d-flex">
                                <Form.Check
                                    type="radio"
                                    label="Yes"
                                    name="status"
                                    value="approve"
                                    checked={newMember.status === 'approve'}
                                    onChange={handleInputChange}
                                    className='grouplabel me-3' // Adds margin to the right
                                />
                                <Form.Check
                                    type="radio"
                                    label="No"
                                    name="status"
                                    value="reject"
                                    checked={newMember.status === 'reject'}
                                    onChange={handleInputChange}
                                    className='grouplabel'
                                />
                            </div>
                        </Form.Group>

                        <Row className="mb-3">
                            <Col md={7}>
                                <Form.Group controlId="leaveReason">
                                    <Form.Label className='grouplabel'>Absent Reason</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="leaveReason"
                                        className="floating-input memberslabels"
                                        value={newMember.leaveReason}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="" onClick={handleCloseMemberModal} className="me-2 groupclear">
                        Cancel
                    </Button>
                    <Button variant="" onClick={handleSaveMember} className='groupsearch'>
                        {isEditing ? 'Update' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Attendencedetail;

