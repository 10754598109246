import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, Form, Table, InputGroup, Card } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import './campaigncss/Enrollement.css'
import useTranslations from '../transalation/TranslationUtils';
function Enrollment({ campaignDetails }) {
    const [showMemberModal, setShowMemberModal] = useState(false);
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [members, setMembers] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState(null);


    const { cancel, save, pushstatus, assignTo, cancelled, mobile, func_t, quota, memberid, membername, role_edit, usersearch, enrolmentStatus, campaignname,
        noOfApplications,
        quotaUsage,
        approver,
        lastUpdate,
        addMember,
        addGroup,
        preApprove,
        approve,
        waitlist,
        withdraw, } = useTranslations();



    // Initial member state
    // const [newMember, setNewMember] = useState({
    //     memberId: '',
    //     memberName: '',
    //     mobile: '',
    //     status: 'approve',
    //     lastUpdated: ''
    // });

    // Show modals for adding or editing
    const handleShowMemberModal = () => setShowMemberModal(true);
    const handleCloseMemberModal = () => {
        setShowMemberModal(false);
        setIsEditing(false);
        setNewMember({
            memberId: '',
            memberName: '',
            mobile: '',
            status: 'approve',
            lastUpdated: ''
        });
    };

    const handleShowGroupModal = () => setShowGroupModal(true);
    const handleCloseGroupModal = () => setShowGroupModal(false);




    const handleEditMember = (index) => {
        const member = enrollmentList[index];
        setNewMember({
            account_name: member.account_name,
            account_phone: member.account_phone,
            status: getStatusDescription(member.enroll_status).toLowerCase(),
        });
        setMemberId(member.member_id);
        setAccountId(member.account_id); // Set account_id
        setIsEditing(true);
        setEditIndex(index);
        handleShowMemberModal();
    };

    const handleUpdateMember = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch(`https://membershipuat.maxisense.io/webapp/memportal/updateenrollmentstatus`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
                body: JSON.stringify({
                    campaign_id: campaignDetails.campaign_id,
                    account_id: accountId, // Use account_id
                    enroll_status: newMember.status === "approve" ? 1 :
                        newMember.status === "pre-approve" ? 2 :
                            newMember.status === "waitlisted" ? 3 :
                                newMember.status === "Withdrawn" ? 4 :
                                    newMember.status === "cancelled" ? 5 : null,
                }),
            });

            const data = await response.json();
            if (data.code === 1) {
                alert("Member updated successfully!");
                handleCloseMemberModal();
                fetchEnrollmentList(); // Refresh the list
            } else {
                alert(data.message_en || "Failed to update member.");
            }
        } catch (error) {
            console.error("Error updating member:", error);
            alert("System problem");
        }
    };

    const [enrollmentList, setEnrollmentList] = useState([]);

    // Fetch enrollment data
    const fetchEnrollmentList = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch(`https://membershipuat.maxisense.io/webapp/memportal/getcampaignenrollmentlist`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
                body: JSON.stringify({
                    campaign_id: campaignDetails.campaign_id, // Pass the campaign ID dynamically
                }),
            });
            const data = await response.json();
            if (data.code === 1) {
                setEnrollmentList(data.data.enroll_list || []);

            } else {
                console.error("Failed to fetch enrollment list:", data.message_en);
            }
        } catch (error) {
            console.error("Error fetching enrollment list:", error);
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        if (campaignDetails?.campaign_id) {
            fetchEnrollmentList();
        }
    },[])

    const getStatusDescription = (status) => {
        switch (status) {
            case 1:
                return "approve";
            case 2:
                return "pre-approve";
            case 3:
                return "waitlisted";
            case 4:
                return "Withdrawn";
            case 5:
                return "cancelled";
            default:
                return "Unknown";
        }
    };


    const [memberId, setMemberId] = useState("");
    const [newMember, setNewMember] = useState({
        account_name: "",
        account_phone: "",
        status: "",
    });

    const [accountId, setAccountId] = useState(""); // Add state for account_id

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "account_phone" && !/^\d{0,8}$/.test(value)) return; // Allow only up to 8 digits
        setNewMember((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleMemberIdChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,8}$/.test(value)) { // Allow only up to 8 digits
            setMemberId(value);
        }
    };


    const handleSearchMember = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch(`https://membershipuat.maxisense.io/webapp/memportal/getmemberdetail`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Token: token, // Replace with actual token
                },
                body: JSON.stringify({
                    campaign_id: campaignDetails.campaign_id,
                    member_id: memberId,
                }),
            });

            const data = await response.json();
            if (data.code === 1) {
                const { account_name, account_phone, account_id } = data.data;
                // Set all the necessary values in the state
                setNewMember({ account_name, account_phone, status: "", account_id });
            } else {
                alert(data.message_en || "Failed to fetch member details.");
            }
        } catch (error) {
            console.error("Error fetching member details:", error);
        }
    };



    const handleSaveMember = async () => {
        try {
            const token = localStorage.getItem("authToken");

            // Ensure account_id is available
            if (!newMember.account_id) {
                alert("Account ID is missing. Please search for the member first.");
                return;
            }

            const response = await fetch(`https://membershipuat.maxisense.io/webapp/memportal/addmembertocampaign`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Token: token, // Replace with actual token
                },
                body: JSON.stringify({
                    campaign_id: campaignDetails.campaign_id,
                    account_id: newMember.account_id, // Use the account_id from state
                    account_name: newMember.account_name,
                    account_phone: newMember.account_phone,
                    enroll_status: newMember.status === "pre-approve" ? 2 :
                        newMember.status === "approve" ? 1 :
                            newMember.status === "waitlisted" ? 3 :
                                newMember.status === "Withdrawn" ? 4 :
                                    newMember.status === "cancelled" ? 5 : null,
                }),
            });

            const data = await response.json();
            console.log(data); // Log the response data to see what's returned
            if (data.code === 1) {
                alert("Member saved successfully!");
                handleCloseMemberModal();
            } else {
                alert(data.message_en || "Failed to save member.");
            }
        } catch (error) {
            console.error("Error saving member:", error);
        }
    };





    return (
        <Container>
            <Row>
                <Card className="mt-0 border-0" style={{ position: 'relative' }}>
                    <Card.Body style={{ padding: 0, paddingTop: '16px' }}>
                        <div className="d-flex justify-content-between align-items-center my-class">
                            <h5 className='enrollementhead'>Enrollment Status</h5>
                            <div className="d-flex me-">
                                <Button
                                    variant="success"
                                    className="custom-success-btn me-2"
                                    style={{ width: '160px' }}
                                    onClick={handleShowMemberModal}
                                >
                                    {addMember()}
                                </Button>
                                <Button
                                    variant="success"
                                    className="custom-success-btn ms-auto"
                                    style={{ width: '160px' }}
                                    onClick={handleShowGroupModal}
                                >
                                    {addGroup()}
                                </Button>
                            </div>
                        </div>


                        <Row>
                            <Col md={6} className="d-flex justify-content-between mt-5 ms-2">
                                <p className='grouplabel'>{quota()}: 10</p>
                                <p className='grouplabel'>{noOfApplications()}: 8</p>
                                <p className='grouplabel'>{quotaUsage()}: 80%</p>
                            </Col>
                        </Row>




                        <Table striped hover responsive>
                            <thead className="text-center">
                                <tr>
                                    <th className="tableheadgrp">
                                        <div className="button-group d-flex ">
                                            {memberid()}
                                        </div>
                                    </th>
                                    <th className="tableheadgrp">
                                        <div className="button-group d-flex ">
                                            {membername()}
                                        </div></th>
                                    <th className="tableheadgrp">   <div className="button-group d-flex ">
                                        {mobile()}
                                    </div></th>
                                    <th className="tableheadgrp">   <div className="button-group d-flex ">
                                        {approver()}
                                    </div></th>
                                    <th className="tableheadgrp">   <div className="button-group d-flex ">
                                        {pushstatus()}
                                    </div></th>
                                    <th className="tableheadgrp">   <div className="button-group d-flex justify-content-start align-items-start">
                                        {lastUpdate()}
                                    </div></th>
                                    <th className="tableheadgrp">   <div className="button-group d-flex justify-content-end align-items-end">
                                        {func_t()}
                                    </div></th>
                                </tr>
                            </thead>
                            <tbody className="tableheadgrp text-start">
                                {enrollmentList.length > 0 ? (
                                    enrollmentList.map((enroll, index) => (
                                        <tr key={index}>
                                            <td>{enroll.member_id}</td>
                                            <td>{enroll.account_name}</td>
                                            <td>{enroll.account_phone}</td>
                                            <td>{enroll.approver_name}</td>
                                            <td>{getStatusDescription(enroll.enroll_status)}</td>
                                            <td>
                                                {new Date(enroll.update_date).toLocaleDateString()}{" "}
                                                {new Date(enroll.update_date).toLocaleTimeString()}
                                            </td>
                                            <td>
                                                <div className="button-group d-flex justify-content-end align-items-end">
                                                    <Button variant="primary" className="useraddbtn" onClick={() => handleEditMember(index)}>
                                                        {role_edit()}
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            No enrollments found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                    </Card.Body>
                </Card>
            </Row>


            <Modal show={showMemberModal} onHide={handleCloseMemberModal} centered className="custom-modal-width">
                <Modal.Body>
                    <Form>
                        <h4 className="memberheads ms-2">{isEditing ? "Edit Member" : addMember()}</h4>

                        {/* Member ID */}
                        <Row className="mb-3 ms-2">
                            <Col md={8}>
                                <Form.Group controlId="memberId">
                                    <Form.Label className="grouplabel">{memberid()}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={memberId}
                                        onChange={handleMemberIdChange}
                                        className="floating-input memberslabels"
                                        disabled={isEditing}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} className="d-flex align-items-end justify-content-end">
                                <Button variant="primary" onClick={handleSearchMember} className="useraddbtn">
                                    {usersearch()}
                                </Button>
                            </Col>
                        </Row>

                        {/* Member Name */}
                        <Row className="mb-3 ms-2">
                            <Col md={8}>
                                <Form.Group controlId="accountName">
                                    <Form.Label className="grouplabel">{membername()}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={newMember.account_name}
                                        name="account_name"
                                        onChange={handleInputChange}
                                        className="floating-input memberslabels"
                                        disabled={isEditing}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* Mobile */}
                        <Row className="mb-3 ms-2">
                            <Col md={8}>
                                <Form.Group controlId="accountPhone">
                                    <Form.Label className="grouplabel">{mobile()}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={newMember.account_phone}
                                        name="account_phone"
                                        onChange={handleInputChange}
                                        className="floating-input memberslabels"
                                        disabled={isEditing}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* Status */}
                        <Form.Group controlId="formStatus" className="mt-3 ms-3">
                            <Form.Label className="grouplabel">{pushstatus()}</Form.Label>
                            <div className="d-flex flex-wrap">
                                {[preApprove(), approver(), waitlist(), withdraw(), cancelled()].map((status, idx) => (
                                    <Form.Check
                                        key={idx}
                                        type="radio"
                                        label={status.charAt(0).toUpperCase() + status.slice(1)}
                                        name="status"
                                        value={status}
                                        checked={newMember.status === status}
                                        onChange={handleInputChange}
                                        className="grouplabel me-3"
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>


                    <Col md={4} className="d-flex align-items-end justify-content-end">
                        <Button variant="" onClick={handleCloseMemberModal} className=" me-2 canslebtndialog">
                            {cancel()}
                        </Button>
                        <Button variant="" onClick={isEditing ? handleUpdateMember : handleSaveMember} className="logoutdialogbtn">
                            {save()}
                        </Button>
                    </Col>
                </Modal.Footer>
            </Modal>



            <Modal
                show={showGroupModal}
                onHide={handleCloseGroupModal}
                centered
                className="custom-modal-group"
            >
                <Modal.Body>
                    <h4 className='groupheading ps-3'>Add a Group</h4>

                    {/* Campaign Name Section */}

                    {/* Filter Section */}
                    <Card className="p-3 mb-3 border-0">
                        <Row className="mb-3">
                            <Col md={8}> {/* Set width to 8 columns */}
                                <Form.Group controlId="campaignName">
                                    <Form.Label className='grouplabel'>{campaignname()}</Form.Label>
                                    <Form.Select className='grouplabel'>
                                        <option className='grouplabel'>Cantonese Opera Class (Beginner)</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col md={4} className="d-flex align-items-center">
                                <Form.Group controlId="filter" className="w-100">
                                    <Form.Label className='grouplabel'>Filter</Form.Label>
                                    <Form.Select className='grouplabel'>
                                        <option className='grouplabel'>None</option>
                                        {Array.from({ length: 21 }, (_, i) => (
                                            <option key={i} value={i * 5}>{i * 5}%</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <span className="ms-2 mt-4 align-self-center grouplabel">above</span>
                            </Col>
                        </Row>

                        <Row className="justify-content-end">
                            <Col md="auto">
                                <Button variant="" className="me-2 groupclear">Clear</Button>
                                <Button variant="" className='groupsearch'>{usersearch()}</Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} className="d-flex justify-content-between mt-5">
                                <p className='grouplabel'>{quota()}: 10</p>
                                <p className='grouplabel'>{noOfApplications()}: 8</p>
                                <p className='grouplabel'>{quotaUsage()}: 80%</p>
                            </Col>
                        </Row>
                    </Card>

                    {/* Table */}
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th className='tableheadgrp'>{memberid()}</th>
                                <th className='tableheadgrp'>{membername()}</th>
                                <th className='tableheadgrp'>{mobile()}</th>
                                <th className='tableheadgrp'>Class 1</th>
                                <th className='tableheadgrp'>Class 2</th>
                                <th className='tableheadgrp'>Class 3</th>
                                <th className='tableheadgrp'>Cla</th>
                                <th className='tableheadgrp'>Reason of Absence</th>
                                <th className='tableheadgrp'>Overall Attendance</th>
                                <th className='tableheadgrp'>{pushstatus()}</th>
                                <th className='tableheadgrp'>{role_edit()}</th>
                            </tr>
                        </thead>
                        <tbody className='tableheadgrp'>
                            <tr>
                                <td>Q000006</td>
                                <td>Lily Kong</td>
                                <td>98370232</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td>Out Of Town</td>
                                <td>100%</td>
                                <td>Approved</td>
                                <td>
                                    <Button variant="primary" size="sm">✎</Button>
                                </td>
                            </tr>
                            <tr>
                                <td>Q000006</td>
                                <td>Lily Kong</td>
                                <td>98370232</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td>Sick</td>
                                <td>100%</td>
                                <td>Approved</td>
                                <td>
                                    <Button variant="primary" size="sm">✎</Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="" onClick={handleCloseGroupModal} className='groupclear'>
                        {cancel()}
                    </Button>
                    <Button variant="" className='groupsearch'>Import</Button>
                </Modal.Footer>
            </Modal>



        </Container>
    );
}

export default Enrollment;
