import React from 'react'
import Breadcrumb from "app/components/Breadcrumb";
import  useTranslationshome  from './Hometransalte'
import { Form, Button, Table, Container, Row, Col, Card } from "react-bootstrap";
const CompleteCampaign = () => {

    const {  listOfCompletedCampaigns,
        campaignName,
        enrolmentMethod,
        totalEnrolledMembers,
        waitlisted,
        totalClasses,
        totalClassAttendance,
        totalClassAbsence,
        totalRating,quota,
        rating,} = useTranslationshome();
    
    return (
        <div>
            <Row className="ps-4 pt-4 align-items-center">
                <Col>
                    <Breadcrumb routeSegments={[{ name: listOfCompletedCampaigns() }]} />
                </Col>
             
            </Row>
            <table className="table table-striped mt-4">
                <thead>
                    <tr>
                        <th className="custom-th">{campaignName()}</th>
                        <th className="custom-th">{quota()}</th>
                        <th className="custom-th">{enrolmentMethod()}</th>
                        <th className="custom-th">{totalEnrolledMembers()}</th>
                        <th className="custom-th">{waitlisted()}</th>
                        <th className="custom-th">{totalClasses()}</th>
                        <th className="custom-th">{totalClassAttendance()}</th>
                        <th className="custom-th">{totalClassAbsence()}</th>
                        <th className="custom-th">{totalRating()}</th>
                        <th className="custom-th">{rating()}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="custom-td">
                        <td>Contonese Opera Class </td>
                        <td>12</td>
                        <td>Lottery Draw</td>
                        <td>12</td>
                        <td>18</td>
                        <td>4</td>
                      
                        <td>11</td>
                        <td>1</td>
                        <td>17</td>
                        <td>⭐⭐⭐⭐</td>
                    </tr>
                    <tr className="custom-td">
                        <td>Dancing </td>
                        <td>23</td>
                        <td>First Come First Serve</td>
                        <td>30</td>
                        <td>0</td>
                        <td>4</td>
                        
                        <td>90</td>
                        <td>0</td>
                        <td>10</td>
                        <td>⭐⭐⭐⭐⭐</td>
                    </tr>
                </tbody>
            </table></div>
    )
}

export default CompleteCampaign