


import React, { useState, useEffect } from "react";
import { Button, Table, Alert, Spinner } from "react-bootstrap";
import axios from "axios";
import UserManagementNew from "./UserManagementNew";
import "./usercss/UserManagement.css";
import { Puff } from 'react-loading-icons'
import useTranslations from '../transalation/TranslationUtils';
const UserManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [permissions, setPermissions] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  const [loading, setLoading] = useState(true); // Track overall loading state
  const [permissionsLoaded, setPermissionsLoaded] = useState(false); // Track permissions loading
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { func_t, role_edit, role_Add, role_delete, role_next, role_previous, change_pass, user_manage, username } = useTranslations();

  useEffect(() => {
    const fetchPermissions = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("No token found in localStorage");
        return;
      }

      try {
        const response = await axios.get(
          "https://membershipuat.maxisense.io/webapp/memportal/getadmininfo",
          {
            headers: {
              Token: token,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.code === 1) {
          const permissionsList = response.data.data.permission_list || [];
          setPermissions({
            add: permissionsList.includes("admin"),
            edit: permissionsList.includes("admin_u"),
            delete: permissionsList.includes("admin_d"),
          });
        }
        setPermissionsLoaded(true); // Mark permissions as loaded
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, []);

  const fetchUsers = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("No token found in localStorage");
      setLoading(false); // End loading if no token
      return;
    }

    try {
      const response = await axios.get(
        "https://membershipuat.maxisense.io/webapp/memportal/getadminlist",
        {
          headers: {
            Token: token,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("user", response.data)
      if (response.data.code === 1 && Array.isArray(response.data.data)) {
        setUsers(response.data.data);
      } else {
        console.warn("Unexpected API response format:", response.data);
        setUsers([]);
      }
    } catch (error) {
      console.error("Error fetching admin list:", error);
      setUsers([]);
    }
    setLoading(false); // Mark data fetching as done
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAddUser = () => {
    setSelectedUser(null);
    setShowModal(true);
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleDeleteUser = async (user) => {
    if (
      window.confirm(
        `Are you sure you want to delete User ${user.admin_name || "Unnamed Admin"}?`
      )
    ) {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("No token found in localStorage");
        return;
      }
      try {
        const response = await axios.post(
          "https://membershipuat.maxisense.io/webapp/memportal/deleteadmin",
          {
            admin_id: user.admin_id,
          },
          {
            headers: {
              Token: token,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.code === 1) {
          setSuccessMessage(
            `User ${user.admin_name || "Unnamed Admin"} deleted successfully!`
          );
          fetchUsers();
          setTimeout(() => setSuccessMessage(""), 2000);
        } else if (user.admin_name === "Root Admin") {
          alert(`Root Account cannot be deleted`);
        } else {
          console.error("Failed to delete admin:", response.data.message_en);
          alert(`An account currently logged in cannot be deleted`);
        }
      } catch (error) {
        console.error("Error deleting admin:", error);
        alert("An error occurred while deleting the admin.");
      }
    }
  };




  const handleCloseModal = () => setShowModal(false);

  const totalPages = Math.ceil(users.length / rowsPerPage);
  const paginatedUsers = users.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid pt-5 ps-3 pe-3">

      <div className="row mb-3">
        <div className="col-10 d-flex align-items-center">
          <h4 className="firstname">{user_manage()}</h4>
        </div>
        <div className="col-2 d-flex justify-content-end">
          {permissions.add && (
            <Button
              className="custom-success-btn me-3"
              variant="success"
              onClick={handleAddUser}
            >
              {role_Add()}
            </Button>
          )}
        </div>
      </div>

      {successMessage && (
        <Alert
          variant="success"
          onClose={() => setSuccessMessage("")}
          dismissible
        >
          {successMessage}
        </Alert>
      )}

      {loading || !permissionsLoaded ? (
        <div className="text-center mt-4">

          <p> <Puff stroke="#98ff98" /> Loading Users...</p>
        </div>
      ) : (
        <>
          <div className="table-responsive">
            <Table className="role-management-table">
              <thead>
                <tr className="tabaleh">
                  <th style={{ width: "78%" }} className="tabaleh">
                    {username()}
                  </th>
                  <th style={{ width: "22.5%" }} className="tabaleh">
                    <div className=" d-flex justify-content-center align-items-center">
                      {func_t()}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedUsers.length > 0 ? (
                  paginatedUsers.map((user, index) => (
                    <tr key={user.admin_id || index}>
                      <td className="role align-middle">{user.admin_name}</td>
                      <td>
                        <div className="button-group d-flex justify-content-end align-items-end">
                          {permissions.edit && (
                            <Button
                              className="custom-success-btn me-2"
                              onClick={() => handleEditUser(user)}
                            >
                              {role_edit()}
                            </Button>
                          )}
                          <Button className="custom-success-btn1 me-2">
                            {change_pass()}
                          </Button>
                          {permissions.delete && (
                            <Button
                              className="custom-danger-btn"
                              onClick={() => handleDeleteUser(user)}
                            >
                              {role_delete()}
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" className="text-center">
                      No admins available.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>


          <nav aria-label="Page navigation example" className="mt-5">
            <ul className="pagination justify-content-end">
              {/* Previous Button */}
              <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                <button
                  className={`page-link ${currentPage !== 1 ? "text-success" : ""}`}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {role_previous()}
                </button>
              </li>

              {/* Page Numbers */}
              {[...Array(totalPages).keys()].map((page) => (
                <li
                  key={page}
                  className={`page-item ${currentPage === page + 1 ? "active" : ""}`}
                >
                  <button
                    className={`page-link ${currentPage === page + 1 ? "bg-success text-white" : "text-success"}`}
                    onClick={() => handlePageChange(page + 1)}
                  >
                    {page + 1}
                  </button>
                </li>
              ))}

              {/* Next Button */}
              <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                <button
                  className={`page-link ${currentPage !== totalPages ? "text-success" : ""}`}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  {role_next()}
                </button>
              </li>
            </ul>
          </nav>



        </>
      )}

      <UserManagementNew
        show={showModal}
        handleClose={handleCloseModal}
        modalTitle={selectedUser ? "Edit User" : "Add User"}
        userData={selectedUser}
        fetchUsers={fetchUsers}
      />
    </div>
  );
};

export default UserManagement;
