import React, { useState } from 'react';
import { Container, Card, Button, Row, Col, Form } from 'react-bootstrap';
import useTranslations from "../transalation/TranslationUtils";
import { useDispatch } from 'react-redux';
import { deleteMember } from 'app/redux/slices/rdxMemberSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
function DeleteAccount() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showErrMsg } = useTranslations();
    const memberEditData = useSelector((state) => state.rdxMember?.memberEditData || {});
    const [isChecked, setIsChecked] = useState(false);

    const handleDelete = () => {
        if (isChecked) {
            dispatch(deleteMember({ "account_id": memberEditData.account_id }, (isSuccess, data) => {
                if(isSuccess) {
                    alert("Account deleted successful!");
                    navigate("/member/membermanagement");
                } else {
                    alert(showErrMsg(data));
                }
            }));
        } else {
            alert("Please confirm before deleting your account.");
        }
    };

    const handleCancel = () => {
        console.log("Deletion cancelled.");
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const { deleteAccount, deleteYourAccount, loseAccessToApp, confirmDeleteAccount, cancel, deletes } = useTranslations();

    return (
        <Container fluid style={{ backgroundColor: 'white' }}>
            <Row>
                <Col md={8}>
                    <Card style={{ border: 'none' }}>
                        <Card.Body>
                            <Card.Title>{deleteYourAccount()}</Card.Title>
                            <Card.Text>
                                {loseAccessToApp()}
                            </Card.Text>


                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                </Col>
                <Card style={{ border: 'none' }}>
                    <Card.Body>
                        {/* Horizontal line */}
                        <hr style={{ borderTop: '1px solid #ccc', margin: '0 -15px' }} />
                        <br></br>

                        {/* Checkbox */}
                        <Form.Check
                            type="checkbox"
                            id="confirm-delete"
                            label={confirmDeleteAccount()}
                            onChange={handleCheckboxChange}
                            className="mb-3"
                        />

                        <div className="mt-4">
                            <Button variant="danger" disabled={!isChecked} onClick={handleDelete} className="me-2 deletebtn" >
                                {deletes()}
                            </Button>
                            {/* <Button variant="outline-secondary" className="canslebtn" onClick={handleCancel}>
                                {cancel()}
                            </Button> */}
                        </div>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );
}

export default DeleteAccount;
