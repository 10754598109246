import Account from "./views/account";
import Redirect from "./auth/Redirect";
import AuthGuard from "./auth/AuthGuard";
import mapRoutes from "./views/map/map-routes";
import ListRoute from "./views/list/list-route";
import crudRoute from "./views/CRUD/crud-routes";
import NotFound from "./views/sessions/NotFound";
import todoRoutes from "./views/todo/TodoRoutes";
import inboxRoute from "./views/inbox/inbox-routes";
import formsRoutes from "./views/forms/forms-routes";
import pagesRoutes from "./views/pages/pages-routes";
import chartsRoute from "./views/charts/charts-route";
import chatRoutes from "./views/chat-box/chat-routes";
import pricingRoutes from "./views/pricing/pricing-routes";
import invoiceRoutes from "./views/invoice/invoioce-routes";
import sessionRoutes from "./views/sessions/session-routes";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import calendarRoutes from "./views/calendar/calendar-routes";
import dashboardRoutes from "./views/dashboard/dashboard-routes";
import ecommerceRoutes from "./views/ecommerce/ecommerce-routes";
import materialRoutes from "./views/material-kit/MaterialRoutes";
import dragAndDropRoute from "./views/Drag&Drop/drag-drop-routes";
import pageLayoutRoutes from "./views/page-layouts/page-layout-routes";
import scrumBoardRoutes from "./views/scrum-board/scrum-board-routes";
import CampaignsPage from "./views/campaignmanagement/CampaignsPage";
import Member from "./views/membermanagement/Member";
import MemberInfo from "./views/membermanagement/MemberInfo";
import RoleManagement from "./views/role/RoleManagement";
import UserManagement from "./views/usermanage/UserManagement";
import Notification from "./views/pushnotification/Notification";
import UserCategories from "./views/usercategories/UserCategories";
import ImageModal from "./views/reports/ImageModal";
import NotificationForm from "./views/pushnotification/NotificationForm";
import BasicInfo from "./views/campaignmanagement/BasicInfo";
import Mainpage from "./views/campaignmanagement/Mainpage";
import Report from "./views/reports/Reports";
import CampaignType from "./views/usercategories/CampaignType";
import InstructorSetting from "./views/membermanagement/InstructorSetting";
import CompleteCampaign from "./views/dashboard/analytics-2/components/CompleteCampaign";
import OpenRegistration from "./views/dashboard/analytics-2/components/OpenRegistration";
import CampaignProgress from "./views/dashboard/analytics-2/components/CampaignProgress";
const routes = [
  {
    element: (
      // <AuthGuard>
      //   <MatxLayout />
      // </AuthGuard>


      <MatxLayout />

    ),
    children: [
      ...dashboardRoutes,
      ...calendarRoutes,
      ...chartsRoute,
      ...chatRoutes,
      ...crudRoute,
      ...dragAndDropRoute,
      ...ecommerceRoutes,
      ...formsRoutes,
      ...invoiceRoutes,
      ...ListRoute,
      ...mapRoutes,
      ...materialRoutes,
      ...inboxRoute,
      ...pageLayoutRoutes,
      ...pagesRoutes,
      ...pricingRoutes,
      ...scrumBoardRoutes,
      ...todoRoutes,
      { path: "/page-layouts/account", element: <Account /> },
      { path: "/campaign/campaignmanagement", element: <CampaignsPage /> },
      { path: "/member/membermanagement", element: <Member /> },
      { path: "/member/memberinfo", element: <MemberInfo /> },
      { path: "/member/instructor", element: <InstructorSetting /> },
      { path: "/role/rolemanagement", element: <RoleManagement /> },
      { path: "/user/usermanagement", element: <UserManagement /> },
      { path: "/pushnotification", element: <Notification /> },
      { path: "/pushnotification/createnotification", element: <NotificationForm /> },
      { path: "/user/usercategories", element: <UserCategories /> },
      { path: "/report/reports", element: <Report /> },
      { path: "/campaigns/mainpage", element: <Mainpage /> },
      { path: "/campaign/tpe", element: <CampaignType /> },
      { path: "/campaign/complete", element: <CompleteCampaign /> },
      { path: "/campaign/openregistratioon", element: <OpenRegistration /> },
      { path: "/campaign/progress", element: <CampaignProgress /> },




    ]
  },
  ...sessionRoutes,
  { path: "/", element: <Redirect /> },
  { path: "*", element: <NotFound /> },

];

export default routes;
