import React from 'react'
import Breadcrumb from "app/components/Breadcrumb";
import useTranslationshome from './Hometransalte'
import { Form, Button, Table, Container, Row, Col, Card } from "react-bootstrap";
const CampaignProgress = () => {

    
    const { listOfCompletedCampaigns,
        campaignName,
        enrolmentMethod,
        totalEnrolledMembers,
        waitlisted,
        totalClasses,
        totalClassAttendance,
        totalClassAbsence,
        totalRating, quota,
        rating, listOfOpenForRegistration,
        totalApplicants,
        enrolmentType,
        enrolmentPeriod,
        startDateAndEndDate,
        spaceAvailable,
        spaceTaken,
        luckyDrawDate,listOfCampaignInProgress,
        totalClassesCompleted,
        percentOfClassesCompleted, } = useTranslationshome();
    return (
        <div>
            <Row className="ps-4 pt-4 align-items-center">
                <Col>
                    <Breadcrumb routeSegments={[{ name: listOfCampaignInProgress() }]} />
                </Col>

            </Row>
            <table className="table table-striped mt-4">
                <thead>
                    <tr>
                        <th className="custom-th">{campaignName()}</th>
                        <th className="custom-th">{totalEnrolledMembers()}</th>
                        <th className="custom-th">{startDateAndEndDate()}</th>
                        <th className="custom-th">{totalClasses()}</th>
                        <th className="custom-th">{totalClassesCompleted()} </th>
                        <th className="custom-th">  {percentOfClassesCompleted()} </th>
                        <th className="custom-th"> {totalClassAttendance()} </th>
                        <th className="custom-th">{totalClassAbsence()} </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="custom-td">
                        <td>English Class</td>

                        <td>20</td>
                        <td>10/5/2024-10/15/2024</td>
                        <td>12</td>
                        <td>8</td>
                        <td>85%</td>
                        <td>156</td>
                        <td>4</td>


                    </tr>
                    <tr className="custom-td">
                        <td>Chinese History</td>

                        <td>30</td>
                        <td>10/28/2024-11/4/2024</td>
                        <td>12</td>
                        <td>8</td>
                        <td>89%</td>

                        <td>216</td>
                        <td>0</td>
                    </tr>
                </tbody>
            </table></div>
    )
}

export default CampaignProgress