

import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Image } from "react-bootstrap";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/MemberInfo.css";
import axios from 'axios';
import Avatars from './Avatars'
import useTranslations from '../transalation/TranslationUtils';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { initSimpleData, setSimpleData, setSimpleDataToMemberData, updateInterests } from "app/redux/slices/rdxMemberSlice";
import { useLanguage } from "app/contexts/LanguageContext";
function Interests() {
    const {
        savechaneg,
        cancel,
        interest,
        showErrMsg
    } = useTranslations();

    const dispatch = useDispatch();
    const { selectedLanguage } = useLanguage();


    const memberEditData = useSelector((state) => state.rdxMember?.memberEditData || {});
    const interestList = useSelector((state) => state.rdxMember?.interestList || {});
    const selectedInterests = useSelector((state) => state.rdxMember?.editInterests || []);

    const handleCheckboxChange = (event, id) => {
        let list = [...selectedInterests];
        let index = list.indexOf(id);
        if (index >= 0) {
            list.splice(index, 1);
        } else {
            list.push(id);
        }

        let data = {
            data: list,
            projection: "editInterests"
        }
        dispatch(setSimpleData(data));
    };


    useEffect(() => {
        let data = {
            data: memberEditData.account_interests,
            projection: "editInterests",
            type: "list"
        };
        dispatch(initSimpleData(data));
    }, []);

    const handleSave = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior


        let sendData = {
            account_id: memberEditData.account_id,
            account_interests: selectedInterests
        }
        dispatch(updateInterests(sendData, (isSuccess, data) => {
            if (!isSuccess) {
                alert(showErrMsg(data));
            } else {
                let rData = {
                    data: selectedInterests,
                    projection: "account_interests"
                };
                dispatch(setSimpleDataToMemberData(rData));
            }
        }));
    };

    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card className="mt-0 border-0" style={{ position: "relative" }}>
                        <Card.Body>

                            <Avatars initAvatar={memberEditData["account_avatar"]} />
                            <br />

                            <Form onSubmit={handleSave}>
                                <Row>
                                    <h5 className="basicinfo">{interest()}</h5>
                                    <Col md={9} className="mt-4">
                                        <Row>
                                            {interestList.map((interest, index) => (
                                                <Col md={3} key={index} className="mb-2">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={`checkbox-${index}`}
                                                        name={interest.interest_id} // Use interest name as the checkbox name
                                                        label={interest.interest_name[selectedLanguage]}
                                                        className="checkbtn"
                                                        checked={selectedInterests.includes(interest.interest_id)} // Use interest name for checked state
                                                        onChange={(event) => handleCheckboxChange(event, interest.interest_id)} // Handle checkbox change
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col>
                                        <Button variant="primary" type="submit" className="me-2 instructorphoto">
                                            {savechaneg()}
                                        </Button>
                                        {/* <button className="btn btn-outline-primary canslebtn ">{cancel()}</button> */}
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Interests;
