import { Navigate, useLocation } from "react-router-dom";
import useAuth from "app/hooks/useAuth";
import { defaultRoutes } from "./authRoles";

export default function Redirect() {
  let location = useLocation();

  const from = location.state?.from || "/dashboard/default";

  return <Navigate to={from} />;
}
