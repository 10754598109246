import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Card, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import './campaigncss/BasicInfo.css'
import { CardBody } from "reactstrap";
import { IoNotificationsOutline } from 'react-icons/io5';
import { Add } from '@mui/icons-material';
import axios from 'axios';
import { useLanguage } from 'app/contexts/LanguageContext';
import { useNavigate } from 'react-router-dom'; // Import useHistory from react-router-dom
import useTranslations from '../transalation/TranslationUtils';
import { useSelector } from "react-redux";
import { MODE_ADD, MODE_EDIT, MODE_UNKNOWN } from "app/utils/constant";
import { createCampaign, getCampaignDetail, initalCampaignBasicInfoPrice, initCampaignSimpleData, initialCampaignBasicInfo, setCampaignSimpleData, setCampaignSimpleDataToCampaignData, updateCampaign } from "app/redux/slices/rdxCampaignSlice";
import { useDispatch } from "react-redux";
function BasicInfo({ onSave }) {
    const navigate = useNavigate(); // Initialize useHistory


    const { cancel, save, code, type, pushstatus, basicInformation,
        campaignNameChinese,
        venueChinese,
        normal,
        delayed,
        cancelled,
        quota,
        inquiry,
        enrolmentMethod,
        firstComeFirstServe,
        lotteryDraw,
        targetAudienceAndCourseFee,
        target,
        selectAudience,
        fee,
        publish,
        enablePublish,
        assignTo,
        showErrMsg } = useTranslations();

    const dispatch = useDispatch();
    const campaignDetails = useSelector((state) => state.rdxCampaign?.campaignEditData || {});
    const editBasic = useSelector((state) => state.rdxCampaign?.editBasic || initialCampaignBasicInfo);
    const campaignEditMode = useSelector((state) => state.rdxCampaign?.campaignEditMode || MODE_UNKNOWN);
    const permissions = useSelector((state) => state.rdxMain?.pagePermissions?.campaign || {});


    useEffect(() => {
        // Component mount logic
        if (MODE_EDIT == campaignEditMode || MODE_ADD == campaignEditMode) {
            let data = {
                data: { ...campaignDetails },
                projection: "editBasic",
                type: "obj"
            };
            dispatch(initCampaignSimpleData(data));
        }
    }, [campaignEditMode]);

    const validateForm = () => {
        // Validate required fields
        if (!editBasic.campaign_name?.zh || !editBasic.campaign_name?.en) {
            alert("Please fill in both English and Chinese campaign names.");
            return false;
        }
        if (!editBasic.campaign_venue) {
            alert("Please fill in both English and Chinese venue.");
            return false;
        }
        if (!editBasic.campaign_type_id) {
            alert("Please select a campaign type.");
            return false;
        } if (!editBasic.campaign_inquiry?.admin_id) {
            alert("Please select an inquiry.");
            return false;
        }
        if (editBasic.campaign_code.length !== 6 || isNaN(editBasic.campaign_code)) {
            alert("The campaign code must be 6 digits.");
            return false;
        }
        if (!editBasic.campaign_status) {
            alert("Please select the campaign status.");
            return false;
        }
        if (!editBasic.campaign_intr?.zh || !editBasic.campaign_intr?.en) {
            alert("Please fill in both English and Chinese introductions.");
            return false;
        }

        if (!editBasic.campaign_quota) {
            alert("Please enter a valid quota.");
            return false;
        }
        if (!editBasic.campaign_enrolment_method) {
            alert("Please select the enrolment method.");
            return false;
        }
        if (editBasic.campaign_prices.length === 0) {
            alert("Please add at least one target audience and fee.");
            return false;
        }

        for (const row of editBasic.campaign_prices) {
            if (!row.price) {
                alert("Please enter a fee for all target audiences.");
                return false;
            }
        }
        return true;
    };

    const handleSave = async () => {
        const isValid = validateForm();
        if (!isValid) {
            return;
        }

        if (MODE_EDIT == campaignEditMode) {
            dispatch(updateCampaign(editBasic, (isSuccess, data) => {
                if (isSuccess) {
                    let rData = {
                        data: editBasic,
                        projection: "self",
                        base: campaignDetails
                    };
                    dispatch(setCampaignSimpleDataToCampaignData(rData));
                    alert("Campaign updated successfully!");
                } else {
                    alert(showErrMsg(data));
                }
            }));
        } else {
            dispatch(createCampaign(editBasic, (isSuccess, data) => {
                if (isSuccess) {
                    alert("Campaign create successfully!");

                    navigate("/campaigns/mainpage?campaign_id=" + data.data.campaign_id);
                    console.log(data);
                    dispatch(getCampaignDetail(data.data.campaign_id, (isSuccess, data) => {
                        if (!isSuccess) {
                            alert(showErrMsg(data));
                            navigate("/campaign/campaignmanagement");
                        }
                    }));
                } else {
                    alert(showErrMsg(data));
                }
            }));
        }
    };


    const [showBasicModal, setShowBasicModal] = useState(false);
    const handleshowBasicModal = () => setShowBasicModal(true);
    const handlecloseBasicModal = () => setShowBasicModal(false);


    const [data, setData] = useState([]);

    const fetchCampaignTypes = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getcampaigntypelist", {
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
            });
            // console.log(response.data)
            if (response.data.code === 1) {
                const campaignTypes = response.data.data || [];
                // Sort campaign types alphabetically based on campaign_type_code
                campaignTypes.sort((a, b) => {
                    return a.campaign_type_code.localeCompare(b.campaign_type_code);
                });
                setData(campaignTypes);
            } else {
                console.error("API responded with an error:", response.data.message_en);
            }
        } catch (error) {
            console.error("Error fetching campaign types:", error);
        }
    };

    // Fetch campaign types initially and refresh every 3 seconds
    useEffect(() => {
        fetchCampaignTypes();
        // const intervalId = setInterval(fetchCampaignTypes, 3000);
        return () => {
            // clearInterval(intervalId);
        }
    }, []);


    const [venuedata, setvenuedataData] = useState([]);
    const fetchCampaignVenues = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getvenuelist", {
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
            });

            // Log the raw response
            // console.log("Raw response:", response.data);

            if (response.data.code === 1) {
                // Directly access response.data.data which is an array
                const venuesData = response.data.data || [];

                // Log parsed data
                // console.log("Parsed venues data:", venuesData);

                setvenuedataData(venuesData);
            } else {
                console.error("API responded with an error:", response.data.message_en);
            }
        } catch (error) {
            console.error("Error fetching campaign venues:", error);
        }
    };
    useEffect(() => {
        fetchCampaignVenues();
        const intervalId = setInterval(fetchCampaignVenues, 3000);
        return () => clearInterval(intervalId);
    }, []);


    const { selectedLanguage } = useLanguage();

    const getRoleName = (item) => {
        return selectedLanguage === 'zh'
            ? item.campaign_type_name.zh
            : item.campaign_type_name.en;
    };

    const [users, setUsers] = useState([]);

    const fetchUsers = async () => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            console.error("No token found in localStorage");
            return;
        }

        try {
            const response = await axios.get(
                "https://membershipuat.maxisense.io/webapp/memportal/getadminlist",
                {
                    headers: {
                        Token: token,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.data.code === 1 && Array.isArray(response.data.data)) {
                setUsers(response.data.data);
            } else {
                console.warn("Unexpected API response format:", response.data);
                setUsers([]);
            }
        } catch (error) {
            console.error("Error fetching admin list:", error);
            setUsers([]);
        }

    };

    useEffect(() => {
        fetchUsers();
    }, []);


    const [audience, setAudience] = useState([]);

    const fetchaudience = async () => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            console.error("No token found in localStorage");
            return;
        }

        try {
            const response = await axios.get(
                "https://membershipuat.maxisense.io/webapp/memportal/gettargetaudiencelist",
                {
                    headers: {
                        Token: token,
                        "Content-Type": "application/json",
                    },
                }
            );
            // console.log("audience", response.data)
            if (response.data.code === 1 && Array.isArray(response.data.data)) {
                setAudience(response.data.data);
            } else {
                console.warn("Unexpected API response format:", response.data);
                setAudience([]);
            }
        } catch (error) {
            console.error("Error fetching admin list:", error);
            setAudience([]);
        }

    };

    useEffect(() => {
        fetchaudience();
    }, []);


    // const getAudienceName = (audien) => {
    //     return selectedLanguage === 'zh'
    //         ? audien.audience_name.zh
    //         : audien.audience_name.en;
    // };

    // const handleChangerow = (index, field, value) => {
    //     setRows((prevRows) =>
    //         prevRows.map((row, i) =>
    //             i === index ? { ...row, [field]: value } : row
    //         )
    //     );
    // };

    const handleAddRow = () => {
        let newRows = [...editBasic.campaign_prices];
        newRows.push({ ...initalCampaignBasicInfoPrice });

        let data = {
            data: newRows,
            projection: "editBasic",
            path: "campaign_prices"
        };
        dispatch(setCampaignSimpleData(data));
    };

    const handleRemoveRow = (index) => {
        let newRows = [...editBasic.campaign_prices];
        newRows.slice(index, 1);

        let data = {
            data: newRows,
            projection: "editBasic",
            path: "campaign_prices"
        };
        dispatch(setCampaignSimpleData(data));
    };

    const handleChange = (e, path) => {
        const { name, value } = e.target;

        let pass = false;
        let formatVal = undefined;
        if (name === "code") {
            // Ensure code is numeric and max 6 digits
            if (/^\d{0,6}$/.test(value)) {
                formatVal = value;
                pass = true;
            }
        } else if (name === "fee") {
            // Ensure fee is numeric and max 3 digits
            if (/^\d{0,3}$/.test(value)) {
                formatVal = parseInt(value);
                pass = true;
            }
        } else if (name === "campaignStatus" || name == "quota" || name == "enrolmentMethod") {
            formatVal = parseInt(value);
            pass = true;
        } else {
            formatVal = value;
            pass = true;
        }

        if (pass) {
            let data = {
                data: formatVal,
                projection: "editBasic",
                path: path
            }
            dispatch(setCampaignSimpleData(data));
        }
    };

    const handleToggle = (origVal, path) => {
        let data = {
            data: !origVal,
            projection: "editBasic",
            path: path
        }
        dispatch(setCampaignSimpleData(data));
    }

    const handleCancel = () => {
        if (window.confirm("Do you really want to cancel the campaign?")) {
            navigate("/campaign/campaignmanagement");
        }
    };

    return (
        <Container fluid>
            <Row>
                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Card.Body>
                        <Form>

                            <Row className="align-items-center mb-3">
                                <Col md={6} className="d-flex align-items-center">
                                    <h5 className="basicheading">{basicInformation()}</h5>
                                </Col>
                                <Col md={6} className="text-end">
                                    {/* <Button variant="secondary" className="basiccancelbtnn me-2" onClick={handleCancel}>{cancel()}</Button> */}
                                    <Button variant="primary" className="basicsavebtn me-3" onClick={handleSave}>{save()}</Button>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col md={12}>
                                    <Card className="basicinfocard">
                                        <CardBody className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
                                                <span className="basicstatus">{pushstatus()}: Draft</span>
                                            </div>
                                            <Button className="basicsavebtn " style={{ width: '110px' }} onClick={handleshowBasicModal}>{assignTo()}</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col md={6}>
                                    {/* Campaign Name (Chinese) */}
                                    <Form.Group controlId="subsidiaryNameChinese" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="campaignNamezh"
                                            value={editBasic.campaign_name?.zh}
                                            onChange={(e) => handleChange(e, "campaign_name|zh")}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">{campaignNameChinese()}</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    {/* Campaign Name (English) */}
                                    <Form.Group controlId="subsidiaryNameEnglish" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="campaignNameeng"
                                            value={editBasic.campaign_name?.en}
                                            onChange={(e) => handleChange(e, "campaign_name|en")}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">Campaign Name (English)</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {/* Venue Dropdown (Chinese) */}
                                    <Form.Group controlId="venueChinese" className="floating-label-group">
                                        <Form.Select
                                            name="venuezh" // Name corresponds to the key where venue ID will be stored
                                            value={editBasic.campaign_venue} // Bind to venueId in the state
                                            onChange={(e) => handleChange(e, "campaign_venue")} // Handle value changes
                                            className="floating-input"
                                        >
                                            <option value="">Select Venue (Chinese)</option>
                                            {venuedata.map((item) => (
                                                <option key={item.venue_id} value={item.venue_id}>
                                                    {item.venue_name.zh} {/* Display Chinese name */}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label className="floating-label">{venueChinese()}</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    {/* Venue Dropdown (English) */}
                                    <Form.Group controlId="venueEnglish" className="floating-label-group">
                                        <Form.Select
                                            name="venueeng" // Name corresponds to the key where venue ID will be stored
                                            value={editBasic.campaign_venue} // Bind to venueId in the state
                                            onChange={(e) => handleChange(e, "campaign_venue")} // Handle value changes
                                            className="floating-input"
                                        >
                                            <option value="">Select Venue (English)</option>
                                            {venuedata.map((item) => (
                                                <option key={item.venue_id} value={item.venue_id}>
                                                    {item.venue_name.en} {/* Display English name */}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label className="floating-label">Venue (English)</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={2}>
                                    <Form.Group controlId="type" className="floating-label-group">
                                        <Form.Select
                                            name="campaignTypeId"
                                            className="floating-input"
                                            value={editBasic.campaign_type_id}
                                            onChange={(e) => handleChange(e, "campaign_type_id")}
                                        >
                                            <option value="">Select Type</option>
                                            {data.map((item) => (
                                                <option key={item.campaign_type_id} value={item.campaign_type_id}>
                                                    {item.campaign_type_code} ({getRoleName(item)})
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label className="floating-label">{type()}</Form.Label>
                                    </Form.Group>
                                </Col>



                                {/* Code Input */}
                                <Col md={2}>
                                    {/* Code Input holding 240703 */}
                                    <Form.Group controlId="code" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="code"
                                            value={editBasic.campaign_code}
                                            onChange={(e) => handleChange(e, "campaign_code")}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">{code()}</Form.Label>
                                    </Form.Group>
                                </Col>

                                {/* District Dropdown */}
                                <Col md={2}>
                                    <Form.Group controlId="status" className="floating-label-group">
                                        <Form.Select
                                            name="campaignStatus"
                                            value={editBasic.campaign_status}
                                            onChange={(e) => handleChange(e, "campaign_status")}
                                            className="floating-input"
                                        >
                                            <option value="0">Select Status</option>
                                            <option value="1">Normal</option>
                                            {/* <option value="2">Delayed</option> */}
                                            <option value="3">Cancelled</option>
                                        </Form.Select>
                                        <Form.Label className="floating-label">{pushstatus()}</Form.Label>
                                    </Form.Group>
                                </Col>




                                {/* Quota Input */}
                                <Col md={1}>
                                    {/* Quota Input holding value 40 */}
                                    <Form.Group controlId="quota" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="quota"
                                            value={editBasic.campaign_quota}
                                            onChange={(e) => handleChange(e, "campaign_quota")}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">{quota()}</Form.Label>
                                    </Form.Group>
                                </Col>
                                {/* Inquiry Dropdown */}
                                <Col md={2}>
                                    <Form.Group controlId="inquiry" className="floating-label-group">
                                        <Form.Select name="inquiry" className="floating-input"
                                            value={editBasic.campaign_inquiry?.admin_id}
                                            onChange={(e) => handleChange(e, "campaign_inquiry|admin_id")}>
                                            <option value="">Select Inquiry</option>


                                            {users.map((user, index) => (
                                                <option key={user.admin_id} value={user.admin_id}>
                                                    {user.admin_name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label className="floating-label">{inquiry()}</Form.Label>
                                    </Form.Group>
                                </Col>

                                <Col md={3}>
                                    <Form.Group controlId="enrolmentMethod" className="floating-label-group">
                                        <Form.Select
                                            name="enrolmentMethod"
                                            value={editBasic.campaign_enrolment_method}
                                            onChange={(e) => handleChange(e, "campaign_enrolment_method")}
                                            className="floating-input"
                                        >
                                            <option value="0">Select Enrolement Method </option>

                                            <option value="1">First-come-first-served</option>
                                            <option value="2">Lottery Draw</option>
                                        </Form.Select>
                                        <Form.Label className="floating-label">{enrolmentMethod()}</Form.Label>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={6}>
                                    {/* Introduction (Chinese) */}
                                    <Form.Group controlId="introductionChinese" className="floating-label-group">
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="introductionChinese"
                                            value={editBasic.campaign_intr?.zh}
                                            onChange={(e) => handleChange(e, "campaign_intr|zh")}
                                            className="floating-input"
                                            style={{ height: '200px', paddingTop: '20px' }}
                                        />
                                        <Form.Label className="floating-label">Introduction (Chinese)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    {/* Introduction (English) */}
                                    <Form.Group controlId="introductionEnglish" className="floating-label-group">
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="introductionEnglish"
                                            value={editBasic.campaign_intr?.en}
                                            onChange={(e) => handleChange(e, "campaign_intr|en")}
                                            className="floating-input"
                                            style={{ height: '200px', paddingTop: '20px' }}

                                        />
                                        <Form.Label className="floating-label">Introduction (English)</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <br></br>
                            <Row>
                                <h5 className="targetheading">{targetAudienceAndCourseFee()}</h5>
                                {editBasic.campaign_prices?.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                <Form.Group controlId={`target-${index}`} className="floating-label-group">
                                                    <Form.Select
                                                        name="targetaudience"
                                                        className="floating-input"
                                                        value={row.audience_id}
                                                        onChange={(e) => handleChange(e, "campaign_prices|" + index + "|audience_id")}
                                                    >
                                                        <option value="">Select Audience</option>
                                                        {audience.map((audien, idx) => (
                                                            <option key={audien.audience_id || idx} value={audien.audience_id}>
                                                                {audien.audience_name.en}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Label className="floating-label">{target()}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group controlId={`fee-${index}`} className="floating-label-group">
                                                    <Form.Control
                                                        type="text"
                                                        name="fee"
                                                        className="floating-input"
                                                        value={row.price}
                                                        onChange={(e) => handleChange(e, "campaign_prices|" + index + "|price")}
                                                    />
                                                    <Form.Label className="floating-label">{fee()}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col md={1} className="d-flex justify-content-center align-items-center">
                                                <Button
                                                    style={{
                                                        backgroundColor: '#FF4694',
                                                        border: 'none',
                                                        height: '24px',
                                                        width: '24px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '0',
                                                    }}
                                                    onClick={() => handleRemoveRow(index)}
                                                    disabled={editBasic.campaign_prices?.length === 1}
                                                >
                                                    --
                                                </Button>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                ))}
                            </Row>
                            <Row className="mt-3">
                                <Col md={1}>
                                    <Button
                                        style={{
                                            backgroundColor: '#1976D2',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            padding: '0',
                                        }}
                                        onClick={handleAddRow}
                                    >
                                        <Add style={{ color: '#fff', fontSize: '16px' }} />
                                    </Button>
                                </Col>
                            </Row>
                            <br></br><br></br>
                            <Row>
                                <h5 className="targetheading">{publish()}  <FormControlLabel
                                    disabled={!permissions.campaign_review}
                                    control={
                                        <Switch
                                            checked={editBasic.is_publish} // Check state value properly
                                            onChange={(e) => handleToggle(editBasic.is_publish, "is_publish")}
                                        />
                                    }
                                    label=""
                                />
                                </h5>
                                <p className="publictext">{enablePublish()}</p>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Row>
            <Modal
                show={showBasicModal}
                onHide={handlecloseBasicModal}
                centered
                className="custom-modal-width"
            >
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">
                            {assignTo()}
                        </h4>

                        <Form.Group controlId="formStatus" className="mt-3 ms-3">
                            <Form.Check
                                type="checkbox"
                                label="Ling (Manager)"
                                name="status"
                                value="pre-approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Rainbow Lee (Campaign Organizer)"
                                name="status"
                                value="approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Alex Law (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Chan Siu Ming (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant=""
                        onClick={handlecloseBasicModal}
                        className="me-2 groupclear"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant=""
                        className="groupsearch"
                        onClick={handlecloseBasicModal}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}
export default BasicInfo;












