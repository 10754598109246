import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mLoading: false
};

const rdxLoadingSlice = createSlice({
  initialState,
  name: "rdxLoading",
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => !action.type.includes("noLoading") && action.type.endsWith('/pending'),
      (state) => {
        state.mLoading = true;
      }
    );

    builder.addMatcher(
      (action) => !action.type.includes("noLoading") && (action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected')),
      (state) => {
        state.mLoading = false;
      }
    );
  },
  reducers: {}
});

export const rdxLoadingReducer = rdxLoadingSlice.reducer;