

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { IoNotificationsOutline } from "react-icons/io5";
import { Add, RemoveCircle, Edit } from "@mui/icons-material";
import { Icon } from '@mui/material';
import "./campaigncss/Survey.css";
import useTranslations from '../transalation/TranslationUtils';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setCampaignSimpleDataToCampaignData } from "app/redux/slices/rdxCampaignSlice";
function Survy({ }) {
    const dispatch = useDispatch();
    const campaignDetails = useSelector((state) => state.rdxCampaign?.campaignEditData || {});

    const [showBasicModal, setShowBasicModal] = useState(false);
    const [questionRows, setQuestionRows] = useState([]); // Manage dynamic rows
    const [loading, setLoading] = useState(false); // To manage loading state
    const [error, setError] = useState(""); // To manage error state

    const { cancel, save, pushstatus, assignTo, survey,
        memberComments,
        enableUserComments, } = useTranslations();

    const handleshowBasicModal = () => setShowBasicModal(true);
    const handlecloseBasicModal = () => setShowBasicModal(false);

    const [campaignId, setCampaignId] = useState("");
    const [isAllowComment, setIsAllowComment] = useState(false);

    const handleAddClick = () => {
        setQuestionRows([
            ...questionRows,
            { id: Date.now(), chinese: "", english: "" } // Add new empty question row
        ]);
    };

    const handleRemoveClick = (id) => {
        setQuestionRows(questionRows.filter((row) => row.id !== id)); // Remove specific question row
    };

    const handleChange = (id, field, value) => {
        setQuestionRows(
            questionRows.map((row) =>
                row.id === id ? { ...row, [field]: value } : row
            )
        );
    };

    useEffect(() => {
        if (campaignDetails) {
            setCampaignId(campaignDetails.campaign_id);
            setIsAllowComment((campaignDetails.is_allow_comment) ? true : false);

            const fetchSurveyData = async () => {
                setError("");

                try {
                    const token = localStorage.getItem("authToken");
                    const response = await fetch("https://membershipuat.maxisense.io/webapp/memportal/getcampaignsurveylist", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Token: token
                        },
                        body: JSON.stringify({ campaign_id: campaignDetails.campaign_id })
                    });

                    if (!response.ok) {
                        throw new Error("Failed to fetch survey data");
                    }

                    const data = await response.json();
                    if (data.code === 1) {
                        const formattedData = data.data.map((item) => ({
                            id: item.survey_id,
                            chinese: item.survey_content.zh,
                            english: item.survey_content.en
                        }));
                        setQuestionRows(formattedData);

                        let rData = {
                            data: { is_allow_comment: isAllowComment },
                            projection: "self",
                            base: campaignDetails
                        };
                        dispatch(setCampaignSimpleDataToCampaignData(rData));
                    } else {
                        throw new Error(data.message_en || "Error fetching survey data");
                    }
                } catch (error) {
                    setError(error.message);
                }
            };

            fetchSurveyData();
        }
    }, []);

    const handleToggle = (origVal, path) => {
        setIsAllowComment(!isAllowComment);
    }


    const handleSave = async () => {
        if (!campaignId) {
            setError("Campaign ID not provided");
            return;
        }

        setError("");
        setLoading(true);

        try {
            const token = localStorage.getItem("authToken");
            const surveyList = questionRows.map((row, index) => ({
                business_id: "YMMSS001", // Assuming a constant business_id for now
                survey_id: row.id, // Use the row ID as survey_id
                survey_order: index, // Order based on the index
                survey_content: {
                    en: row.english,
                    zh: row.chinese,
                },
                campaign_id: campaignId, // Use the current campaign ID
            }));

            const payload = {
                campaign_id: campaignId,
                is_allow_comment: isAllowComment, // Assuming comments are allowed
                survey_list: surveyList,
            };

            const response = await fetch("https://membershipuat.maxisense.io/webapp/memportal/updatecampaignsurvey", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Failed to update survey data");
            }

            const data = await response.json();
            if (data.code === 1) {
                alert("Survey updated successfully");
            } else {
                throw new Error(data.message_en || "Error updating survey data");
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <Container>
            <Row>
                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Card.Body>
                        <Form>
                            <Row className="align-items-center mb-3">
                                <Col md={6} className="d-flex align-items-center">
                                    <h5 className="basicheading">{survey()}</h5>
                                </Col>
                                <Col md={6} className="text-end">
                                    {/* <Button variant="secondary" className="basiccancelbtnn me-2">{cancel()}</Button> */}
                                    <Button variant="primary" className="basicsavebtn me-3" onClick={handleSave}>{save()}</Button>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col md={12}>
                                    <Card className="basicinfocard">
                                        <Card.Body className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
                                                <span className="basicstatus">{pushstatus()}: Draft</span>
                                            </div>
                                            <Button className="basicsavebtn " style={{ width: "110px" }} onClick={handleshowBasicModal}>
                                                {assignTo()}
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row> */}

                            {/* Show error or loading */}
                            {error && <p className="text-danger">{error}</p>}
                            {loading && <p>Loading...</p>}

                            {/* Render dynamic question rows */}
                            {!loading &&
                                questionRows.map((row) => (
                                    <Row key={row.id} className="align-items-center mb-3 mt-3">
                                        <Col>
                                            <Form.Group className="floating-label-group">
                                                <Form.Control
                                                    as="textarea"
                                                    rows={2}
                                                    className="floating-input "
                                                    value={row.chinese}
                                                    onChange={(e) => handleChange(row.id, "chinese", e.target.value)}
                                                    style={{
                                                        height: "auto",
                                                        borderColor: "#848484",
                                                        backgroundColor: "transparent",
                                                        display: "flex",



                                                    }}
                                                />
                                                <Form.Label className="floating-label">Question (Chinese)</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="floating-label-group">
                                                <Form.Control
                                                    as="textarea"
                                                    rows={2}
                                                    className="floating-input"
                                                    value={row.english}
                                                    onChange={(e) => handleChange(row.id, "english", e.target.value)}
                                                    style={{
                                                        height: "auto",
                                                        borderColor: "#848484",
                                                        backgroundColor: "transparent"
                                                    }}
                                                />
                                                <Form.Label className="floating-label">Question (English)</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={1} className="d-flex flex-column justify-content-between ps-4">
                                            <Button
                                                style={{
                                                    backgroundColor: "#FF4694",
                                                    border: "none",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "0",
                                                    marginBottom: "8px"
                                                }}
                                                onClick={() => handleRemoveClick(row.id)} // Remove specific row
                                            >
                                                <Icon>remove</Icon>
                                            </Button>
                                            <Button
                                                style={{
                                                    backgroundColor: "#1976D2",
                                                    border: "none",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "0"
                                                }}
                                            >
                                                <Icon>edit</Icon>
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}

                            {/* Add button */}
                            <Row className="mt-3">
                                <Col md={1}>
                                    <Button
                                        style={{
                                            backgroundColor: "#1976D2",
                                            border: "none",
                                            height: "24px",
                                            width: "24px",
                                            padding: "0"
                                        }}
                                        onClick={handleAddClick} // Add new row
                                    >
                                        <Add style={{ color: "#fff", fontSize: "16px" }} />
                                    </Button>
                                </Col>
                            </Row>

                            {/* User Comments Section */}
                            <Row className="mt-5">
                                <h5 className="usercomment mt-5">
                                    {memberComments()}
                                    <FormControlLabel control={<Switch
                                        checked={isAllowComment} // Check state value properly
                                        onChange={(e) => handleToggle()}
                                    />} label="" />
                                </h5>
                                <p className="usertext">
                                    {enableUserComments()}   </p>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Row>

            {/* Modal */}
            <Modal
                show={showBasicModal}
                onHide={handlecloseBasicModal}
                centered
                className="custom-modal-width"
            >
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">
                            {assignTo()}
                        </h4>

                        <Form.Group controlId="formStatus" className="mt-3 ms-3">
                            <Form.Check
                                type="checkbox"
                                label="Ling (Manager)"
                                name="status"
                                value="pre-approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Rainbow Lee (Campaign Organizer)"
                                name="status"
                                value="approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Alex Law (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Chan Siu Ming (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant=""
                        onClick={handlecloseBasicModal}
                        className="me-2 groupclear"
                    >
                        {cancel()}
                    </Button>
                    <Button
                        variant=""
                        className="groupsearch"
                        onClick={handlecloseBasicModal}
                    >
                        {save()}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Survy;
