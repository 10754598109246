import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import './CampaignsPage.css';
import { useNavigate } from "react-router-dom";
import { useLanguage } from 'app/contexts/LanguageContext';
import axios from 'axios';
import useTranslations from '../transalation/TranslationUtils';
import { resetAllCampaignPageData } from 'app/redux/slices/rdxCampaignSlice';
import { useDispatch } from 'react-redux';

const statusMap = {
  1: "Normal",
  2: "Delayed",
  3: "Cancelled",
};
const CampaignsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState({
    campaignName: "",
    code: "",
    type: "",
    status: "",
    startDate: "",
    pic: "",
    sis: ""
  });


  const { campaignManagement,
    campaignName,
    startDate,
    pic,
    createNewCampaign,
    details,
    copy, usersearch, exactmatch, pushreset, role_next, role_previous, code, type, pushstatus, func_t, role_delete } = useTranslations();



  const handleAddMember = () => {
    navigate("/campaigns/mainpage");
  };

  // State for pagination
  const [page, setPage] = useState(0);
  const rowsPerPage = 15; // Default rows per page

  // Sample data for campaigns

  // Calculate total pages


  const [data, setData] = useState([]);
  const sortedData = [...data].sort((a, b) => a.campaign_type_code.localeCompare(b.campaign_type_code));


  const fetchCampaignTypes = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getcampaigntypelist", {
        headers: {
          "Content-Type": "application/json",
          Token: token,
        },
      });

      if (response.data.code === 1) {
        const campaignTypes = response.data.data || [];
        // Sort campaign types alphabetically based on campaign_type_code
        campaignTypes.sort((a, b) => {
          return a.campaign_type_code.localeCompare(b.campaign_type_code);
        });
        setData(campaignTypes);
        // console.log("my data", response.data)
      } else {
        console.error("API responded with an error:", response.data.message_en);
      }
    } catch (error) {
      console.error("Error fetching campaign types:", error);
    }
  };

  // Fetch campaign types initially and refresh every 3 seconds
  useEffect(() => {
    fetchCampaignTypes();
    // const intervalId = setInterval(fetchCampaignTypes, 3000);
    return () => {
      // clearInterval(intervalId);
      dispatch(resetAllCampaignPageData());
    }
  }, []);

  const [users, setUsers] = useState([]);
  const fetchUsers = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("No token found in localStorage");

      return;
    }

    try {
      const response = await axios.get(
        "https://membershipuat.maxisense.io/webapp/memportal/getadminlist",
        {
          headers: {
            Token: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.code === 1 && Array.isArray(response.data.data)) {
        setUsers(response.data.data);
      } else {
        console.warn("Unexpected API response format:", response.data);
        setUsers([]);
      }
    } catch (error) {
      console.error("Error fetching admin list:", error);
      setUsers([]);
    }

  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const [campaign, setCampaign] = useState([]);


  // Calculate total pages
  const totalCampaigns = campaign.length;
  const totalPages = Math.ceil(totalCampaigns / rowsPerPage);

  // Get the current campaigns for the current page
  const currentCampaigns = campaign.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  // Handle page change for pagination
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };





  const fetchCampaign = async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    try {
      const response = await axios.post(
        "https://membershipuat.maxisense.io/webapp/memportal/getcampaignlist",
        {},
        {
          headers: {
            Token: token,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data)
      if (response.data.code === 1 && Array.isArray(response.data.data)) {
        // Success: set campaign data
        setCampaign(response.data.data);
        console.log(response.data)
      } else if (response.data.code === -5) {
        console.warn("Session expired, please log in again.");

        setCampaign([]); // Optionally, clear campaigns
      } else {

        console.warn("Unexpected API response format:", response.data);
        setCampaign([]);
      }
    } catch (error) {
      console.error("Error fetching campaign list:", error);
      setCampaign([]);
    }
  };


  useEffect(() => {
    fetchCampaign();
  }, []);

  // Convert Unix timestamp to a human-readable date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US");
  };


  const { selectedLanguage } = useLanguage();


  const getRoleName = (item) => {
    return selectedLanguage === 'zh'
      ? item.campaign_type_name.zh
      : item.campaign_type_name.en;
  };

  const getcampaigntypename = (campaign) => {
    return selectedLanguage === 'zh'
      ? campaign.campaign_name.zh
      : campaign.campaign_name.en;
  }



  const handleDetailsClick = async (campaignId) => {
    navigate("/campaigns/mainpage?campaign_id=" + campaignId);
  };

  const [successMessage, setSuccessMessage] = useState("");




  const handleDeleteUser = async (campaign) => {
    if (
      window.confirm(
        `Are you sure you want to delete campaign ${getcampaigntypename(campaign)}?`
      )
    ) {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("No token found in localStorage");
        return;
      }

      try {
        const response = await axios.post(
          "https://membershipuat.maxisense.io/webapp/memportal/deletecampaign",
          {
            campaign_id: campaign.campaign_id,
          },
          {
            headers: {
              Token: token,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.code === 1) {
          // Remove the deleted campaign from the state
          setCampaign((prevCampaigns) =>
            prevCampaigns.filter((c) => c.campaign_id !== campaign.campaign_id)
          );

          setSuccessMessage(
            `Campaign ${getcampaigntypename(campaign)} deleted successfully!`
          );
          setTimeout(() => setSuccessMessage(""), 2000);
        } else {
          alert(response.data.message || "Failed to delete campaign.");
        }
      } catch (error) {
        console.error("Error deleting campaign:", error);
        alert("An error occurred while deleting the campaign.");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "code") {
      // Ensure code is numeric and max 6 digits
      if (/^\d{0,6}$/.test(value)) {
        setSearchParams((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setSearchParams((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };



  const handleSearch = () => {
    const filteredCampaigns = campaign.filter((c) => {
      return (
        (searchParams.campaignName === "" || getcampaigntypename(c).toLowerCase().includes(searchParams.campaignName.toLowerCase())) &&
        (searchParams.code === "" || c.campaign_code.toString().includes(searchParams.code)) &&
        (searchParams.type === "" || c.campaign_type_code === searchParams.type) &&
        (searchParams.status === "" || statusMap[c.campaign_status] === searchParams.status) &&
        (searchParams.startDate === "" || formatDate(c.campaign_start_date) === searchParams.startDate) &&
        (searchParams.pic === "" || (c.pic && c.pic.toLowerCase().includes(searchParams.pic.toLowerCase()))) &&
        (searchParams.sis === "" || c.sis === searchParams.sis)
      );
    });
    setFilteredCampaigns(filteredCampaigns);
  };



  const handleReset = () => {
    setSearchParams({
      campaignName: "",
      code: "",
      type: "",
      status: "",
      startDate: "",
      pic: "",
      sis: ""
    });
    setFilteredCampaigns(campaign);
  };

  const [filteredCampaigns, setFilteredCampaigns] = useState(campaign);

  useEffect(() => {
    setFilteredCampaigns(campaign);
  }, [campaign]);

  return (
    <Container fluid>

      <Form className="mb-3 p-3 border rounded shadow-custom" style={{ marginTop: '30px' }}>
        <Row className="g-3"> {/* Added spacing between elements */}
          <Col md={3} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>{campaignName()}:</Form.Label>
            <Form.Control type="text" name="campaignName" value={searchParams.campaignName} onChange={handleInputChange} />
          </Col>
          <Col md={2} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>{code()}:</Form.Label>
            <Form.Control
              type="text"
              name="code"
              value={searchParams.code}
              onChange={handleInputChange}
            />
          </Col>
          <Col md={2} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>{type()}:</Form.Label>
            <Form.Select name="type" value={searchParams.type} onChange={handleInputChange}>
              <option value=""></option>
              {sortedData.map((item) => (
                <option key={item.campaign_type_code} value={item.campaign_type_code}>
                  {item.campaign_type_code} ({getRoleName(item)})
                </option>))}
            </Form.Select>

          </Col>
          <Col md={2} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>{pushstatus()}:</Form.Label>
            <Form.Select name="status" value={searchParams.status} onChange={handleInputChange}>
              <option value=""></option>
              <option>Draft</option>
              {/* <option>Pending Approval</option>
              <option>Approved</option> */}
              <option>Promotion</option>
              {/* <option>Pre-registration</option> */}
              <option>Enrollment</option>
              {/* <option>Start</option>
              <option>End</option> */}
              <option>In-Progress</option>
              <option>Survey</option>

            </Form.Select>
          </Col>
          <Col md={3} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>{startDate()}:</Form.Label>
            <Form.Control type="date" name="startDate" value={searchParams.startDate} onChange={handleInputChange} />
          </Col>




          <Col md={3} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>{pic()}:</Form.Label>

            <Form.Select name="pic" value={searchParams.pic} onChange={handleInputChange}>
              <option value=""></option>

              {users.map((item) => (
                <option key={item.admin_id} value={item.admin_name}>
                  {item.admin_name}
                </option>
              ))}
            </Form.Select>

          </Col>


          <Col md={2} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>SIS :-</Form.Label>
            <Form.Select name="sis" value={searchParams.sis} onChange={handleInputChange}>
              <option value=""></option>
              <option>Y</option>
              <option>N</option>
            </Form.Select>
          </Col>
        </Row>

        <Row className="mt-3">


          <Col className="d-flex align-items-center">
            <Button variant="success" className="custom-success-btn me-2" onClick={handleSearch}>{usersearch()}</Button>
            <Button variant="success" className="custom-success-btn me-2" onClick={handleReset}>{pushreset()}</Button>
            <Form.Check
              type="checkbox"
              id="exampleCheck"
              label={exactmatch()}
              className="ms-2 exactsearch"
            />
          </Col>



          <Col className='text-end'>
            <Button onClick={handleAddMember} variant="success" className="custom-success-btn " style={{ width: '178px' }}>{createNewCampaign()}</Button>
          </Col>
        </Row>
      </Form>

      {successMessage && (
        <Alert
          variant="success"
          onClose={() => setSuccessMessage("")}
          dismissible
        >
          {successMessage}
        </Alert>
      )}

      <hr />
      <div className="table-responsive">
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th className="cmptablehead">{campaignName()}</th>
              <th className="cmptablehead">
                <div className=" d-flex justify-content-center align-items-center">
                  {type()}
                </div>
              </th>
              <th className="cmptablehead">
                <div className=" d-flex justify-content-center align-items-center">
                  {code()}
                </div>
              </th>
              <th className="cmptablehead">
                <div className=" d-flex justify-content-center align-items-center">
                  {pushstatus()}
                </div>
              </th>
              <th className="cmptablehead">
                <div className=" d-flex justify-content-center align-items-center">
                  {startDate()}
                </div>
              </th>
              <th className="cmptablehead">
                <div className=" d-flex justify-content-center align-items-center">
                  {func_t()}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredCampaigns.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((campaign, index) => (
              <tr key={index} className="cmptablehead">
                <td>{getcampaigntypename(campaign)}</td>
                <td>
                  <div className=" d-flex justify-content-center align-items-center">
                    {campaign.campaign_type_code}
                  </div>
                </td>
                <td>
                  <div className=" d-flex justify-content-center align-items-center">{campaign.campaign_code}</div></td>
                <td><div className=" d-flex justify-content-center align-items-center">{statusMap[campaign.campaign_status] || "Unknown"}</div></td>
                <td><div className=" d-flex justify-content-center align-items-center">{formatDate(campaign.campaign_start_date)}</div></td>
                <td>
                  <div className="button-group d-flex justify-content-end align-items-end">
                    <Button variant="success" className="custom-success-btn me-2" onClick={() => handleDetailsClick(campaign.campaign_id)}>{details()}</Button>
                    <Button variant="success" className="custom-success-btn me-2">{copy()}</Button>
                    <Button variant="danger" className="custom-danger-btn  me-2" onClick={() => handleDeleteUser(campaign)}>{role_delete()}</Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <nav aria-label="Page navigation example" className="mt-5">
        <ul className="pagination justify-content-end">
          {/* Previous Button */}
          <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
            <a
              className={`page-link ${page !== 0 ? 'text-success' : ''}`}
              href="#"
              onClick={() => handlePageChange(page - 1)}
              tabIndex="-1"
              aria-disabled={page === 0}
            >
              {role_previous()}
            </a>
          </li>

          {/* Page Numbers */}
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index} className={`page-item ${page === index ? 'active' : ''}`}>
              <a
                className={`page-link ${page === index ? 'bg-success text-white' : 'text-success'}`}
                href="#"
                onClick={() => handlePageChange(index)}
              >
                {index + 1}
              </a>
            </li>
          ))}

          {/* Next Button */}
          <li className={`page-item ${page === totalPages - 1 ? 'disabled' : ''}`}>
            <a
              className={`page-link ${page !== totalPages - 1 ? 'text-success' : ''}`}
              href="#"
              onClick={() => handlePageChange(page + 1)}
            >
              {role_next()}
            </a>
          </li>
        </ul>
      </nav>

    </Container>
  );
};

export default CampaignsPage;
