


import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/MemberInfo.css";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import axios from 'axios';
import useTranslations from '../transalation/TranslationUtils';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updatePwd } from "app/redux/slices/rdxMemberSlice";

function Password() {
    const [inputValues, setInputValues] = useState({
        input1: "",
        input2: "",
    });
    const [forcePwdLogin, setForcePwdLogin] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [showPassword, setShowPassword] = useState({
        input1: false,
        input2: false,
    }); // State to track password visibility


    const dispatch = useDispatch();
    const { showSuccessMsg, showErrMsg } = useTranslations();
    const memberEditData = useSelector((state) => state.rdxMember?.memberEditData || {});


    const { password, newPassword, confirmPassword, createNewPasswordNextLogin,
        passwordRequirement, ensureRequirementsMet, minimumCharacters, oneLowercaseCharacter,
        oneUppercaseCharacter, oneNumber, savechaneg, cancel
    } = useTranslations();


    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const togglePasswordVisibility = (field) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

        if (!passwordRegex.test(password)) {
            return 'Password must be at least 8 characters long and include at least 1 lowercase letter, 1 uppercase letter, and 1 number.';
        }

        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        const { input1, input2 } = inputValues;

        if (input1 !== input2) {
            setError("Passwords do not match");
            return;
        }

        const passwordError = validatePassword(input1);
        if (passwordError) {
            setError(passwordError);
            return;
        }

        let sendData = {
            "account_id": memberEditData.account_id,
            "account_password": input2,
            "is_force_change_password": forcePwdLogin
        };
        dispatch(updatePwd(sendData, (isSuccess, data) => {
            if (isSuccess) {
                setSuccess(showSuccessMsg(data));
                setError(null);
                setInputValues({ input1: "", input2: "" });
                setForcePwdLogin(false);
            } else {
                setSuccess(null);
                setError(showErrMsg(data));
            }
        }));
    };

    const handleCancel = () => {
        setInputValues({ input1: "", input2: "" });
        setError(null);
        setSuccess(null);
    };

    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card sx={{ border: '1px solid #d9d9d9', mt: 0 }}>
                        <Card.Header style={{
                            height: '80px',
                            backgroundColor: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left'
                        }}>
                            <h6 style={{ margin: 0 }}>{password()}</h6>
                        </Card.Header>

                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId="formInput1" className="floating-label-group position-relative">
                                            <Form.Control
                                                type={showPassword.input1 ? "text" : "password"} // Toggle input type
                                                name="input1"
                                                value={inputValues.input1}
                                                onChange={handleChange}
                                                className="floating-input"
                                                required
                                            />
                                            <Form.Label className="floating-label">{newPassword()}</Form.Label>
                                            <span
                                                onClick={() => togglePasswordVisibility('input1')}
                                                style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    cursor: "pointer",
                                                    color: "#6c757d",
                                                }}
                                            >
                                                {showPassword.input1 ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </Form.Group>

                                        <Form.Group controlId="formInput2" className="floating-label-group position-relative">
                                            <Form.Control
                                                type={showPassword.input2 ? "text" : "password"} // Toggle input type
                                                name="input2"
                                                value={inputValues.input2}
                                                onChange={handleChange}
                                                className="floating-input"
                                                required
                                            />
                                            <Form.Label className="floating-label">{confirmPassword()}</Form.Label>
                                            <span
                                                onClick={() => togglePasswordVisibility('input2')}
                                                style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    cursor: "pointer",
                                                    color: "#6c757d",
                                                }}
                                            >
                                                {showPassword.input2 ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </Form.Group>

                                        <Form.Group className="floating-label-group">
                                            <Form.Check
                                                type="checkbox"
                                                label={createNewPasswordNextLogin()}
                                                checked={forcePwdLogin}
                                                onChange={(event) => {
                                                    setForcePwdLogin(!forcePwdLogin);
                                                }}
                                            />
                                        </Form.Group>
                                        {error && <div style={{ color: 'red' }}>{error}</div>}
                                        {success && <div style={{ color: 'green' }}>{success}</div>}
                                    </Col>

                                    <Col md={6}>
                                        <h6>{passwordRequirement()}:</h6>
                                        <h6>{ensureRequirementsMet()}:</h6>
                                        <ul style={{ listStyleType: "disc", paddingLeft: "20px", marginTop: '5px', color: 'black' }}>
                                            <li style={{ fontSize: "16px" }}>{minimumCharacters()}</li>
                                            <li style={{ fontSize: "16px" }}>{oneLowercaseCharacter()}</li>
                                            <li style={{ fontSize: "16px" }}>{oneUppercaseCharacter()}</li>
                                            <li style={{ fontSize: "16px" }}>{oneNumber()}</li>
                                        </ul>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col>
                                        <Button variant="primary" className="me-2 instructorphoto" type="submit">
                                            {savechaneg()}
                                        </Button>
                                        {/* <Button variant="outline-primary" className="canslebtn" onClick={handleCancel}>
                                            {cancel()}
                                        </Button> */}
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Password;
