import React, { useState, useEffect } from "react";
import { Form, Button, Table, Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./membercss/Member.css";
import Breadcrumb from "app/components/Breadcrumb";
import { IoIosSearch } from "react-icons/io";
import Identity from "./Identity";
import Advance from "./Advance";
import useTranslations from "../transalation/TranslationUtils";
import { useSelector } from "react-redux";
import { getMembers, resetAllMemberPageData, searchMembers, setEditMemberData } from "app/redux/slices/rdxMemberSlice";
import { useDispatch } from "react-redux";
import { TAB_MEMBER_LIST_ADVANCE, TAB_MEMBER_LIST_IDENTITY, TAB_MEMBER_LIST_SEARCH } from "app/utils/constant";

const Member = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchOption, setSearchOption] = useState("Name");
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const permissions = useSelector((state) => state.rdxMain?.pagePermissions?.account || {});
  const members = useSelector((state) => state.rdxMember?.memberList || []);
  const filteredMembers = useSelector((state) => state.rdxMember?.filterMemberList || []);

  const {
    membermange,
    mAdd,
    searchmember,
    usersearch,
    result,
    membername,
    joineddate,
    mobile,
    memberid,
    cardref,
    app1st,
    memberidentity,
    func_t,
    nomemberfound,
    mEdit,
    role_next,
    role_previous,
    midentity,
    mAdvance,
    searchformemberm,
    showErrMsg
  } = useTranslations();

  const handleAddMember = () => {
    navigate("/member/memberinfo");
  };

  const handleEditMember = (member) => {
    navigate("/member/memberinfo?account_id=" + member.account_id);
  };

  useEffect(() => {
    // Component mount logic
    dispatch(getMembers());

    return () => {
      // Component unmount logic
      dispatch(resetAllMemberPageData())
    };
  }, []);
  useEffect(() => {
    console.log(permissions);
  }, [permissions]);


  const handleSearchOptionChange = (e) => {
    setSearchOption(e.target.value);
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearch = () => {
    let sendData = {
      cat: searchOption,
      keyword: searchInput
    }

    dispatch(searchMembers(sendData, members, (isSuccess, data) => {
      if (isSuccess) {
        setPage(0);
      } else {
        alert(showErrMsg(data));
      }
    }));
  };

  const handlePaginationClick = (index) => {
    setPage(index);
  };

  const currentMembers = filteredMembers.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  const totalPages = Math.ceil(filteredMembers.length / rowsPerPage);

  const [activeTab, setActiveTab] = useState(TAB_MEMBER_LIST_SEARCH);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  function formatDate(input, format = "date") {
    let date;

    if (typeof input === "string") {
      date = new Date(input);
    } else if (typeof input === "number") {
      date = new Date(input);
    } else if (Array.isArray(input) && input.length === 1 && typeof input[0] === "number") {
      date = new Date(input[0]);
    } else if (!input) {
      return "";
    } else {
      return "Invalid";
    }

    // Ensure the Date object is valid
    if (isNaN(date.getTime())) {
      return "Invalid";
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    if (format === "date") {
      return `${year}-${month}-${day}`;
    } else if (format === "datetime") {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } else {
      return "Invalid";
    }
  }

  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="ps-4 pt-4 align-items-center">
        <Col>
          <Breadcrumb routeSegments={[{ name: membermange() }]} />
        </Col>
        <Col className="text-end me-4">
          {(activeTab === TAB_MEMBER_LIST_SEARCH || activeTab === TAB_MEMBER_LIST_IDENTITY || activeTab === TAB_MEMBER_LIST_ADVANCE) &&
            permissions.add && (
              <Button className="custom-success-btn me-2" onClick={handleAddMember}>
                {mAdd()}
              </Button>
            )}
        </Col>
      </Row>

      <Row className="ps-4 g-0 me-4 mt-5">
        <Col xs={12} md={4} className="d-flex justify-content-between">
          {[TAB_MEMBER_LIST_SEARCH
            // , TAB_MEMBER_LIST_IDENTITY, TAB_MEMBER_LIST_ADVANCE
          ].map((tab, index) => (
            <div
              key={index}
              className={`tab-link ${activeTab === tab ? "active" : ""}`}
              onClick={() => handleTabClick(tab)}
              style={{
                cursor: "pointer",
                fontWeight: activeTab === tab ? "bold" : "normal",
                color: activeTab === tab ? "blue" : "black",
                paddingBottom: "5px",
                borderBottom: activeTab === tab ? "2px solid blue" : "none",
              }}
            >
              {TAB_MEMBER_LIST_SEARCH == tab ? (
                searchformemberm()
              ) : TAB_MEMBER_LIST_IDENTITY == tab ? (
                midentity()
              ) : TAB_MEMBER_LIST_ADVANCE == tab ? (
                mAdvance()
              ) : (<React.Fragment></React.Fragment>)}
            </div>
          ))}
        </Col>
      </Row>

      {/* Conditional Rendering of Components Based on Active Tab */}
      {activeTab === TAB_MEMBER_LIST_SEARCH && (
        <Row className="ps-4 g-0 me-4">
          <Col xs={12} md={{ span: 12 }}>
            <Card
              className="mt-4 mb-4 custom-shadow"
              style={{ margin: "0", padding: "0", width: "100%" }}
            >
              <Card.Body>
                <Row className="d-flex align-items-center">
                  <Col md={6}>
                    <h4 className="search-heading">{searchmember()}</h4>
                  </Col>
                  {/* <Col md={6} className="text-end">
                   {permissions.add && (
                     <Button
                       variant="success"
                       className="custom-success-btn me-2"
                       onClick={handleAddMember}
                     >
                       Add
                     </Button>
                   )}
                 </Col> */}
                </Row>


                <hr />

                <Row className="d-flex align-items-center mt-3">
                  <Col md={4}>
                    <Form.Select
                      aria-label="Member ID Select"
                      className="custom-select me-2"
                      style={{ height: "29px", padding: "0 8px" }}
                      value={searchOption}
                      onChange={handleSearchOptionChange}
                    >
                      <option value="All">All</option>
                      <option value="Name">Name</option>
                      <option value="Email">Email</option>
                      <option value="Member ID">Member ID</option>
                      <option value="Mobile">Mobile</option>
                      <option value="Card Ref">Card Ref</option>
                      <option value="Account ID">Account ID</option>
                    </Form.Select>
                  </Col>
                  {searchOption != "All" && (
                    <Col md={3} className="ps-1 ms-3">
                      <Form.Control
                        type="text"
                        className="custom-search-member me-2"
                        style={{ height: "29px", padding: "0 8px" }}
                        value={searchInput}
                        onChange={handleSearchInputChange}
                      />
                    </Col>
                  )}
                  <Col md={1} className="text-end">
                    <Button
                      variant="success"
                      className="custom-success-btn me-5"
                      onClick={handleSearch}
                    >
                      {usersearch()}
                    </Button>
                  </Col>
                </Row>

              </Card.Body>
            </Card>
          </Col>

          <Row className="mt-5">
            <Col xs={12}>
              <h4 className="member-result">{result()}</h4>
            </Col>
          </Row>
          <hr />

          <Row className=" me-3">
            <Col xs={12} md={{ span: 12 }}>
              <Table
                striped
                hover
                responsive
                className="centered-table"
                style={{ margin: "0", padding: "0", width: "100%" }}
              >
                <thead>
                  <tr>
                    <th className="custom-th">{membername()}</th>
                    <th className="custom-th">{joineddate()}</th>
                    <th className="custom-th">{mobile()}</th>
                    <th className="custom-th">{memberid()}</th>
                    <th className="custom-th">{cardref()}</th>
                    <th className="custom-th">Email</th>
                    <th className="custom-th">{app1st()}</th>
                    <th className="custom-th">{memberidentity()}</th>
                    <th className="custom-th">{func_t()}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentMembers.length > 0 ? (
                    currentMembers.map((member, index) => (
                      <tr key={index}>
                        <td className="custom-td">{member.account_name}</td>
                        <td className="custom-td">{formatDate(member.create_date, "date")}</td>
                        <td className="custom-td">{member.account_phone}</td>
                        <td className="custom-td">{member.member_id}</td>
                        <td className="custom-td">{member.card_ref}</td>
                        <td className="custom-td">{member.account_email}</td>
                        <td className="custom-td">{formatDate(member.first_login_date, "date")}</td>
                        <td className="custom-td">
                          {/* TODO peteryeh 待確認欄位 */}
                        </td>
                        <td className="custom-td">
                          {permissions.edit && (
                            <Button
                              variant="success"
                              className="custom-success-btn me-2"
                              onClick={() => handleEditMember(member)}
                            >
                              {mEdit()}
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        {nomemberfound()}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>



              <nav aria-label="Page navigation example" className="mt-5">
                <ul className="pagination justify-content-end">
                  {/* Previous Button */}
                  <li className={`page-item ${page === 0 ? "disabled" : ""}`}>
                    <a
                      className={`page-link ${page !== 0 ? "text-success" : ""}`}
                      href="#"
                      onClick={() => handlePaginationClick(page - 1)}
                      aria-disabled={page === 0}
                    >
                      {role_previous()}
                    </a>
                  </li>

                  {/* Page Numbers */}
                  {Array.from({ length: totalPages }, (_, idx) => (
                    <li
                      key={idx}
                      className={`page-item ${idx === page ? "active" : ""}`}
                    >
                      <a
                        className={`page-link ${idx === page ? "bg-success text-white" : "text-success"}`}
                        href="#"
                        onClick={() => handlePaginationClick(idx)}
                      >
                        {idx + 1}
                      </a>
                    </li>
                  ))}

                  {/* Next Button */}
                  <li className={`page-item ${page === totalPages - 1 ? "disabled" : ""}`}>
                    <a
                      className={`page-link ${page !== totalPages - 1 ? "text-success" : ""}`}
                      href="#"
                      onClick={() => handlePaginationClick(page + 1)}
                      aria-disabled={page === totalPages - 1}
                    >
                      {role_next()}
                    </a>
                  </li>
                </ul>
              </nav>

            </Col>
          </Row>

        </Row>
      )}
      {/* {activeTab === TAB_MEMBER_LIST_IDENTITY && <Identity />}
      {activeTab === TAB_MEMBER_LIST_ADVANCE && <Advance />} */}
    </Container>
  );
};

export default Member;

