// import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import { userReducer } from "./slices/userSlice";
// import { cartReducer } from "./slices/cartSlice";
// import { scrumReducer } from "./slices/scrumSlice";
// import { brandReducer } from "./slices/brandSlice";
// import { ratingReducer } from "./slices/ratingSlice";
// import { productReducer } from "./slices/productSlice";
// import { categoryReducer } from "./slices/categorySlice";
// import { notificationReducer } from "./slices/notificationSlice";
// import { persistReducer, persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import basicInfoReducer from './slices/basicInfoSlice';
// const appReducer = combineReducers({
//   cart: cartReducer,
//   users: userReducer,
//   brands: brandReducer,
//   ratings: ratingReducer,
//   scrumboard: scrumReducer,
//   products: productReducer,
//   categories: categoryReducer,
//   notifications: notificationReducer
// });

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const rootReducer = (state, action) => {
//   if (action.type === "user/logout") {
//     // Clear the persisted state on logout
//     state = undefined;
//   }
//   return appReducer(state, action);
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
//   devTools: process.env.NODE_ENV !== "production",
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [
//           "persist/PERSIST",
//           "persist/REHYDRATE",
//           "persist/PAUSE",
//           "persist/FLUSH",
//           "persist/PURGE",
//           "persist/REGISTER",
//         ],
//       },
//     }),
// });

// const persistor = persistStore(store);

// export { store, persistor };





import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./slices/userSlice";
import { cartReducer } from "./slices/cartSlice";
import { scrumReducer } from "./slices/scrumSlice";
import { brandReducer } from "./slices/brandSlice";
import { ratingReducer } from "./slices/ratingSlice";
import { productReducer } from "./slices/productSlice";
import { categoryReducer } from "./slices/categorySlice";
import { notificationReducer } from "./slices/notificationSlice";
// import { persistReducer, persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import { userReducers } from './slices/basicInfoSlice';  // Import the basicInfo reducer
import { rdxAuthReducer } from "./slices/rdxAuthSlice";
import { rdxLoadingReducer } from "./slices/rdxLoadingSlice";
import { rdxMainReducer } from "./slices/rdxMainSlice";
import { rdxMemberReducer } from "./slices/rdxMemberSlice";
import { rdxCampaignReducer } from "./slices/rdxCampaignSlice";

// Add basicInfoReducer to the combineReducers function
const appReducer = combineReducers({
  cart: cartReducer,
  users: userReducer,
  brands: brandReducer,
  ratings: ratingReducer,
  scrumboard: scrumReducer,
  products: productReducer,
  categories: categoryReducer,
  notifications: notificationReducer,
  basicinfo: userReducers, // Add basicInfo here
  rdxAuth: rdxAuthReducer,
  rdxLoading: rdxLoadingReducer,
  rdxMain: rdxMainReducer,
  rdxMember: rdxMemberReducer,
  rdxCampaign: rdxCampaignReducer
});

// const persistConfig = {
//   key: "root",
//   storage,
// };

const rootReducer = (state, action) => {
  if (action.type === "user/logout") {
    // Clear the persisted state on logout
    state = undefined;
  }
  return appReducer(state, action);
};

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  // reducer: persistedReducer,
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/PAUSE",
          "persist/FLUSH",
          "persist/PURGE",
          "persist/REGISTER",
        ],
      },
    }),
});

// const persistor = persistStore(store);
// export { store, persistor };

export { store };
