import { clearToken, getadmininfo, initToken, revokeToken } from "app/redux/slices/rdxAuthSlice";
import { setPagePermissions, updateNavViewData } from "app/redux/slices/rdxMainSlice";
import useTranslations from "app/views/transalation/TranslationUtils";
import React, { createContext } from "react";
import { useEffect } from "react";
import { useReducer } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { navigations } from "app/navigations";
import { useLanguage } from "./LanguageContext";

const initialState = {};


const reducer = (state, action) => { };

const RdxAuthContext = createContext({
    ...initialState
});

//use as global context, only use redux state hook 
export const RdxAuthProvider = ({ children }) => {
    const [state] = useReducer(reducer, initialState);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { translate } = useTranslations();
    const { selectedLanguage } = useLanguage();
    const hasToken = useSelector((state) => state.rdxAuth?.hasToken || false);
    const tokenFailed = useSelector((state) => state.rdxAuth?.tokenFailed || false);
    const adminInited = useSelector((state) => state.rdxAuth?.adminInited || false);
    const permissions = useSelector((state) => state.rdxAuth?.permissions || []);


    useEffect(() => {
        if (window.location.href.includes("/session/signin")) {
            //do not check token in login page 
        } else {
            // console.log("check token");
            let tkn = localStorage.getItem("authToken");
            if (tkn) {
                dispatch(initToken(tkn));
            } else {
                dispatch(revokeToken());
            }
        }
    }, []);

    useEffect(() => {
        if (hasToken) {
            dispatch(getadmininfo());
        }
    }, [hasToken]);

    useEffect(() => {
        if (tokenFailed) {
            dispatch(clearToken());
            navigate("/session/signin");
        }
    }, [tokenFailed]);

    useEffect(() => {
        // console.log("language chageed:", selectedLanguage);
        if (adminInited) {
            setNavData();
        }
    }, [selectedLanguage]);

    useEffect(() => {
        if (permissions && adminInited) {
            // console.log("permission changed", permissions);
            setNavData();
            refreshPagePermissions();
        }
    }, [permissions]);

    const setNavData = async () => {
        const filteredNavItems = navigations
            .filter(navItem => permissions.includes(navItem.permission))
            .map(navItem => ({
                ...navItem,
                name: translate(navItem.name.zh, navItem.name.en), // Translate name
            }));

        // Always include "Home" as the first item
        filteredNavItems.unshift({
            name: translate("首頁 - 儀表板", "Home"), // Translate Home dynamically
            icon: "home",
            path: "/dashboard/default",
            permission: "home"
        });

        dispatch(updateNavViewData(filteredNavItems));
    };

    const refreshPagePermissions = async () => {
        dispatch(setPagePermissions(permissions));
    }

    return (
        <RdxAuthContext.Provider value={{ ...state }}>
            {children}
        </RdxAuthContext.Provider>
    );
};

export default RdxAuthContext;
