import React, { useState, useEffect } from 'react';
import { Button, Table, Alert } from 'react-bootstrap';
import RoleManagementNew from './RoleManagementNew';
import './rolemanagemntcss/RoleManagement.css';
import axios from 'axios';
import { useLanguage } from 'app/contexts/LanguageContext';
import useTranslations from '../transalation/TranslationUtils';
const RoleManagement = () => {
  const [roles, setRoles] = useState(null); // Initialize as null to differentiate loading state
  const [permissions, setPermissions] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Add Role');
  const [selectedRole, setSelectedRole] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [deleteError, setDeleteError] = useState("");

  const fetchData = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    try {

      const rolesRequest = axios.get("https://membershipuat.maxisense.io/webapp/memportal/getrolelist", {
        headers: { Token: token, "Content-Type": "application/json" },
      });

      const permissionsRequest = axios.get("https://membershipuat.maxisense.io/webapp/memportal/getadmininfo", {
        headers: { Token: token, "Content-Type": "application/json" },
      });

      const [rolesResponse, permissionsResponse] = await Promise.all([rolesRequest, permissionsRequest]);
      const endTime = Date.now();

 
 

      const rolesData = Array.isArray(rolesResponse.data.data) ? rolesResponse.data.data : [];
      setRoles(rolesData);

      if (permissionsResponse.data.code === 1) {
        const permissionsList = permissionsResponse.data.data.permission_list || [];
        setPermissions({
          add: permissionsList.includes("role"),
          edit: permissionsList.includes("role_u"),
          delete: permissionsList.includes("role_d"),
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setRoles([]); // Set to empty array on error to show "No roles available"
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on mount

    const intervalId = setInterval(() => {
      fetchData(); // Refresh data every 3 seconds
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const handleShow = () => {
    setSelectedRole(null);
    setModalTitle('Add Role');
    setShowModal(true);
  };

  const handleEdit = (role) => {
    setSelectedRole(role);
    setModalTitle("Edit Role");
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const handleDelete = async (role) => {
    const confirmed = window.confirm(`Are you sure you want to delete the role ${role.role_name}?`);
    if (!confirmed) return;

    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    try {
      const payload = { role_id: role.role_id };
      const response = await axios.post(
        "https://membershipuat.maxisense.io/webapp/memportal/deleterole",
        payload,
        {
          headers: { "Content-Type": "application/json", Token: token },
        }
      );

      if (response.data?.code === 1) {
        setDeleteError("");
        setShowSuccessPopup(true);
        fetchData();
        setTimeout(() => setShowSuccessPopup(false), 3000);
      } else {
        const errorMsg = response.data?.message_en || "The role cannot be deleted as it is currently in use";
        setDeleteError(errorMsg);
        setTimeout(() => setDeleteError(""), 3000);
      }
    } catch (error) {
      const errorMsg = error.response?.data?.message_en || error.message || "An error occurred.";
      setDeleteError(errorMsg);
      setTimeout(() => setDeleteError(""), 3000);
    }
  };

  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = roles ? Math.ceil(roles.length / rowsPerPage) : 0;
  const paginatedRoles = roles
    ? roles.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
    : [];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const { selectedLanguage } = useLanguage();
  const { rolemanage, role_t, func_t, role_edit, role_Add, role_delete, role_next, role_previous } = useTranslations();

  const getRoleName = (item) => {
    return selectedLanguage === 'zh'
      ? item.role_name
      : item.role_name_en;
  };




  return (
    <div className="container-fluid pt-5 ps-3 pe-3">
      <div className="row mb-3">
        <div className="col-10 d-flex align-items-center">
          <h4 className="firstname ">{rolemanage()}</h4>
        </div>
        <div className="col-2 d-flex justify-content-end">
          {permissions.add && (
            <Button className="custom-success-btn me-3" variant="success" onClick={handleShow}>
              {role_Add()}
            </Button>
          )}
        </div>
      </div>

      {showSuccessPopup && (
        <Alert variant="success" className="mt-3">
          Role deleted successfully!
        </Alert>
      )}

      {deleteError && (
        <Alert variant="danger" className="mt-3">
          {deleteError}
        </Alert>
      )}

      {roles === null ? ( // Show nothing while data is being fetched
        <div className="text-center"></div>
      ) : roles.length === 0 ? ( // Show "No roles available" only when roles is empty
        <div className="text-center">No roles available.</div>
      ) : (
        <>
          <Table className="role-management-table">
            <thead>
              <tr className="tabaleh">
                <th className="tabaleh" style={{ width: '78%' }}>{role_t()}</th>
                <th className="tabaleh text-end pe-5" style={{ width: '22.5%' }}>{func_t()}</th> {/* Add text-center class */}
              </tr>
            </thead>
            <tbody>
              {paginatedRoles.map((role) => (
                <tr key={role.role_id}>
                  <td className="role align-middle">
                    {/* {role.role_name_en} */}

                    {getRoleName(role)}
                  </td>
                  <td>
                    <div className="button-group d-flex justify-content-end align-items-end">
                      {permissions.edit && (
                        <Button className="custom-success-btn me-2" onClick={() => handleEdit(role)}>
                          {role_edit()}
                        </Button>
                      )}
                      {permissions.delete && (
                        <Button className="custom-danger-btn " onClick={() => handleDelete(role)}>
                          {role_delete()}
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <nav aria-label="Page navigation example" className="mt-5">
            <ul className="pagination justify-content-end">
              {/* Previous Button */}
              <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                <button
                  className={`page-link ${currentPage !== 1 ? "text-success" : ""}`}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {role_previous()}
                </button>
              </li>

              {/* Page Numbers */}
              {[...Array(totalPages).keys()].map((page) => (
                <li
                  key={page}
                  className={`page-item ${currentPage === page + 1 ? "active" : ""}`}
                >
                  <button
                    className={`page-link ${currentPage === page + 1 ? "bg-success text-white" : "text-success"}`}
                    onClick={() => handlePageChange(page + 1)}
                  >
                    {page + 1}
                  </button>
                </li>
              ))}

              {/* Next Button */}
              <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                <button
                  className={`page-link ${currentPage !== totalPages ? "text-success" : ""}`}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  {role_next()}
                </button>
              </li>
            </ul>
          </nav>
        </>
      )}

      <RoleManagementNew
        show={showModal}
        handleClose={handleClose}
        modalTitle={modalTitle}
        roleData={selectedRole}
      />
    </div>
  );
};

export default RoleManagement;

