import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";
import Loadable from "app/components/Loadable";

const Sales = Loadable(lazy(() => import("./sales/Sales")));
const Analytics = Loadable(lazy(() => import("./analytics-1")));
const Analytics2 = Loadable(lazy(() => import("./analytics-2")));
const Analytics3 = Loadable(lazy(() => import("./analytics-3")));
const Analytics4 = Loadable(lazy(() => import("./analytics-4")));
const LearningManagement = Loadable(lazy(() => import("./learning-management")));
const InventoryManagement = Loadable(lazy(() => import("./inventory-management")));

const dashboardRoutes = [
  { path: "/dashboard/default", element: <Analytics2 />, auth: authRoles.admin },
  { path: "/dashboard/analytics", element: <Analytics3 /> },
  { path: "/dashboard/analytics-2", element: <Analytics4 /> },
  { path: "/dashboard/alternative", element: <Analytics /> },
  { path: "/dashboard/inventory-management", element: <InventoryManagement /> },
  { path: "/dashboard/learning-management", element: <LearningManagement /> },
  { path: "/dashboard/sales", element: <Sales /> }
];

export default dashboardRoutes;
