

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Card, Button, CardBody, Modal, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Add } from '@mui/icons-material';
import { IoNotificationsOutline } from 'react-icons/io5';
import './campaigncss/Instructor.css';
import axios from "axios";
import useTranslations from '../transalation/TranslationUtils';
function Instructor({ campaignDetails }) {
    const [showInstructor, setShowInstructor] = useState(false);
    const [instructors, setInstructors] = useState([]); // Start with an empty list
    const [newInstructor, setNewInstructor] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [formData, setFormData] = useState({
        setinstructorname: "",
    });
    // Open modal
    // const handleShowInstructor = () => setShowInstructor(true);

    const handleShowInstructor = () => {
        fetchInstructorList(); // Fetch available instructors from API
        setShowInstructor(true);
    };
    // Close modal
    const handleCloseInstructor = () => {
        setShowInstructor(false);
        setNewInstructor(''); // Clear input after closing the modal
        setErrorMessage(''); // Clear any error message when closing the modal
    };

  
    const { cancel, save, pushstatus, assignTo,instructor, addinstructor} = useTranslations();




    const [showBasicModal, setShowBasicModal] = useState(false);
    const handleshowBasicModal = () => setShowBasicModal(true);
    const handlecloseBasicModal = () => setShowBasicModal(false);

    // Remove instructor from the list
    const handleRemoveInstructor = (instructorName) => {
        setInstructors(instructors.filter(instructor => instructor.name !== instructorName));
    };
    const [availableInstructors, setAvailableInstructors] = useState([]);
    const fetchInstructorList = async () => {
        try {
            const token = localStorage.getItem("authToken"); // Get token from localStorage
            const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getinstrcutorlist", {
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
            });
            console.log("instructor", response.data)
            if (response.data.code === 1) {
                setAvailableInstructors(response.data.data || []); // Set available instructors from API
            } else {
                console.error("API responded with an error:", response.data.message_en);
            }
        } catch (error) {
            console.error("Error fetching instructor list:", error);
        }
    };

    useEffect(() => {
        // Fetch the available instructor list when the component mounts
        fetchInstructorList();

        if (campaignDetails) {
            console.log("Campaign Details:", campaignDetails);
            setFormData({
                setinstructorname: campaignDetails.campaign_tutors || "",
            });
        }
    }, [campaignDetails]); // Runs once when campaignDetails is updated


    useEffect(() => {
        if (availableInstructors.length > 0 && campaignDetails && Array.isArray(campaignDetails.campaign_tutors)) {
            // Map the account_id to account_name using availableInstructors
            const updatedInstructors = campaignDetails.campaign_tutors.map((instructorId) => {
                const foundInstructor = availableInstructors.find(
                    (availableInstructor) => availableInstructor.account_id === instructorId
                );
                return {
                    id: instructorId,
                    name: foundInstructor ? foundInstructor.account_name : "Unknown Instructor",
                };
            });

            setInstructors(updatedInstructors);
        }
    }, [availableInstructors, campaignDetails]);


    const handleSaveInstructor = async () => {
        if (newInstructor) {
            // Check if the instructor is already added
            const existingInstructor = instructors.find(instructor => instructor.name === newInstructor);
            if (existingInstructor) {
                setErrorMessage('Instructor already present.');
            } else {
                setInstructors((prevInstructors) => [...prevInstructors, { name: newInstructor }]);
                setNewInstructor('');
                handleCloseInstructor();
            }
        }

        // Prepare the payload with the updated instructor list
        const payload = {
            campaign_id: campaignDetails.campaign_id,  // Use campaign_id from campaignDetails
            campaign_tutors: instructors.map(instructor =>
                availableInstructors.find(avail => avail.account_name === instructor.name)?.account_id
            ).filter(Boolean), // Map names to IDs and remove any null/undefined values
        };

        try {
            const token = localStorage.getItem("authToken");  // Get token from localStorage
            const response = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/updateinstructor", payload, {
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
            });

            if (response.data.code === 1) {
                alert("Instructors updated successfully!");
                console.log("Instructor list updated successfully.");
            } else {
                alert("Failed to update instructors.");
                console.error("Error updating instructors:", response.data.message_en);
            }
        } catch (error) {
            console.error("Error during API call:", error);
            alert("An error occurred while saving data.");
        }
    };



    return (
        <Container fluid>
            <Row>
                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Card.Body>
                        <Form>
                            <Row className="align-items-center mb-3">
                                <Col md={6} className="d-flex align-items-center">
                                    <h5 className="basicheading">{instructor()}</h5>
                                </Col>
                                <Col md={6} className="text-end">
                                    {/* <Button variant="secondary" className="basiccancelbtnn me-2">{cancel()}</Button> */}
                                    <Button variant="primary" className="basicsavebtn me-3" onClick={handleSaveInstructor}>{save()}</Button>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col md={12}>
                                    <Card className="basicinfocard">
                                        <CardBody className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
                                                <span className="basicstatus">{pushstatus()}: Draft</span>
                                            </div>
                                            <Button className="basicsavebtn " style={{ width: '110px' }} onClick={handleshowBasicModal}>{assignTo()}</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row> */}

                            {/* Error message */}


                            {instructors.map((instructor, index) => (
                                <Row key={index} className="mt-3">
                                    <Col md={4}>
                                        <Form.Group controlId={`inquiry-${index}`} className="floating-label-group">
                                            <Form.Select
                                                name="inquiry"
                                                className="floating-input"
                                                value={instructor.name || instructor} // Show name instead of id
                                                readOnly
                                            >
                                                <option value={instructor.name || instructor}>
                                                    {instructor.name || instructor}
                                                </option>
                                            </Form.Select>
                                            <Form.Label className="floating-label">Name</Form.Label>
                                        </Form.Group>
                                    </Col>

                                    <Col md={1} className="d-flex justify-content-center align-items-center">
                                        <Button
                                            style={{
                                                backgroundColor: '#FF4694',
                                                border: 'none',
                                                height: '24px',
                                                width: '24px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '0', // Ensures no extra space around the text
                                            }}
                                            onClick={() => handleRemoveInstructor(instructor.name || instructor)} // Handle removal
                                        >
                                            --
                                        </Button>
                                    </Col>
                                </Row>
                            ))}
                            {/* Add Button */}
                            <Row className="mt-3">
                                <Col md={1}>
                                    <Button
                                        style={{
                                            backgroundColor: '#1976D2',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            padding: '0'
                                        }}
                                        onClick={handleShowInstructor}
                                    >
                                        <Add style={{ color: '#fff', fontSize: '16px' }} />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Row>

            <Modal
                show={showBasicModal}
                onHide={handlecloseBasicModal}
                centered
                className="custom-modal-width"
            >
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">
                            {assignTo()}
                        </h4>

                        <Form.Group controlId="formStatus" className="mt-3 ms-3">

                            <Form.Check
                                type="checkbox"
                                label="Ling (Manager)"
                                name="status"
                                value="pre-approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Rainbow Lee (Campaign Organizer)"
                                name="status"
                                value="approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Alex Law (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Chan Siu Ming (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant=""
                        onClick={handlecloseBasicModal}
                        className="me-2 groupclear"
                    >
                        {cancel()}
                    </Button>
                    <Button
                        variant=""
                        className="groupsearch"
                        onClick={handlecloseBasicModal}
                    >
                        {save()}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for Adding Instructor */}
            <Modal show={showInstructor} onHide={handleCloseInstructor} centered className="custom-modal-width">
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">{addinstructor()}</h4>
                        {errorMessage && (
                            <Alert variant="danger">
                                {errorMessage}
                            </Alert>
                        )}
                        <Form.Group controlId="formStatus" className="mt-3 ms-3">

                            {availableInstructors.map((instructor, index) => (
                                <Form.Check
                                    key={index}
                                    type="radio"
                                    label={instructor.account_name}
                                    value={instructor.account_name}
                                    name="status"
                                    // value={instructorData.instructoName}
                                    onChange={(e) => setNewInstructor(e.target.value)}
                                    className="assigntocheck mb-3"
                                />
                            ))}
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="" onClick={handleCloseInstructor} className="me-2 groupclear">{cancel()}</Button>
                    <Button variant="" className="groupsearch" onClick={handleSaveInstructor}>{save()}</Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
}

export default Instructor;











