


import React, { useState } from "react";
import { Button, Form, Row, Col, Table } from "react-bootstrap";
import './reportcss/Report.css'
import useTranslations from '../transalation/TranslationUtils';
const Report = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [formVisible, setFormVisible] = useState(false);

  const handleSelectionChange = (e) => {
    setSelectedOption(e.target.value);
  };


  const handleShowForm = () => {
    setFormVisible(true); // Show the form when the "Search" button is clicked
  };


  const { usersearch, reporttype, year } = useTranslations();

  return (
    <div className="container-fluid mt-4">
      <Form className="p-3 m-3 border rounded shadow-custom" >
        <Row>
          <Col md={8}>
            <Form.Group controlId="reportTypeForm" className="d-flex align-items-center" >
              <Form.Label className="me-2 userlabel mb-0">{reporttype()}:</Form.Label>
              <Form.Select
                value={selectedOption}
                onChange={handleSelectionChange}
                className="custom-select me-2 ms-1 reportinputselect"
                style={{ width: '70%', height: '30px' }}
              >
                <option value=""></option>
                <option value="ytnec">Program Attendance Report</option>
                {/* <option value="outside">Average attendance per session within one year-Outside</option> */}
                {/* <option value="totalGroups">Total number of groups, activities and programs held within one year</option> */}
                {/* <option value="oneTimeActivity">One-time activity attendance record sheet</option> */}
                {/* <option value="continuousEvent">Continuous event attendance record sheet</option> */}
                <option value="quarterlyAttendance">Quarterly Attendance Summary for SWD</option>
                <option value="campaignRating">Campaign Overall Rating Report</option>
                <option value="individualRating">Quarterly Program Summary report for SWD</option>
                <option value="individualActivity">Annual Performance Report</option>
              </Form.Select>
              <Button className="ms-5 reportserchbtn" onClick={handleShowForm}>{usersearch()}</Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>


      {/* Dynamically Render Forms based on Selected Option */}
      {selectedOption === "ytnec" && <YTNECForm />}
      {selectedOption === "outside" && <OutsideForm />}
      {selectedOption === "totalGroups" && <TotalGroupsForm />}
      {selectedOption === "oneTimeActivity" && <OneTimeActivityForm />}
      {selectedOption === "continuousEvent" && <ContinuousEventForm />}
      {selectedOption === "campaignRating" && <CampaignRatingForm />}
      {selectedOption === "individualRating" && <IndividualRatingForm />}
      {selectedOption === "individualActivity" && <IndividualActivityForm />}
      {selectedOption === "quarterlyAttendance" && <QuarterlyAttendanceForm />}

    </div>
  );
};

// Example table structure for multiple forms
const ExampleTable = ({ columns, rows }) => (
  <Table striped hover className="ms-3 mt-4">
    <thead>
      <tr>
        {columns.map((col, index) => (
          <th key={index} className="custom-th">{col}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map((row, index) => (
        <tr key={index}>
          {Object.keys(row).map((key, idx) => (
            <td key={idx} className="custom-td">{row[key]}</td>
          ))}
          <td>
            <Button variant="success" className="me-2 customnotificationbtn">Download</Button>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

// Table Rows (You can replace this with dynamic data from APIs or form logic)
const ytnecRows = [
  { reportName: "Report 1", date: "15/01/2024 to 20/01/2024" },
  { reportName: "Report 2", date: "20/01/2024to25/01/2024" }
];

// YTNEC Form and Table
const YTNECForm = () => {
  const [showTable, setShowTable] = useState(false);

  const handleSearch = () => {
    setShowTable(true);
  };

  const { usersearch, pushreset, date, to } = useTranslations();


  return (
    <div>
      <Form className="p-3 m-3 border rounded shadow-custom">
        <Row>
          <Col md={3}>
            <Form.Group controlId="ytnecDate" className="d-flex align-items-center mb-2">
              <Form.Label className="me-2 mb-0 userlabel">{date()}:</Form.Label>
              <Form.Control type="date" className="flex-grow-1 reportinputselect" style={{ height: '30px' }} />
            </Form.Group>
          </Col>
          <Col md={3} >
            <Form.Group controlId="ytnecTo" className="d-flex align-items-center mb-2">
              <Form.Label className="me-2 mb-0  userlabel" >{to()}:</Form.Label>
              <Form.Control type="date" className="flex-grow-1 ms-4 reportinputselect" style={{ height: '30px' }} />
            </Form.Group>
          </Col>
        </Row>
        <Button className="mt-3 me-2 ms-2 reportserchbtn" variant="primary" onClick={handleSearch}>{usersearch()}</Button>
        <Button className="mt-3 reportserchbtn" variant="secondary">{pushreset()}</Button>
      </Form>


      {showTable && (
        <ExampleTable
          columns={['Report Name', 'Date', 'Functions']}
          rows={ytnecRows}
        />
      )}
    </div>
  );
};

// Outside Form and Table (Same as YTNEC, but separate logic can be added)
const outsideRows = [
  { reportName: "Outside Report 1", date: "12/01/2024" }
];

const OutsideForm = () => {
  const [showTable, setShowTable] = useState(false);

  const handleSearch = () => {
    setShowTable(true);
  };

  const { usersearch, pushreset, date, to } = useTranslations();
  return (
    <div>
      <Form className="p-3 m-3 border rounded shadow-custom">
        <Row>
          <Col md={3}>
            <Form.Group controlId="outsideDate" className="d-flex align-items-center mb-2">
              <Form.Label className="me-2 mb-0 userlabel" >{date()}:</Form.Label>
              <Form.Control type="date" className="flex-grow-1 reportinputselect" style={{ height: '30px' }} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="outsideTo" className="d-flex align-items-center mb-2">
              <Form.Label className="me-2 mb-0  userlabel">{to()}:</Form.Label>
              <Form.Control type="date" className="flex-grow-1 ms-4 reportinputselect" style={{ height: '30px' }} />
            </Form.Group>
          </Col>
        </Row>
        <Button className="mt-3 me-2 ms-2 reportserchbtn" variant="primary" onClick={handleSearch}>{usersearch()}</Button>
        <Button className="mt-3 reportserchbtn" variant="secondary">{pushreset()}</Button>
      </Form>

      {showTable && (
        <ExampleTable
          columns={['Report Name', 'Date', 'Functions']}
          rows={outsideRows}
        />
      )}
    </div>
  );
};


// Form for Total Groups
const TotalGroupsForm = () => {
  const { usersearch, pushreset, date, to, year, quater } = useTranslations();
  return (<div>
    <Form className="p-3 m-3 border rounded shadow-custom">
      <Row>
        <Col md={2}>
          <Form.Group controlId="totalGroupsYear" className="d-flex align-items-center mb-2">
            <Form.Label className="me-2 mb-0 userlabel">{year()}:</Form.Label>
            <Form.Select className="flex-grow-1 reportinputselect" style={{ height: '30px' }}>
              <option></option>
              <option>2023</option>
              <option>2024</option>

            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group controlId="totalGroupsQuarter" className="d-flex align-items-center mb-2" >
            <Form.Label className="me-2 mb-0 ms- userlabel">{quater()}:</Form.Label>
            <Form.Select className="flex-grow-1 reportinputselect" style={{ height: '30px' }} >
              <option></option>
              <option>1-3</option>
              <option>4-6</option>
              <option>7-9</option>
              <option>10-12</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Button className="mt-3 me-2 ms-2 reportserchbtn" variant="primary">{usersearch()}</Button>
      <Button className="mt-3 reportserchbtn" variant="secondary">{pushreset()}</Button>
    </Form>
  </div>)
};



// Form for One-time Activity
const OneTimeActivityForm = () => {
  const { usersearch, pushreset, campaignname, code } = useTranslations();

  return (
    <Form className="p-3 m-3 border rounded shadow-custom">
      <Row>
        <Col md={4}>
          <Form.Group controlId="oneTimeActivityName" className="mb-2">
            <div className="d-flex align-items-center">
              <Form.Label className="me-2 mb-0 userlabel ms-2">{campaignname()}:</Form.Label>
              <Form.Control type="text" className="flex-grow-1 ms- reportinputselect" style={{ height: '30px' }} />
            </div>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group controlId="oneTimeActivityCode" className="mb-2">
            <div className="d-flex align-items-center">
              <Form.Label className="me-2 mb-0 ms-4 userlabel">{code()}:</Form.Label>
              <Form.Control type="text" className="flex-grow-1 reportinputselect" style={{ height: '30px' }} />
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Button className="mt-3 me-2 ms-2 reportserchbtn" variant="primary">{usersearch()}</Button>
      <Button className="mt-3 reportserchbtn" variant="secondary">{pushreset()}</Button>
    </Form>
  )
}
  ;



// Continuous Event Form and Table (Similar to YTNEC but different columns)
const continuousEventRows = [
  { campaignName: "Campaign 1", type: "Type 1", code: "Code 1", sis: "SIS 1", updatedBy: "Amy Lee", startDate: "15/01/2024", approvedBy: "Ling", lastUpdateApprove: "15/01/2024" }
];

const ContinuousEventForm = () => {
  const [showTable, setShowTable] = useState(false);

  const handleSearch = () => {
    setShowTable(true);
  };
  const { usersearch, pushreset, campaignname, code } = useTranslations();

  return (
    <div>
      <Form className="p-3 m-3 border rounded shadow-custom">
        <Row>
          <Col md={4}>
            <Form.Group controlId="campaignName" className="mb-2">
              <div className="d-flex align-item-center">
                <Form.Label className="me-2 mb-0 userlabel ms-2">{campaignname()}:</Form.Label>
                <Form.Control type="text" className="flex-grow-1 ms- reportinputselect" style={{ height: '30px' }} />
              </div>
            </Form.Group>
          </Col>
          <Col md={2}>
            <div className="d-flex align-items-center">
              <Form.Label className="me-2 mb-0 ms-4 userlabel">{code()}:</Form.Label>
              <Form.Control type="text" className="flex-grow-1 reportinputselect" style={{ height: '30px' }} />
            </div>
          </Col>
        </Row>
        <Button className="mt-3 me-2 ms-2 reportserchbtn" variant="primary" onClick={handleSearch}>{usersearch()}</Button>
        <Button className="mt-3 reportserchbtn" variant="secondary">{pushreset()}</Button>
      </Form>

      {showTable && (
        <ExampleTable
          columns={['Campaign Name', 'Type', 'Code', 'SIS', 'Updated By', 'Start Date', 'Approved By', 'Last Update/Approve', 'Functions']}
          rows={continuousEventRows}
        />
      )}
    </div>
  );
};



const QuarterlyAttendanceForm = () => {
  const { usersearch, pushreset, date, to, year, quater } = useTranslations();
  return (
    <div>
      <Form className="p-3 m-3 border rounded shadow-custom">
        <Row>
          <Col md={4}>
            <Form.Group controlId="totalGroupsYear" className="d-flex align-items-center mb-2">
              <Form.Label className="me-2 mb-0 userlabel">{year()}:</Form.Label>
              <Form.Select className="flex-grow-1 reportinputselect" style={{ height: '30px' }}>
                <option></option>
                <option>2023</option>
                <option>2024</option>

              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="totalGroupsQuarter" className="d-flex align-items-center mb-2" >
              <Form.Label className="me-2 mb-0 ms- userlabel">{quater()}:</Form.Label>
              <Form.Select className="flex-grow-1 reportinputselect" style={{ height: '30px' }} >
                <option></option>
                <option>1-3</option>
                <option>4-6</option>
                <option>7-9</option>
                <option>10-12</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Button className="mt-3 me-2 ms-2 reportserchbtn" variant="primary">{usersearch()}</Button>
        <Button className="mt-3 reportserchbtn" variant="secondary">{pushreset()}</Button>
      </Form>
    </div>
  )
};




const CampaignRatingForm = () => {
  const [showTable, setShowTable] = useState(false);

  // Sample data for CampaignRatingForm
  const campaignRatingRows = [
    { reportName: "Campaign Rating Report 1", date: "10/01/2024" },
    { reportName: "Campaign Rating Report 2", date: "15/01/2024" },
  ];

  const handleSearch = () => {
    setShowTable(true);
  };

  const { usersearch, pushreset, date, to, year, quater } = useTranslations();

  return (
    <div>
      {/* Campaign Rating Form */}
      <Form className="p-3 m-3 border rounded shadow-custom">
        <Row>
          <Col md={3}>
            <Form.Group controlId="campaignRatingStartDate" className="d-flex align-items-center mb-2">
              <Form.Label className="me-2 mb-0 userlabel">{date()}:</Form.Label>
              <Form.Control type="date" className="flex-grow-1 reportinputselect" style={{ height: '30px' }} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="campaignRatingEndDate" className="d-flex align-items-center mb-2">
              <Form.Label className="me-2 mb-0  userlabel">{to()}:</Form.Label>
              <Form.Control type="date" className="flex-grow-1 ms-4 reportinputselect" style={{ height: '30px' }} />
            </Form.Group>
          </Col>
        </Row>
        <Button className="mt-3 me-2 ms-2 reportserchbtn" variant="primary" onClick={handleSearch}>
          {usersearch()}
        </Button>
        <Button className="mt-3 reportserchbtn" variant="secondary">
          {pushreset()}
        </Button>
      </Form>

      {/* Campaign Rating Report Table */}
      {showTable && (
        <Table striped hover className="ms-3 mt-5">
          <thead>
            <tr>
              <th className="custom-th">Report Name</th>
              <th className="custom-th">Date</th>
              <th className="custom-th">Functions</th>
            </tr>
          </thead>
          <tbody>
            {campaignRatingRows.map((row, index) => (
              <tr key={index}>
                <td className="custom-td">{row.reportName}</td>
                <td className="custom-td">{row.date}</td>
                <td>
                  <Button variant="success" className="me-2 customnotificationbtn">
                    Download
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};


const IndividualRatingForm = () => {
  const [showTable, setShowTable] = useState(false);

  // Sample data for IndividualRatingForm
  const individualRatingRows = [
    {
      campaignName: "Campaign 1",
      type: "Type A",
      code: "12345",
      sis: "SIS123",
      updatedBy: "John Doe",
      startDate: "01/01/2024",
      approvedBy: "Jane Doe",
      lastUpdateApprove: "10/01/2024",
    },
    {
      campaignName: "Campaign 2",
      type: "Type B",
      code: "67890",
      sis: "SIS456",
      updatedBy: "Alice",
      startDate: "05/01/2024",
      approvedBy: "Bob",
      lastUpdateApprove: "15/01/2024",
    },
  ];

  const handleSearch = () => {
    setShowTable(true);
  };

  const { usersearch, pushreset, campaignname, code, } = useTranslations();

  return (
    <div>
      {/* Individual Rating Form */}
      <Form className="p-3 m-3 border rounded shadow-custom">
        <Row>
          <Col md={4}>
            <Form.Group controlId="individualRatingStartDate" className="mb-2">
              <div className="d-flex align-item-center">
                <Form.Label className="me-2 mb-0 userlabel ms-2">{campaignname()}:</Form.Label>
                <Form.Control type="text" className="flex-grow-1 ms- reportinputselect" style={{ height: '30px' }} />
              </div>
            </Form.Group>
          </Col>
          <Col md={4}>
            <div className="d-flex align-items-center">
              <Form.Label className="me-2 mb-0 ms-4 userlabel">{code()}:</Form.Label>
              <Form.Control type="text" className="flex-grow-1 reportinputselect" style={{ height: '30px' }} />
            </div>
          </Col>
        </Row>
        <Button className="mt-3 me-2 ms-2 reportserchbtn" variant="primary" onClick={handleSearch}>
          {usersearch()}
        </Button>
        <Button className="mt-3 reportserchbtn" variant="secondary">
          {pushreset()}
        </Button>
      </Form>

      {/* Individual Rating Report Table */}
      {showTable && (
        <Table striped hover className="ms-3 mt-5">
          <thead>
            <tr>
              <th className="custom-th">Campaign Name</th>
              <th className="custom-th">Type</th>
              <th className="custom-th">Code</th>
              <th className="custom-th">SIS</th>
              <th className="custom-th">Updated By</th>
              <th className="custom-th">Start Date</th>
              <th className="custom-th">Approved By</th>
              <th className="custom-th">Last Update/Approve</th>
              <th className="custom-th">Functions</th>
            </tr>
          </thead>
          <tbody>
            {individualRatingRows.map((row, index) => (
              <tr key={index}>
                <td className="custom-td">{row.campaignName}</td>
                <td className="custom-td">{row.type}</td>
                <td className="custom-td">{row.code}</td>
                <td className="custom-td">{row.sis}</td>
                <td className="custom-td">{row.updatedBy}</td>
                <td className="custom-td">{row.startDate}</td>
                <td className="custom-td">{row.approvedBy}</td>
                <td className="custom-td">{row.lastUpdateApprove}</td>
                <td>
                  <Button variant="success" className="me-2 customnotificationbtn">
                    Download
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};



const IndividualActivityForm = () => {
  const [showTable, setShowTable] = useState(false);

  // Sample data for IndividualActivityForm
  const individualActivityRows = [
    {
      campaignName: "Campaign 1",
      startDate: "01/01/2024",
      endDate: "10/01/2024",
      status: "Completed",
      attendance: '100%',
    },
    {
      campaignName: "Campaign 2",
      startDate: "05/01/2024",
      endDate: "15/01/2024",
      status: "In Progress",
      attendance: '80%',
    },
  ];

  const handleSearch = () => {
    setShowTable(true);
  };


  const { usersearch, pushreset, campaignname, code, memberid, period } = useTranslations();
  return (
    <div>
      {/* Individual Member Activity Form */}
      <Form className="p-3 m-3 border rounded shadow-custom">
        <Row>
          <Col md={4}>
            <Form.Group controlId="memberId" className="d-flex align-items-center mb-2">
              <Form.Label className="me-2 mb-0 userlabel">{memberid()}</Form.Label>
              <Form.Control type="text" className="flex-grow-1 reportinputselect" style={{ height: '30px' }} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="periodSelect" className="d-flex align-items-center mb-2">
              <Form.Label className="me-2 mb-0 ms-5 userlabel">{period()}</Form.Label>
              <Form.Select className="flex-grow-1 ms-4 reportinputselect" style={{ height: '30px' }}>
                <option value="12months"></option>
                <option value="12months">Past 12 months</option>
                <option value="24months">Past 24 months</option>
                <option value="36months">Past 36 months</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Button className="mt-3 me-2 ms-2 reportserchbtn" variant="primary" onClick={handleSearch}>{usersearch()}</Button>
        <Button className="mt-3 reportserchbtn" variant="secondary">{pushreset()}</Button>
      </Form>

      {/* Individual Member Activity Report Table */}
      {showTable && (
        <Row >
          <Col md={10}>
            <Table striped hover className="ms-2 mt-3">
              <thead>
                <tr>
                  <th className="custom-th">Campaign Name</th>
                  <th className="custom-th">Start Date</th>
                  <th className="custom-th">End Date</th>
                  <th className="custom-th">Status</th>
                  <th className="custom-th">Attendance</th>
                </tr>
              </thead>
              <tbody>
                {individualActivityRows.map((row, index) => (
                  <tr key={index}>
                    <td className="custom-td">{row.campaignName}</td>
                    <td className="custom-td">{row.startDate}</td>
                    <td className="custom-td">{row.endDate}</td>
                    <td className="custom-td">{row.status}</td>
                    <td className="custom-td">{row.attendance}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

      )}
    </div>
  );
};




// Similar structure for other forms
// CampaignRatingForm, IndividualRatingForm, and IndividualActivityForm would follow a similar pattern

export default Report;
