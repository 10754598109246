import { useLanguage } from 'app/contexts/LanguageContext';
import { join } from 'lodash';
import { Pass } from 'react-bootstrap-icons';

const useTranslations = () => {
    const { selectedLanguage } = useLanguage();

    const translate = (zh, en) => (selectedLanguage === 'zh' ? zh : en);

    return {
        showErrMsg: (messageObj) => translate(messageObj.message, messageObj.message_en),
        showSuccessMsg: (messageObj) => translate(messageObj.message, messageObj.message_en),
        translate,
        getRoleName: (item) => translate(item.role_name, item.role_name_en),
        rolemanage: () => translate('角色管理', 'Role Management'),
        role_t: () => translate('角色', 'Roles'),
        func_t: () => translate('功能', 'Function(s)'),
        role_edit: () => translate('編輯', 'Edit'),
        role_Add: () => translate('新增', 'Add'),
        role_delete: () => translate('刪除', 'Delete'),
        role_next: () => translate('下一頁', 'Next'),
        role_previous: () => translate('上一頁', 'Previous'),
        change_pass: () => translate('更改密碼', 'Change Password'),
        user_manage: () => translate('使用者管理', 'User Management'),
        availableuser: () => translate('可登入', 'Available'),
        usersearch: () => translate('搜尋', 'Search'),
        usercateg: () => translate('常用資源管理', 'User Defined Categories'),
        usertype: () => translate('類别', 'Type'),
        descCh: () => translate('描述', 'Description (Chinese)'),
        incenter: () => translate('戶內', 'In-Center'),
        venuech: () => translate('地點', 'Venue (Chinese)'),
        username: () => translate('用戶名單', 'User Name'),
        pushtiitle: () => translate('主題', 'Title'),
        pushnotiification: () => translate('推播通知管理', 'Push Notification'),
        pushstatus: () => translate('狀況', 'Status'),
        date: () => translate('日期', 'Date'),
        to: () => translate('至', 'To'),
        approvedby: () => translate('核准人', 'Approved By'),
        createdate: () => translate('建立日期', 'Create Date'),
        createmsg: () => translate('建立訊息', 'Create New Message'),
        pushdate: () => translate('推送日期', 'Push Date'),
        editby: () => translate('編輯者', 'Edit By'),
        lastupdate: () => translate('最後更新/批准', 'last Update/Approve'),
        pushreset: () => translate('重設', 'Reset'),
        nonotification: () => translate(' 沒有信息', ' No notification available'),
        reporttype: () => translate('報表類型', 'Report Type'),
        year: () => translate('年份', 'Year'),
        quater: () => translate('季度', 'Quater'),
        campaignname: () => translate('活動名稱', 'Campaign Name'),
        code: () => translate('活動编號', 'Code'),
        membermange: () => translate('會員管理', 'Member Management'),
        searchmember: () => translate('會員搜尋', 'Search for Member'),
        result: () => translate('結果', 'Result'),

        membername: () => translate('會員姓名', 'Member Name'),
        joineddate: () => translate('加入日期', 'Joined Date'),
        mobile: () => translate('行動電話', 'Mobile'),
        memberid: () => translate('會員编號', 'Member ID'),
        cardref: () => translate('會員卡號', 'Card Ref'),
        app1st: () => translate('首次登入時間', 'App 1st Login'),
        memberidentity: () => translate('會員身份', 'Member Identity'),
        nomemberfound: () => translate('沒有找到會員', 'No Member Found'),

        basicinfo: () => translate('基本資料', 'Basic Information'),
        instructor: () => translate('設定導師', 'Instructor Setting'),
        interests: () => translate('興趣', 'Interests'),
        memberdetails: () => translate('會員詳細資料', 'Member Details'),
        PasswordMEM: () => translate('密碼', 'Password'),
        recentdevice: () => translate('登入用的行動手機', 'Recent Devices'),
        deleteaccount: () => translate('刪除會員帳戶', 'Delete Account'),
        volunteer: () => translate('設定義工', 'Volunteer Setting'),
        camaignengege: () => translate('活動參與', 'Campaign Engagement'),

        gender: () => translate('性別', 'Gender'),
        dobb: () => translate('出生日期', 'Date of Birth'),
        ageb: () => translate('年齡', 'Age'),
        joinb: () => translate('加入日期', 'Joined Date'),
        preferedlang: () => translate('首選語言', 'Prefered Language'),
        livindist: () => translate('居住地區', 'Living District'),
        livingzone: () => translate('居住地區', 'Living Zone'),
        savechaneg: () => translate('儲存變更', 'Save Changes'),
        cancel: () => translate('取消', 'Cancel'),

        interest: () => translate('興趣', 'Interest'),

        acting: () => translate('表演', 'Acting'),
        antiques: () => translate('古董', 'Antiques'),
        aquarium: () => translate('海洋', 'Aquarium'),
        art: () => translate('藝術', 'Art'),
        calligraphy: () => translate('書法', 'Calligraphy'),
        dancing: () => translate('舞蹈', 'Dancing'),
        design: () => translate('設計', 'Design'),
        fishing: () => translate('釣魚', 'Fishing'),
        gardening: () => translate('園藝', 'Gardening'),
        gourmand: () => translate('園藝', 'Gourmand'),
        history: () => translate('歷史', 'History'),
        it: () => translate('資訊科技', 'I.T'),
        languages: () => translate('語言', 'Languages'),
        music: () => translate('音樂', 'Music'),
        nurturing: () => translate('養生', 'Nurturing'),
        painting: () => translate('繪畫', 'Painting'),
        pets: () => translate('寵物', 'Pets'),
        photography: () => translate('攝影', 'Photography'),
        planting: () => translate('種植', 'Planting'),
        playing: () => translate('遊戲', 'Playing'),
        pottery: () => translate('陶器', 'Pottery'),
        reading: () => translate('閱讀', 'Reading'),
        sketching: () => translate('素描', 'Sketching'),
        sports: () => translate('運動', 'Sports'),
        taichi: () => translate('太極', 'Taichi'),
        travel: () => translate('旅行', 'Travel'),
        volunteer: () => translate('義工', 'Volunteer'),
        yoga: () => translate('瑜珈', 'Yoga'),


        memberDetails: () => translate('會員詳細資料', 'Member Details'),
        membershipLevel: () => translate('會員级別', 'Membership Level'),
        membershipIdentity: () => translate('會員身份', 'Membership Identity'),
        membershipStatus: () => translate('會員狀況', 'Membership Status'),
        opsCenter: () => translate('递屬中心', 'Ops Center'),
        instructor: () => translate('導師', 'Instructor'),
        caregiver: () => translate('照顧者', 'Caregiver'),
        volunteer: () => translate('義工', 'Volunteer'),
        addNewRelationship: () => translate('新增關係', 'Add New Relationship'),

        password: () => translate('密碼', 'Password'),
        newPassword: () => translate('新密碼', 'New Password'),
        confirmPassword: () => translate('確認密碼', 'Confirm Password'),
        createNewPasswordNextLogin: () => translate('會員登入時必須建立新密碼', 'Member must create new password at next login'),
        passwordRequirement: () => translate('密碼基本要求', 'Password Requirement'),
        ensureRequirementsMet: () => translate('確保滿足以下要求', 'Ensure that these requirements are met'),
        minimumCharacters: () => translate('至少 8 個字元長', 'Minimum 8 characters long'),
        oneLowercaseCharacter: () => translate('至少一個小寫字符', 'At least one lowercase character'),
        oneUppercaseCharacter: () => translate('至少一個大寫字符', 'At least one uppercase character'),
        oneNumber: () => translate('至少一個數字', 'At least one number'),


        instructorSetting: () => translate('導師設定', 'Instructor Setting'),
        instructorQualification: () => translate('導師專業資格', 'Instructor Qualification (Chinese)'),
        bio: () => translate('導師簡介', 'BIO (Chinese)'),
        addInstructorPhoto: () => translate('新增導師照片', 'Add Instructor Photo'),


        volunteerSetting: () => translate('義工設定', 'Volunteer Setting'),
        joinVoluntary: () => translate('加入義工行列', 'Join Voluntary'),
        willingToJoinVoluntary: () => translate('是的，會員願意自願加入', 'Yes, the member is willing to join voluntary'),
        interestAreaInVoluntary: () => translate('義工興趣領域', 'Interest Area in voluntary'),
        education: () => translate('教育', 'Education'),
        community: () => translate('社區服務', 'Community'),
        environment: () => translate('環保', 'Enrivonment'),
        health: () => translate('健康', 'Health'),
        animal: () => translate('動物保護', 'Animal'),
        periodMostLikelyToJoinVoluntaryActivityTimeOfDay: () => translate('最有可能參加活動的時段 :', 'Period most likely to join voluntary activity: Time of Day'),
        morning: () => translate('早上時段', 'morning'),
        afternoon: () => translate('下午時段', 'Afternoon'),
        evening: () => translate('傍晚時段', 'Evening'),
        periodMostLikelyToJoinVoluntaryActivityTimeOfWeek: () => translate('最有可能參加活動的日子:', 'Period most likely to join voluntary activity: Time of week'),
        monday: () => translate('週一', 'Monday'),
        tuesday: () => translate('週二', 'Tuesday'),
        wednesday: () => translate('週三', 'Wednesday'),
        thursday: () => translate('週四', 'Thursday'),
        friday: () => translate('週五', 'Friday'),
        saturday: () => translate('週六', 'Saturday'),
        sunday: () => translate('週曰', 'Sunday'),
        publicHoliday: () => translate('法定假日', 'Public Holiday'),
        receivePromotion: () => translate('推廣頻道', 'Receive Promotion'),
        post: () => translate('郵寄', 'Post'),
        email: () => translate('email', 'Email'),
        mobileApp: () => translate('推播通知', 'Mobile App'),
        no: () => translate('不接受', 'No'),



        campaignEngagement: () => translate('活動參與', 'Campaign Engagement'),
        campaignDetails: () => translate('活動細節', 'Campaign Details'),
        inProgress: () => translate('進行中的活動', 'In-Progress'),
        completed: () => translate('完成的活動', 'Completed'),
        applicationStatus: () => translate('活動申請狀態', 'Application Status'),
        campaignName: () => translate('活動名稱', 'Campaign Name'),
        type: () => translate('類别', 'Type'),

        startDate: () => translate('開始日期', 'Start Date'),
        endDate: () => translate('結束日期', 'End Date'),
        every: () => translate('每', 'Every'),
        totalClasses: () => translate('總課程數', 'Total Classes'),
        attendance: () => translate('出席率', 'Attendance'),
        absence: () => translate('缺席', 'Absence'),
        reason: () => translate('缺席原因', 'Reason(s)'),


        recentDevices: () => translate('登入用的行動手機', 'Recent Devices'),
        device: () => translate('手機形號', 'Device'),
        location: () => translate('地區', 'Location'),
        recentActivity: () => translate('登入日期/時間', 'Recent Activity'),


        deleteAccount: () => translate('刪除帳戶', 'Delete Account'),
        deleteYourAccount: () => translate('刪除你的帳戶', 'Delete Your Account'),
        loseAccessToApp: () => translate('當您刪除帳戶時，您將無法登入手機App', 'When you delete your account, you lose access to the mobile App'),
        confirmDeleteAccount: () => translate('確認我要刪除我的帳戶', 'Confirm that I want to delete my account'),
        deletes: () => translate('刪除', 'Delete'),

        exactmatch: () => translate('正確配對', 'Exact Match'),
        addfilter: () => translate('新增篩選', 'Add Filter'),
        attributename: () => translate('屬性名稱  ', 'Attribute Name'),
        condition: () => translate('狀態 ', 'Condition'),
        value: () => translate('數據值  ', 'Value'),
        removef: () => translate('移除  ', 'Remove '),
        addfilter: () => translate(' 新增篩選條件 ', 'Add Filter'),


        pushNotifications: () => translate('推播通知', 'Push Notifications'),
        addPhotoVideo: () => translate('新增照片/視頻', 'Add Photo/Video'),
        title: () => translate('推播標題', 'Title (Chinese)'),
        subtitle: () => translate('推播次標題', 'Subtitle (Chinese)'),
        messageContent: () => translate('訊息內容', 'Message Content (Chinese)'),
        sendDateTime: () => translate('發送日期和時間', 'Send Date and Time'),
        preview: () => translate('預覽', 'Preview'),
        test: () => translate('測試', 'Test'),
        scheduleAt: () => translate('安排在', 'Schedule At'),
        now: () => translate('現在', 'Now'),
        targetAudience: () => translate('目標對像', 'Target Audience'),
        allAppUsers: () => translate('所有手機App用户', 'All App Users'),
        paidUsersOnly: () => translate('收費會員', 'Paid Users Only'),
        guestOnly: () => translate('臨時會員', 'Guest Only'),
        confirm: () => translate('確認', 'Confirm'),
        withdraw: () => translate('撤回', 'Withdraw'),
        save: () => translate('儲存', 'Save'),


        campaignManagement: () => translate('活動管理', 'Campaign Management'),
        campaignName: () => translate('活動名稱', 'Campaign Name'),
        startDate: () => translate('開始日期', 'Start Date'),
        pic: () => translate('負責人', 'PIC'),
        sis: () => translate('SIS', 'SIS'),
        createNewCampaign: () => translate('建立新活動', 'Create New Campaign'),
        details: () => translate('細節', 'Details'),
        copy: () => translate('複製', 'Copy'),




        basicInformation: () => translate('基本資料', 'Basic Information'),
        campaignNameChinese: () => translate('活動名稱', 'Campaign Name (Chinese)'),
        venueChinese: () => translate('地點', 'Venue (Chinese)'),
        normal: () => translate('正常', 'Normal'),
        delayed: () => translate('延遲', 'Delayed'),
        cancelled: () => translate('取消', 'Cancelled'),
        quota: () => translate('人數容量', 'Quota'),
        inquiry: () => translate('負責人', 'Inquiry'),
        enrolmentMethod: () => translate('報名方式', 'Enrolment Method'),
        firstComeFirstServe: () => translate('先到先得', 'First Come First Serve'),
        lotteryDraw: () => translate('抽籤', 'Lottery Draw'),
        targetAudienceAndCourseFee: () =>
            translate('合資格會員和課程費用', 'Target Audience & Course Fee'),
        target: () => translate('對象', 'Target'),
        selectAudience: () => translate('會員類別', 'Select Audience'),
        fee: () => translate('費用', 'Fee'),
        publish: () => translate('發布', 'Publish'),
        enablePublish: () =>
            translate(
                '開啟"發布"功能, 合資格會員可以透過手機App查看和加入活動',
                'Enable "Publish" in the portal, and members can view and join the campaign from Mobile App'
            ),
        assignTo: () => translate('發送申請/批核', 'Assign To'),




        date: () => translate('日期', 'Date'),
        promotionStartDate: () => translate('推廣開始日期', 'Promotion Start Date'),
        promotionEndDate: () => translate('推廣結束日期', 'Promotion End Date'),
        registrationStartDate: () => translate('報名開始日期', 'Registration Start Date'),
        registrationEndDate: () => translate('報名結束日期', 'Registration End Date'),
        campaignStartDate: () => translate('活動開始日期', 'Campaign Start Date'),
        campaignEndDate: () => translate('活動結束日期', 'Campaign End Date'),
        recurrenceChinese: () => translate('重複日子', 'Recurrence (Chinese)'),


        instructor: () => translate('導師', 'Instructor(s)'),
        addinstructor: () => translate('新增導師', 'Add Instructor'),


        lessonDetails: () => translate('課程詳情', 'Lesson Details'),
        lessonNameChinese: () => translate('課程名稱', 'Lesson Name (Chinese)'),
        startTime: () => translate('開始時間', 'Start Time'),
        endTime: () => translate('結束時間', 'End Time'),
        normal: () => translate('正常', 'Normal'),
        delayed: () => translate('延遲', 'Delayed'),
        cancelled: () => translate('取消', 'Cancelled'),



        uploadCampaignPhotoVideo: () =>
            translate('上傳活動照片/視頻', 'Upload Campaign Photo/Video'),
        addActivityPhotoVideo: () =>
            translate('新增活動照片/視頻', 'Add Activity Photo/Video'),
        addActivityIcon: () => translate('新增活動圖片', 'Add Activity Icon'),


        survey: () => translate('問卷調查', 'Survey'),
        memberComments: () => translate('會員留言板', 'Member Comments'),
        enableUserComments: () =>
            translate(
                '開啟"留言板"功能, 合資格會員可以透過手機App寫入評語',
                'Enable User comments in the portal, and members can freely comment on the mobile App'
            ),



        consentForm: () => translate('同意書', 'Consent Form'),
        titleChinese: () => translate('主題', 'Title (Chinese)'),
        contentChinese: () => translate('內容', 'Content (Chinese)'),
        rule: () => translate('規則', 'Rule'),
        setConsentFormAsMandatory: () =>
            translate('同意書設為必填', 'Set Consent Form as Mandatory'),
        consentFormMandatory: () =>
            translate('付款前必須簽署同意書', 'Consent Form are Mandatory to sign and agree before payment'),



        enrolmentStatus: () => translate('活動註冊狀態', 'Enrolment Status'),
        noOfApplications: () => translate('申請人數', 'No. of applications'),
        quotaUsage: () => translate('占用率', 'Quota usage'),
        approver: () => translate('審批者姓名', 'Approver'),
        lastUpdate: () => translate('最後更新日期/時間', 'Last Update'),
        addMember: () => translate('新增會員', 'Add Member'),
        addGroup: () => translate('新增群組', 'Add Group'),
        preApprove: () => translate('預先批核', 'Pre-approve'),
        approve: () => translate('批核完成', 'Approve'),
        waitlist: () => translate('候補', 'Waitlist'),
        withdraw: () => translate('撤回', 'Withdraw'),


        attendanceDetails: () => translate('出席詳情', 'Attendance Details'),
        title: () => translate('活動名稱', 'Title'),
        dateStart: () => translate('日期 / 開始時間', 'Date/Start'),
        noOfAttendance: () => translate('總出席人數', 'No. of Attendance(s)'),
        noOfAbsentee: () => translate('總缺席人數', 'No. of Absentee(s)'),
        absenceReason: () => translate('缺席原因', 'Absence Reason'),
        close: () => translate('關閉', 'Close'),

        home: () => translate('首頁 - 儀表板', 'Home'),
        midentity: () => translate(' 身份搜尋', ' Identity'),
        mAdvance: () => translate(' 高諧搜尋', 'Advance '),
        searchformemberm: () => translate(' 會員搜尋', 'Search for Members'),

        period: () => translate('期間', 'Period'),
        mEdit: () => translate('編輯', 'Edit'),
        mAdd: () => translate('新增', 'Add'),
        mAddress: () => translate('地址', 'Address'),
        mEnglish: () => translate('英文', 'English'),
        mChinese: () => translate('中文', 'Chinese'),
        mMale: () => translate('男', 'Male'),
        mFemale: () => translate('女', 'Female'),
        instructorQualificationEn: () => translate('導師專業資格(英文)', 'Instructor Qualification (English)'),
        bioEn: () => translate('導師簡介(英文)', 'BIO (English)'),
        changeInstructorPhoto: () => translate('變更導師照片', 'Change Instructor Photo'),
        photoNotUpdateHint: () => translate('圖片尚未更新，請點擊\'儲存變更\'進行更新。', 'Image has not been updated. Please click \'Save Changes\' to update.'),
    };


};

export default useTranslations;
