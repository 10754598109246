
import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLanguage } from 'app/contexts/LanguageContext';
import useTranslations from '../transalation/TranslationUtils';
const UserManagementNew = ({
  show,
  handleClose,
  modalTitle,
  userData,
  fetchUsers,
}) => {
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roleId, setRoleId] = useState("");
  const [roles, setRoles] = useState([]);
  const [available, setAvailable] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { availableuser } = useTranslations();

  // Populate form fields if editing an existing user
  useEffect(() => {
    if (userData) {
      setAdminName(userData.admin_name || "");
      setAdminEmail(userData.admin_email || "");
      setPassword(""); // Don't populate the password field for editing
      setRoleId(userData.role_id || "");
      setAvailable(userData.available || false);
    } else {
      setAdminName("");
      setAdminEmail("");
      setPassword("");
      setRoleId("");
      setAvailable(true);
    }
  }, [userData]);

  // Fetch roles from API
  const fetchRoles = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        "https://membershipuat.maxisense.io/webapp/memportal/getrolelist",
        {
          headers: {
            Token: token,
            "Content-Type": "application/json",
          },
        }
      );
      const rolesData = Array.isArray(response.data.data)
        ? response.data.data
        : [];
      setRoles(rolesData);
    } catch (error) {
      console.error("Error fetching roles:", error);
      setRoles([]);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = () => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      return "Password must be at least 8 characters long and include at least 1 lowercase letter, 1 uppercase letter, and 1 number.";
    }
    return "";
  };

  const handleSave = async () => {
    if (!validateEmail(adminEmail)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    if (!roleId) {
      setErrorMessage("Please select a role.");
      return;
    }

    if (!userData && !password) {
      setErrorMessage("Please enter a password.");
      return;
    }

    if (!userData) {
      const validationMessage = validatePassword();
      if (validationMessage) {
        setErrorMessage(validationMessage);
        setTimeout(() => setErrorMessage(""), 3000);
        return;
      }
    }

    const token = localStorage.getItem("authToken");
    const apiEndpoint = userData
      ? "https://membershipuat.maxisense.io/webapp/memportal/updateadmin"
      : "https://membershipuat.maxisense.io/webapp/memportal/addadmin";

    const requestData = {
      role_id: roleId,
      admin_name: adminName,
      admin_email: adminEmail,
      available,
      ...(userData
        ? { admin_id: userData.admin_id, admin_profile: "" } // For editing
        : { password }), // Include password only for adding
    };

    try {
      const response = await axios.post(apiEndpoint, requestData, {
        headers: {
          Token: token,
          "Content-Type": "application/json",
        },
      });

      if (response.data.code === 1) {
        setSuccessMessage(
          userData ? "User updated successfully!" : "User added successfully!"
        );
        fetchUsers && fetchUsers();
        setTimeout(() => setSuccessMessage(""), 5000);
        handleClose();
      } else {
        const errorMsg =
          response.data?.message_en ||
          "Failed to save user. Please try again.";
        setErrorMessage(errorMsg);
        setTimeout(() => setErrorMessage(""), 3000);
      }
    } catch (error) {
      console.error("Error saving user:", error);
      setErrorMessage("Failed to save user. Please try again.");
    }
  };
  const { selectedLanguage } = useLanguage();
  const getRoleName = (role) => {
    return selectedLanguage === 'zh' ? role.role_name : role.role_name_en; // Assuming role_name_zh and role_name_en exist in the role data
  };
  return (

    <Modal show={show} onHide={handleClose} centered size="md">
      <Modal.Body>
        <Form>
          <h4 className="maintitle">User Management</h4>

          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}

          {/* Admin Name */}
          <Form.Group controlId="formAdminName" className="floating-label-group">
            <Form.Control
              type="text"
              value={adminName}
              onChange={(e) => setAdminName(e.target.value)}
              className="floating-input"
              required
            />
            <Form.Label className="floating-label">User Name</Form.Label>
          </Form.Group>

          {/* Admin Email */}
          <Form.Group controlId="formAdminEmail" className="floating-label-group">
            <Form.Control
              type="email"
              value={adminEmail}
              onChange={(e) => setAdminEmail(e.target.value)}
              className="floating-input"
              required
            />
            <Form.Label className="floating-label">User Email</Form.Label>
          </Form.Group>

          {/* Password */}
          {!userData && (
            <Form.Group controlId="formPassword" className="floating-label-group" style={{ position: 'relative' }}>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="floating-input"
                required
              />
              <Form.Label className="floating-label">Password</Form.Label>
              <div
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  color: '#6c757d',
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </Form.Group>
          )}

          {/* Role Dropdown */}
          <Form.Group controlId="formRoleId" className="floating-label-group">
            <Form.Select value={roleId} onChange={(e) => setRoleId(e.target.value)} className="floating-input" required>
              <option value="">Select Role</option>
              {roles.map((role) => (
                <option key={role.role_id} value={role.role_id}>
                  {getRoleName(role)} {/* Display role name based on selected language */}
                </option>
              ))}
            </Form.Select>
            <Form.Label className="floating-label">Role</Form.Label>
          </Form.Group>

          {/* Available Checkbox */}
          <Form.Group controlId="formAvailable" className="floating-label-group">
            <Form.Check
              type="checkbox"
              label={availableuser()}
              checked={available}
              onChange={(e) => setAvailable(e.target.checked)}
            />
          </Form.Group>

          {/* Save Button */}
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>

  );
};

export default UserManagementNew;
