import React, { useState, Fragment } from "react";
import {
  Box,
  Card,
  Grid,
  Drawer,
  Button,
  useTheme,
  IconButton,
  useMediaQuery
} from "@mui/material";
import { H5 } from "app/components/Typography";
import { FlexBox } from "app/components/FlexBox";
import Apps from "app/components/icons/Apps";
import Breadcrumb from "app/components/Breadcrumb";
import useTranslations from "../transalation/TranslationUtils";
import BasicInformation from "./BasicInformation";
import InstructorSetting from "./InstructorSetting";
import Interests from "./Interests";
import MembershipDetails from "./MembershipDetails";
import Password from "./Password";
import RecentDevices from "./RecentDevices";
import DeleteAccount from "./DeleteAccount";
import Volunteer from "./Volunteer";
import Campaignengagement from "./Campaignengagement";

// Styled button component
import { styled } from "@mui/system";
import { MODE_ADD, MODE_EDIT, MODE_UNKNOWN } from "app/utils/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { clearEditMember, getInterestList, getMemberDetail, setEditMemberData } from "app/redux/slices/rdxMemberSlice";
const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  overflow: "hidden",
  position: "relative",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  padding: "0.6rem 1.5rem",
  justifyContent: "flex-start",
  color: theme.palette.text.primary
}));

const MemberInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { showErrMsg } = useTranslations();

  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [breadCrumbInit, setBreadCrumbInit] = useState(false);
  const [active, setActive] = useState(0);
  const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const memberEditMode = useSelector((state) => state.rdxMember?.memberEditMode || MODE_UNKNOWN);
  const memberEditData = useSelector((state) => state.rdxMember?.memberEditData || {});

  const style = {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[100],
    "&::before": {
      left: 0,
      width: 4,
      content: '""',
      height: "100%",
      position: "absolute",
      transition: "all 0.3s",
      backgroundColor: theme.palette.primary.main
    }
  };

  const {
    basicinfo,
    instructor,
    interests,
    memberdetails,
    PasswordMEM,
    recentdevice,
    deleteaccount,
    volunteer,
    camaignengege,
    membermange,
    mAdd,
    mEdit
  } = useTranslations();

  // Updated tabList to include translated names
  const tabList = [
    { id: 1, name: basicinfo(), component: <BasicInformation /> },
    { id: 2, name: interests(), component: <Interests /> },
    { id: 3, name: memberdetails(), component: <MembershipDetails /> },
    { id: 4, name: PasswordMEM(), component: <Password /> },
    { id: 5, name: instructor(), component: <InstructorSetting /> },
    { id: 6, name: volunteer(), component: <Volunteer /> },
    { id: 7, name: camaignengege(), component: <Campaignengagement /> },
    { id: 8, name: recentdevice(), component: <RecentDevices /> },
    { id: 9, name: deleteaccount(), component: <DeleteAccount /> }
  ];

  useEffect(() => {
    // Component mount logic
    const searchParams = new URLSearchParams(location.search);
    const accountId = searchParams.get("account_id");

    dispatch(getMemberDetail(accountId, (isSuccess, data) => {
      if (!isSuccess) {
        alert(showErrMsg(data));
        navigate("/member/membermanagement");
      }
    }));

    dispatch(getInterestList());

    return () => {
      // Component unmount logic
      dispatch(clearEditMember())
    };
  }, []);

  useEffect(() => {
    // Component mount logic
    if (MODE_EDIT == memberEditMode || MODE_ADD == memberEditMode) {
      dispatch(setEditMemberData(memberEditData));
      setActive(1);
      setBreadCrumbInit(true);
    }
  }, [memberEditMode]);

  const checkTabDisabled = (id) => {
    if (id == 1) {
      return false;
    } else {
      return !memberEditData.account_id;
    }
  }

  function TabListContent() {
    return (
      <FlexBox flexDirection="column">
        {tabList.map(({ id, name }) => (
          <StyledButton
            key={id}
            sx={active === id ? style : { "&:hover": style }}
            disabled={checkTabDisabled(id)}
            onClick={() => {
              setActive(id);
              setOpenDrawer(false);
            }}
          >
            {name}
          </StyledButton>
        ))}
      </FlexBox>
    );
  }

  return (
    <Box p={4}>
      {(!breadCrumbInit) ? (
        <React.Fragment></React.Fragment>
      ) : (
        <div className="breadcrumb">
          {(MODE_EDIT == memberEditMode) ? (
            <Breadcrumb routeSegments={[{ name: membermange(), path: "/member/membermanagement" }, { name: mEdit() }]} />
          ) : (
            <Breadcrumb routeSegments={[{ name: membermange(), path: "/member/membermanagement" }, { name: mAdd() }]} />
          )}
        </div>
      )}

      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          {downMd ? (
            <Fragment>
              <FlexBox alignItems="center" gap={1}>
                <IconButton sx={{ padding: 0 }} onClick={() => setOpenDrawer(true)}>
                  <Apps sx={{ color: "primary.main" }} />
                </IconButton>
                <H5>Show More</H5>
              </FlexBox>
              <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <Box padding={1}>
                  <TabListContent />
                </Box>
              </Drawer>
            </Fragment>
          ) : (
            <Card sx={{ padding: "1rem 0", marginTop: "25px" }}>
              <TabListContent />
            </Card>
          )}
        </Grid>
        <Grid item md={9} xs={12}>
          {/* Dynamically render the active component */}
          {tabList.find((tab) => tab.id === active)?.component}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MemberInfo;





